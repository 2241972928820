import React, { FC, useEffect, useState, useContext } from 'react';
import { Grid, Container, Typography, makeStyles, Theme, useTheme, useMediaQuery } from '@material-ui/core';
import { Page, StandardConfirmationDialog, Breadcrumb, PaperCustom, FormPinDialog } from 'components';
import { SALES_ORDER_BASE_URL, SALES_ORDER_EXPORT_BASE_URL, ZONE_BASE_URL } from 'constants/url';
import axios, { CancelTokenSource } from 'axios';
import SalesOrderTable from './components/SalesOrderTable';
import Pagination from '@material-ui/lab/Pagination';
import useRouter from 'hooks/useRouter';
import useRole from 'hooks/useRole';
import useConfirmationPin from 'hooks/useConfirmationPin';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import { format } from 'date-fns';
import { GREEN, WHITE } from 'constants/colors';
import ExportModal from './components/ExportModal';
import useDebounced from 'hooks/useDebounced';
import TypeUser from 'typings/enum/TypeUser';

const useStyles = makeStyles((theme: Theme) => ({
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN
    }
  },
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: ' none'
    }
  },
  desktop: {
    [theme.breakpoints.down('md')]: {
      display: ' none'
    }
  }
}));

const SalesOrderPage: FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const showComponent = useMediaQuery(theme.breakpoints.down('md'));
  const { currentUser } = useContext(CurrentUserContext);
  const [count, setCount] = useState<number>(0);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [salesOrders, setSalesOrders] = useState<SalesOrderModel[]>([]);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [selectedId, setSelectedId] = useState<number>();
  const [confirmationDelete, setConfirmationDelete] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [salesName, setSalesName] = useState<string>('');
  const [salesOrderId, setSalesOrderId] = useState<string>('');
  const [statusOrder, setStatusOrder] = useState<string>('PENDING');
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [pin, setPin] = useState<number | null>(null);
  const [isComplete, setComplete] = useState<boolean>(false);
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [indexCollapse, setIndexCollapse] = useState<number>(-1);
  const [loadingItem, setLoadingItem] = useState<boolean>(false);

  const isSuperVisor = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERVISOR]
  });

  const isSales = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SALES]
  });

  const salesRoute = currentUser && currentUser.SalesRoute ? currentUser.SalesRoute.map(value => value.ZoneId) : [];
  let cancelToken: CancelTokenSource = axios.CancelToken.source();

  const [openExport, setOpenExport] = useState<boolean>(false);
  const [date, setDate] = useState<string>('');
  const [dateStart, setDateStart] = useState<string>('');
  const [dateEnd, setDateEnd] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [zoneExport, setZoneExport] = useState<number[]>([]);

  const pinMessage = useConfirmationPin({
    pin,
    confirmationDelete: confirmationDelete,
    isCompletePin: isComplete,
    handleOnDelete: deleteSalesOrder,
    setOpenConfirmation: setOpenConfirmation,
    setLoading: setLoadingDelete,
    setCompletePin: setComplete,
    setPin: setPin
  });

  const fetchData = async () => {
    setIsLoadingData(true);

    const getQueryParams = () => {
      const params = new URLSearchParams();

      if (name) {
        params.append('partner', name);
      }

      if (salesOrderId) {
        params.append('keyword', salesOrderId);
      }

      if (salesName) {
        params.append('sales', salesName);
      }

      if (isSales) {
        if (currentUser) {
          params.append('SalesId', currentUser.id.toString());
        }
      }

      if (isSuperVisor || isSales) {
        if (currentUser) {
          params.append('Zone', (currentUser.SalesRoute && currentUser.SalesRoute.map(value => value.ZoneId).join(',')) || '');
        }
      }

      params.append('statusOrder', statusOrder);
      params.append('page', currentPage.toString());
      params.append('orderBy', orderBy);
      params.append('ordered', order);
      return params.toString();
    };

    try {
      const { data } = await axios.get(`${SALES_ORDER_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelToken.token });
      setSalesOrders(data.data);
      setCount(data.meta.last_page);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string): void => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleConfirmationDelete = (id: number): React.MouseEventHandler => () => {
    setSelectedId(id);
    setConfirmationDelete(true);
  };

  const handleCloseConfirmationDelete = () => {
    setConfirmationDelete(false);
  };

  const handleConfirmSnackbar = (): void => {
    setOpenSnackbar(false);
  };

  const handleCloseSnackbar = (): void => {
    setOpenSnackbar(false);
  };

  const handleCloseExport = () => {
    setOpenExport(false);
    clearExport();
  };

  const handleExport = async () => {
    const params = new URLSearchParams();

    if (date !== '') {
      params.append('date', format(new Date(date), 'yyyy-MM-dd'));
    }

    if (dateStart !== '') {
      params.append('dateStart', format(new Date(dateStart), 'yyyy-MM-dd'));
    }

    if (dateEnd !== '') {
      params.append('dateEnd', format(new Date(dateEnd), 'yyyy-MM-dd'));
    }

    if (zoneExport.length !== 0) {
      params.append('Zone', zoneExport.join(','));
    }

    if (status !== '') {
      params.append('status', status);
    }

    try {
      const { data } = await axios.get(`${SALES_ORDER_EXPORT_BASE_URL}?${params.toString()}`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'sales-order.xlsx');
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.log('err', err);
    } finally {
      setOpenExport(false);
      clearExport();
    }
  };

  const clearExport = () => {
    setDate('');
    setDateStart('');
    setDateEnd('');
    setStatus('');
    setZoneExport([]);
  };

  const getData = async (id: number) => {
    setLoadingItem(true);
    try {
      const { data } = await axios.get(`${SALES_ORDER_BASE_URL}/${id}`);
      setSalesOrders(prevState =>
        prevState.map(value => {
          if (value.id === id) {
            value.SalesOrderItem = data.data.SalesOrderItem;
          }
          return value;
        })
      );
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoadingItem(false);
    }
  };

  const handleOpenCollapse = (index: number, id: number): React.MouseEventHandler => () => {
    setIndexCollapse(index);
    setOpenCollapse(openCollapse ? (index === indexCollapse ? false : true) : true);
    getData(id);
  };

  const handleOpenConfirmation = () => {
    const so = salesOrders.find(value => value.id === selectedId);
    if (so && so.statusOrder !== 'CONFIRMATION') {
      deleteSalesOrder();
    } else {
      setOpenConfirmation(true);
    }
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  async function deleteSalesOrder() {
    if (!openConfirmation) {
      setLoadingDelete(true);
    }

    try {
      await axios.delete(`${SALES_ORDER_BASE_URL}/${selectedId}`);
      setSalesOrders(salesOrders.filter(value => value.id !== selectedId));
      setConfirmationDelete(false);

      handleSnackBar(true, 'success', 'Orderan berhasil dihapus.');
    } catch (err) {
      console.log(err);
      handleSnackBar(true, 'error', 'Orderan gagal dihapus.');
    } finally {
      setLoadingDelete(false);
    }
  }

  useEffect(() => {
    if (!name && !salesOrderId && !salesName) {
      return;
    }

    setCurrentPage(1);
  }, [name, salesOrderId, salesName]);

  const debouncedFetchHits = useDebounced(fetchData, 500);

  useEffect(() => {
    debouncedFetchHits();
    return () => cancelToken.cancel('No longer latest query');
  }, [orderBy, order, currentPage, name, salesOrderId, salesName, statusOrder, isSales, isSuperVisor, currentUser]);

  return (
    <Page title='Sales Order'>
      <Container>
        <Grid container direction='row' spacing={2} className={classes.desktop}>
          <Grid item lg={12}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant='h1' component='h1'>
                Penjualan
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Breadcrumb />
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction='row' spacing={1}>
          <PaperCustom style={{ marginTop: showComponent ? '-50px' : '0.8em' }}>
            <Grid item className={classes.mobile}>
              <Typography variant='h4' component='h4'>
                Penjualan
              </Typography>
            </Grid>
            <Grid container direction='row' alignItems='center' item lg={12} md={12} sm={12} xs={12} spacing={2}>
              <Grid item sm={12} xs={12}>
                <SalesOrderTable
                  statusOrder={statusOrder}
                  setStatusOrder={setStatusOrder}
                  salesOrderId={salesOrderId}
                  setSalesOrderId={setSalesOrderId}
                  name={name}
                  setName={setName}
                  salesName={salesName}
                  setSalesName={setSalesName}
                  isLoadingData={isLoadingData}
                  salesOrders={salesOrders}
                  order={order}
                  orderBy={orderBy}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                  openCollapse={openCollapse}
                  indexCollapse={indexCollapse}
                  handleOpenCollapse={handleOpenCollapse}
                  handleConfirmationDelete={handleConfirmationDelete}
                  loadingItem={loadingItem}
                />
              </Grid>

              <Grid item sm={12} xs={12}>
                {salesOrders.length > 0 && (
                  <Pagination
                    count={count}
                    onChange={(event, page) => setCurrentPage(page)}
                    page={currentPage}
                    boundaryCount={2}
                    variant='outlined'
                  />
                )}
              </Grid>
            </Grid>
          </PaperCustom>
        </Grid>

        <StandardConfirmationDialog
          variant={snackbarVariant}
          titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
          message={message}
          open={openSnackbar}
          handleClose={snackbarVariant === 'success' ? handleConfirmSnackbar : handleCloseSnackbar}
          noCancelButton={true}
        />

        <StandardConfirmationDialog
          variant={'danger'}
          titleMessage={'Delete'}
          message={'Apakah kamu yakin menghapus data ini?'}
          open={confirmationDelete}
          handleClose={handleCloseConfirmationDelete}
          onConfirm={handleOpenConfirmation}
          isLoading={loadingDelete}
        />

        <FormPinDialog
          open={openConfirmation}
          pinMessage={pinMessage}
          isComplete={isComplete}
          setComplete={setComplete}
          setPin={setPin}
          handleClose={handleCloseConfirmation}
        />

        <ExportModal
          openExport={openExport}
          date={date}
          dateStart={dateStart}
          dateEnd={dateEnd}
          status={status}
          setDate={setDate}
          setDateStart={setDateStart}
          setDateEnd={setDateEnd}
          setStatus={setStatus}
          handleClose={handleCloseExport}
          handleExport={handleExport}
        />
      </Container>
    </Page>
  );
};

export default SalesOrderPage;
