import React, { FC, useState, useEffect } from 'react';
import {
  makeStyles,
  LinearProgress,
  Button,
  TextField,
  Typography,
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableContainer
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import {
  COMPANY_BASE_URL,
  COMPANY_UPLOAD_BASE_URL,
  WILAYAH_PROVINSI_BASE_URL,
  WILAYAH_KABUPATEN_BASE_URL,
  WILAYAH_KECAMATAN_BASE_URL
} from 'constants/url';
import { PaperCustom } from 'components';
import { useDropzone, FileWithPath } from 'react-dropzone';
import { PRIMARY, BACKGROUND, GRAY_3 } from 'constants/colors';
import { dummyRegion } from 'utils/dummy';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

interface Props {
  company: CompanyModel;
  setCompany: React.Dispatch<React.SetStateAction<CompanyModel>>;
  setEmpty: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

const useStyles = makeStyles({
  cell: {
    border: 'none',
    padding: '0px 0px 10px 0px'
  },
  dropZone: {
    marginTop: 24,
    padding: '44px 9px 40px 9px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: PRIMARY,
    borderStyle: 'dashed',
    backgroundColor: BACKGROUND,
    color: GRAY_3,
    outline: 'none',
    transition: 'border .24s ease-in-out',
    width: 164,
    height: 178,
    verticalAlign: 'middle',
    textAlign: 'center'
  },
  thumbsContainer: {
    marginTop: 24
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 164,
    height: 178,
    padding: 4,
    boxSizing: 'border-box'
  },
  bigAvatar: {
    display: 'block',
    width: 164,
    height: 178
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%'
  },
  tabUpload: {
    width: '100%'
  },
  iconUpload: {
    fontSize: 20,
    color: PRIMARY
  },
  textUpload: {
    fontSize: 10
  }
});

const CompanyWizard: FC<Props> = props => {
  const classes = useStyles();

  const { company, setCompany, setEmpty, setUpdate, handleSnackBar } = props;
  const [name, setName] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [cellPhoneNumber, setCellPhoneNumber] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [website, setWebsite] = useState<string>('');
  const [contactPerson, setContactPerson] = useState<string>('');
  const [npwpNumber, setNpwpNumber] = useState<string>('');
  const [postalCode, setPostalCode] = useState<number>();
  const [provinsi, setProvinsi] = useState<RegionModel>(dummyRegion);
  const [kabupaten, setKabupaten] = useState<RegionModel>(dummyRegion);
  const [kecamatan, setKecamatan] = useState<RegionModel>(dummyRegion);
  const [region, setRegion] = useState<RegionModel[]>([dummyRegion]);
  const [nameMessage, setNameMessage] = useState<string>('');
  const [phoneNumberMessage, setPhoneNumberMessage] = useState<string>('');
  const [emailMessage, setEmailMessage] = useState<string>('');
  const [addressMessage, setAddressMessage] = useState<string>('');
  const [provinsiMessage, setProvinsiMessage] = useState<string>('');
  const [kabupatenMessage, setKabupatenMessage] = useState<string>('');
  const [kecamatanMessage, setKecamatanMessage] = useState<string>('');
  const [postalCodeMessage, setPostalCodeMessage] = useState<string>('');
  const [contactPersonMessage, setContactPersonMessage] = useState<string>('');
  const [npwpNumberMessage, setNpwpNumberMessage] = useState<string>('');
  const [progress, setProgress] = useState<number>(0);
  const [image, setImage] = useState<string>('');
  const [imageBlob, setImageBlob] = useState<FileWithPath[]>([]);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/jpeg', 'image/png'],
    maxFiles: 1,
    onDrop: acceptedFiles => {
      uploadImage(acceptedFiles);
    }
  });

  const uploadImage = async (filesPath: FileWithPath[]) => {
    setImageUrl(URL.createObjectURL(filesPath[0]));
    setImage(`company.${filesPath[0].name.split('.').pop()}`);
    setImageBlob(filesPath);
  };

  useEffect(() => {
    if (!company) {
      return;
    }

    setName(company.name);
    setPhoneNumber(company.phoneNumber);
    setCellPhoneNumber(company.cellPhoneNumber);
    setAddress(company.address);
    setEmail(company.email);
    setWebsite(company.website);
    setContactPerson(company.contactPerson);
    setNpwpNumber(company.npwpNumber);
    setPostalCode(company.postalCode);
    setProvinsi(company.Provinsi!);
    setKabupaten(company.Kabupaten!);
    setKecamatan(company.Kecamatan!);
    setImage(company.image);
    setImageUrl(company.imageUrl ? company.imageUrl : '');
  }, [company]);

  const handleSearchProvinsi = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const params = new URLSearchParams();
    params.append('keyword', event.target.value);

    try {
      const { data } = await axios.get(`${WILAYAH_PROVINSI_BASE_URL}?${params.toString()}`);
      setRegion(data.data);
    } catch (error) {
      console.log('error :', error);
    }
  };

  const handleSearchKabupaten = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const params = new URLSearchParams();
    params.append('keyword', event.target.value);

    try {
      const { data } = await axios.get(`${WILAYAH_KABUPATEN_BASE_URL(provinsi.code)}?${params.toString()}`);
      setRegion(data.data);
    } catch (error) {
      console.log('error :', error);
    }
  };

  const handleSearchKecamatan = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const params = new URLSearchParams();
    params.append('keyword', event.target.value);

    try {
      const { data } = await axios.get(`${WILAYAH_KECAMATAN_BASE_URL(kabupaten.code)}?${params.toString()}`);
      setRegion(data.data);
    } catch (error) {
      console.log('error :', error);
    }
  };

  const onSubmit = async () => {
    setSubmit(true);

    resetValidation();
    if (!validation()) {
      setSubmit(false);
      return;
    }

    try {
      const { data } = await axios.post(`${COMPANY_BASE_URL}`, {
        id: company.id,
        name,
        phoneNumber: phoneNumber.toString(),
        cellPhoneNumber,
        email,
        address,
        website,
        postalCode,
        areaCode: (kecamatan && kecamatan.code) || null,
        npwpNumber,
        contactPerson,
        image
      });

      if (imageBlob.length > 0) {
        const formData = new FormData();
        formData.append(`image`, imageBlob[0]);
        formData.append(`name`, image);

        await axios.post(`${COMPANY_UPLOAD_BASE_URL}`, formData);
      }
      setCompany(data.data);
      setUpdate(false);
      setEmpty(false);
      handleSnackBar(true, 'success', 'Perusahaan berhasil diperbaharui');
    } catch (error) {
      console.log('error :', error);
      handleSnackBar(true, 'error', 'Perusahaan gagal diperbaharui');
    } finally {
      setSubmit(false);
    }
  };

  const validation = (): boolean => {
    let valid = true;

    if (name === '' || !name) {
      setNameMessage('Nama tidak boleh kosong.');
      valid = false;
    }

    if (phoneNumber === '' && !phoneNumber) {
      setPhoneNumberMessage('No telepon tidak boleh kosong.');
      valid = false;
    }

    return valid;
  };

  const resetValidation = () => {
    setNameMessage('');
    setEmailMessage('');
    setPhoneNumberMessage('');
    setAddressMessage('');
    setProvinsiMessage('');
    setKabupatenMessage('');
    setKecamatanMessage('');
    setPostalCodeMessage('');
    setContactPersonMessage('');
    setNpwpNumberMessage('');
  };

  const handleCancelUpdate = () => {
    setUpdate(false);
  };

  return (
    <PaperCustom>
      <Grid direction='row' container spacing={2}>
        <Grid item container spacing={2} justify={'space-between'}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h5'>Info Perusahaan</Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.cell}>
                      <TextField
                        id='name'
                        name='name'
                        required
                        fullWidth
                        label='Nama'
                        value={name}
                        onChange={event => setName(event.target.value)}
                        error={nameMessage !== ''}
                        helperText={nameMessage}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cell}>
                      <TextField
                        id='phoneNumber'
                        required
                        fullWidth
                        label='No Telepon'
                        value={phoneNumber}
                        onChange={event => setPhoneNumber(event.target.value)}
                        inputProps={{
                          maxLength: 13
                        }}
                        error={phoneNumberMessage !== ''}
                        helperText={phoneNumberMessage}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={classes.cell}>
                      <TextField id='website' fullWidth label='Laman Website' value={website} onChange={event => setWebsite(event.target.value)} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.cell}>
                      <TextField id='email' name='email' fullWidth label='Email ' value={email} onChange={event => setEmail(event.target.value)} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cell}>
                      <TextField id='npwpNumber' fullWidth label='No NPWP' value={npwpNumber} onChange={event => setNpwpNumber(event.target.value)} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cell}>
                      <TextField
                        id='cellPhoneNumber'
                        fullWidth
                        label='No Handphone '
                        value={cellPhoneNumber}
                        onChange={event => setCellPhoneNumber(event.target.value)}
                        inputProps={{
                          maxLength: 13
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h5'>Alamat Perusahaan</Typography>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.cell}>
                    <TextField id='address' fullWidth value={address} onChange={event => setAddress(event.target.value)} label='Alamat Perusahaan' />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell}>
                    <Autocomplete
                      id='kabupaten'
                      fullWidth
                      value={kabupaten}
                      options={region}
                      getOptionLabel={option => option.name}
                      onChange={(event: any, value: any) => {
                        setKabupaten(value);
                        setRegion([dummyRegion]);
                      }}
                      renderInput={params => (
                        <TextField {...params} fullWidth label='Kabupaten' size='small' onChange={handleSearchKabupaten} variant='outlined' />
                      )}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell}>
                    <TextField
                      id='postalCode'
                      fullWidth
                      label='Kode Pos '
                      type='number'
                      value={postalCode}
                      onChange={event => setPostalCode(+event.target.value)}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.cell}>
                    <Autocomplete
                      id='provinsi'
                      fullWidth
                      value={provinsi}
                      options={region}
                      getOptionLabel={option => option.name}
                      onChange={(event: any, value: any) => {
                        setProvinsi(value);
                        setRegion([dummyRegion]);
                      }}
                      renderInput={params => (
                        <TextField {...params} label='Provinsi ' size='small' onChange={handleSearchProvinsi} variant='outlined' />
                      )}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell}>
                    <Autocomplete
                      id='kecamatan'
                      fullWidth
                      value={kecamatan}
                      options={region}
                      getOptionLabel={option => option.name}
                      onChange={(event: any, value: any) => {
                        setKecamatan(value);
                        setRegion([dummyRegion]);
                      }}
                      renderInput={params => (
                        <TextField {...params} label='Kecamatan' size='small' onChange={handleSearchKecamatan} variant='outlined' />
                      )}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>

        <Grid item container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h5'>Logo Perusahaan</Typography>
          </Grid>

          <Grid direction='row' container item lg={6} md={6} sm={12} xs={12}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div {...getRootProps({ className: classes.dropZone })}>
                <input {...getInputProps()} />
                <CloudUploadIcon className={classes.iconUpload} />
                <Typography align='center' variant='subtitle2'>
                  Unggah Logo
                </Typography>
                <Typography align='center' className={classes.textUpload}>
                  Max 20MB JPEG, JPG, atau PNG Rekomendasi 300 x 200
                </Typography>

                {progress > 0 && <LinearProgress color='primary' variant='determinate' value={progress} />}
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className={classes.thumbsContainer}>
                {imageUrl && (
                  <div className={classes.thumb}>
                    <div className={classes.thumbInner}>
                      <img alt='image' src={imageUrl} className={classes.img} />
                    </div>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item direction='row' container lg={12} md={12} sm={12} xs={12} spacing={2} justify='flex-end'>
        <Grid item>
          <Button disabled={isSubmit} color='secondary' onClick={handleCancelUpdate}>
            BataL
          </Button>
        </Grid>
        <Grid item>
          <Button disabled={isSubmit} onClick={onSubmit}>
            Simpan
          </Button>
        </Grid>
      </Grid>
    </PaperCustom>
  );
};

export default CompanyWizard;
