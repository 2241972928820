import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { GREY } from 'constants/colors';
import React, { ReactNode } from 'react';

const useStyles = makeStyles({
  container: {
    border: `1px solid ${GREY}`,
    borderRadius: '5px',
    padding: '1em'
  },
  rowGap: {
    rowGap: '1em'
  }
});

interface Props {
  items: {
    title: string;
    total: {
      label: string;
      totalPrice: number;
    }[];
  };
  children?: ReactNode;
}
const ContentCard = ({ items, children }: Props) => {
  const classes = useStyles();
  return (
    <Grid xs={12} container className={clsx(classes.container, classes.rowGap)}>
      <Grid xs={12}>
        <Typography variant='h6' color='primary'>
          {items.title}
        </Typography>
      </Grid>

      {children && (
        <>
          <Grid xs={12}>
            <Divider />
          </Grid>
          <Grid xs={12}>{children}</Grid>
        </>
      )}

      {items.total.map((item, index) => (
        <Grid xs={12} container justify='space-between' key={index} className={classes.rowGap}>
          <Grid xs={12}>
            <Divider />
          </Grid>
          <Grid xs={6}>
            <Typography variant='h6'>{item.label}</Typography>
          </Grid>
          <Grid xs={6}>
            <Typography variant='h6' align='right'>
              <Typography>Rp{new Intl.NumberFormat('id-ID').format(item.totalPrice)}</Typography>
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default ContentCard;
