import { Container, Grid, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import axios from 'axios';
import { Breadcrumb, Page, PaperCustom } from 'components';
import * as BASE_URL from 'constants/url';
import { format, startOfMonth } from 'date-fns';
import React, { useState, useEffect, useCallback } from 'react';
import { dummyReportProfit } from 'utils/dummy';
import ContentSection from './components/ContentSection';
import FilterSection from './components/FilterSection';
import HeaderSection from './components/HeaderSection';

export interface IReport<T> {
  isLoading: boolean;
  data: T;
}
const useStyles = makeStyles({
  container: {
    rowGap: '1em'
  }
});

const ReportProfitPage = () => {
  const classes = useStyles();
  const [reportProfitResource, setReportProfitResource] = useState<IReport<ReportProfit>>({
    isLoading: false,
    data: dummyReportProfit
  });
  const [isLoadingDownload, setIsLoadingDownload] = useState<boolean>(false);

  const [filter, setFilter] = useState<{
    from: Date;
    to: Date;
  }>({
    from: startOfMonth(new Date()),
    to: new Date()
  });

  const source = axios.CancelToken.source();
  const cancelToken = source.token;

  const handleChangeFilter = useCallback(
    <T,>(key: string, value: T) => {
      setFilter(prev => ({ ...prev, [key]: value }));
    },
    [filter]
  );

  const handleDownload = useCallback(async () => {
    setIsLoadingDownload(true);
    const params = new URLSearchParams();

    params.append('startDate', format(filter.from, 'yyyy-MM-dd'));
    params.append('endDate', format(filter.to, 'yyyy-MM-dd'));

    try {
      const { data } = await axios(`${BASE_URL.REPORT_PROFIT_BASE_URL}/download-pdf?${params}`, { responseType: 'blob' });
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      const newwindow = window.open(fileURL, 'name', 'height=700,width=750');
      if (newwindow) {
        newwindow.focus();
      }
      setIsLoadingDownload(false);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleRefresh = () => {
    setFilter(prev => ({ ...prev, from: startOfMonth(new Date()), to: new Date() }));
  };

  const fetchData = async () => {
    setReportProfitResource(prev => ({ ...prev, isLoading: true }));
    const urlOptions = ['income', 'mainLoad', 'totalOperationalCost'];
    const params = new URLSearchParams();
    params.append('startDate', format(filter.from, 'yyyy-MM-dd'));
    params.append('endDate', format(filter.to, 'yyyy-MM-dd'));

    const REPORT_URLS = urlOptions.map(urlOption =>
      axios.get(`${BASE_URL.REPORT_PROFIT_BASE_URL}/${urlOption}?${params}`, {
        cancelToken
      })
    );

    try {
      const [income, mainLoad, totalOperationalCost] = await Promise.all(REPORT_URLS);

      setReportProfitResource(prev => ({
        ...prev,
        isLoading: false,
        data: {
          ...prev.data,
          ...income.data.data,
          ...mainLoad.data.data,
          ...totalOperationalCost.data.data
        }
      }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    return () => source.cancel('Cancel Unmounted');
  }, [filter]);

  return (
    <Page title='Laporan Laba Rugi'>
      <Container>
        <Grid xs={12}>
          <Typography variant='h1' component='h1'>
            Laporan
          </Typography>
          <Breadcrumb />
        </Grid>
        <PaperCustom>
          <Grid xs={12} container className={classes.container}>
            <HeaderSection
              dateLabel={`Data tanggal ${format(filter.from, 'dd-MM-yyyy')} s/d ${format(filter.to, 'dd-MM-yyyy')}`}
              onClick={{ isLoadingDownload, handleDownload, handleRefresh }}
            />

            <FilterSection filter={filter} handleChangeFilter={handleChangeFilter} />
            {reportProfitResource.isLoading && (
              <Grid xs={12}>
                <LinearProgress color='primary' />
              </Grid>
            )}
            <ContentSection reportProfitResource={reportProfitResource} />
          </Grid>
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default ReportProfitPage;
