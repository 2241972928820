import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { Add, DeleteForever } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import axios from 'axios';
import FlexBox from 'components/FlexBox';
import { GRAY_4, GREY, PRIMARY, WHITE } from 'constants/colors';
import { WILAYAH_KABUPATEN_BASE_URL, WILAYAH_KECAMATAN_BASE_URL, WILAYAH_PROVINSI_BASE_URL } from 'constants/url';
import React, { Fragment, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { dummyPartner, dummyRegion } from 'utils/dummy';

const useStyles = makeStyles({
  container: {
    padding: '12px'
  },
  spacing: {
    margin: '1em 0'
  },
  paper: {
    border: '1px solid #E0E0E0',
    boxSizing: 'border-box',
    boxShadow: '0px 5px 12px rgba(0, 0, 0, 0.08)',
    borderRadius: '5px',
    width: '100%',
    height: '173px',
    paddingTop: 24
  },
  iconButton: {
    background: PRIMARY
  },
  iconBorder: {
    border: `1px solid ${GREY}`,
    boxSizing: 'border-box',
    marginRight: 18
  },
  icon: {
    color: WHITE
  },
  errorType: {
    textAlign: 'center',
    width: '100%'
  },
  secondary: {
    color: GRAY_4
  }
});
interface Props {
  handleSubmitCustomerReseller: (action?: string) => void;
  customerResource: { isOpen: boolean; isLoading: boolean; data: PartnerModel[] };
  setCustomerResource: React.Dispatch<React.SetStateAction<{ isOpen: boolean; isLoading: boolean; data: PartnerModel[] }>>;
}
const ResellerForm = ({ customerResource, setCustomerResource, handleSubmitCustomerReseller }: Props) => {
  const classes = useStyles();
  const [countCustomer, setCountCustomer] = useState<number[]>([0]);

  const [region, setRegion] = useState<RegionModel[]>([dummyRegion]);

  const [loadProvinsi, setLoadProvinsi] = useState<boolean>(false);
  const [loadKabupaten, setLoadKabupaten] = useState<boolean>(false);
  const [loadKecamatan, setLoadKecamatan] = useState<boolean>(false);
  const handleAddFormCustumerReseler = () => {
    setCountCustomer([...countCustomer, countCustomer.push(countCustomer[countCustomer.length - 1] + 1)]);
    setCustomerResource({ ...customerResource, data: [...customerResource.data, dummyPartner] });
  };

  const handleRemoveFormCustumerReseler = (index: number) => {
    setCountCustomer(countCustomer.filter((val, key) => index !== key));
    setCustomerResource({ ...customerResource, data: customerResource.data.filter((val, key) => index !== key) });
  };

  const handleAddCustumerReseler = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const temp = customerResource.data.map((value, key) => {
      if (key === index) {
        return { ...value, [event.target.id]: event.target.value };
      } else {
        return { ...value };
      }
    });
    setCustomerResource(prev => ({ ...prev, data: temp }));
  };

  useEffect(() => {
    setCountCustomer([0]);
  }, []);

  const handleSearchProvinsi = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    setLoadProvinsi(true);
    try {
      const { data } = await axios.get(`${WILAYAH_PROVINSI_BASE_URL}?${params.toString()}`);
      setRegion(data.data);
      setLoadProvinsi(false);
    } catch (error) {
      console.log('error :', error);
      setLoadProvinsi(false);
    }
  };

  const handleSearchKabupaten = async (code: string, value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    setRegion([dummyRegion]);
    setLoadKabupaten(true);
    try {
      const { data } = await axios.get(`${WILAYAH_KABUPATEN_BASE_URL(code)}?${params.toString()}`);
      setRegion(data.data);
      setLoadKabupaten(false);
    } catch (error) {
      console.log('error :', error);
      setLoadKabupaten(false);
    }
  };

  const handleSearchKecamatan = async (code: string, value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    setRegion([dummyRegion]);
    setLoadKecamatan(true);
    try {
      const { data } = await axios.get(`${WILAYAH_KECAMATAN_BASE_URL(code)}?${params.toString()}`, {});
      setRegion(data.data);
      setLoadKecamatan(false);
    } catch (error) {
      console.log('error :', error);
      setLoadKecamatan(false);
    }
  };

  return (
    <Dialog open={customerResource.isOpen} maxWidth={'md'} fullWidth={true} disableBackdropClick={true}>
      <DialogTitle id='customized-dialog-title'>Tambah Customer Reseller</DialogTitle>
      <DialogContent>
        {countCustomer.map((val, index) => (
          <>
            <Grid xs={12} container justify='space-between' spacing={2} key={index}>
              <Grid xs={6} item>
                <Typography variant='h6'>Informasi Umum</Typography>
                <TextField
                  id='name'
                  name='name'
                  required
                  fullWidth
                  label='Nama'
                  value={customerResource.data[index].name}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAddCustumerReseler(index, event)}
                />

                <TextField
                  id='phoneNumber'
                  fullWidth
                  label='Nomor Kontak'
                  value={customerResource.data[index].phoneNumber}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAddCustumerReseler(index, event)}
                />

                <TextField
                  id='email'
                  name='email'
                  type='email'
                  fullWidth
                  label='Email '
                  value={customerResource.data[index].email}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAddCustumerReseler(index, event)}
                />
              </Grid>
              <Grid xs={6} item>
                <Typography variant='h6'>Informasi Alamat</Typography>
                <TextField
                  id='address'
                  required
                  fullWidth
                  label='Alamat'
                  value={customerResource.data[index].address}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAddCustumerReseler(index, event)}
                />
                <Grid container xs={12} justify='space-between' alignItems='center'>
                  <FlexBox flex='0.49'>
                    <Autocomplete
                      id='provinsi'
                      fullWidth
                      options={region}
                      getOptionLabel={option => option.name}
                      getOptionSelected={(option, value) => option.code === value.code}
                      onChange={(event: any, value: any) => {
                        if (value) {
                          setCustomerResource(prev => ({
                            ...prev,
                            data: customerResource.data.map((val, key) => {
                              if (key === index) {
                                return { ...val, Provinsi: value };
                              } else {
                                return { ...val };
                              }
                            })
                          }));
                        }
                      }}
                      loading={loadProvinsi}
                      onOpen={e => handleSearchProvinsi('')}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label='Provinsi *'
                          onChange={e => handleSearchProvinsi(e.target.value)}
                          variant='outlined'
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <Fragment>
                                {loadProvinsi && <CircularProgress color='inherit' size={20} />}
                                {params.InputProps.endAdornment}
                              </Fragment>
                            )
                          }}
                        />
                      )}
                    />
                  </FlexBox>
                  <FlexBox flex='0.49'>
                    <Autocomplete
                      id='kabupaten'
                      fullWidth
                      options={region}
                      getOptionLabel={option => option.name}
                      getOptionSelected={(option, value) => option.code === value.code}
                      onChange={(event: any, value: any) => {
                        if (value) {
                          setCustomerResource(prev => ({
                            ...prev,
                            data: customerResource.data.map((val, key) => {
                              if (key === index) {
                                return { ...val, Kabupaten: value };
                              } else {
                                return { ...val };
                              }
                            })
                          }));
                        }
                      }}
                      loading={loadKabupaten}
                      onOpen={e => handleSearchKabupaten(customerResource.data[index].Provinsi!.code, '')}
                      renderInput={params => (
                        <TextField
                          {...params}
                          fullWidth
                          label='Kabupaten *'
                          onChange={e => handleSearchKabupaten(customerResource.data[index].Provinsi!.code, e.target.value)}
                          variant='outlined'
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <Fragment>
                                {loadKabupaten && <CircularProgress color='inherit' size={20} />}
                                {params.InputProps.endAdornment}
                              </Fragment>
                            )
                          }}
                        />
                      )}
                    />
                  </FlexBox>
                </Grid>
                <Grid container xs={12} justify='space-between'>
                  <FlexBox flex='0.49'>
                    <Autocomplete
                      id='kecamatan'
                      fullWidth
                      options={region}
                      getOptionLabel={option => option.name}
                      getOptionSelected={(option, value) => option.code === value.code}
                      onChange={(event: any, value: any) => {
                        if (value) {
                          setCustomerResource(prev => ({
                            ...prev,
                            data: customerResource.data.map((val, key) => {
                              if (key === index) {
                                return { ...val, Kecamatan: value };
                              } else {
                                return { ...val };
                              }
                            })
                          }));
                        }
                      }}
                      loading={loadKecamatan}
                      onOpen={e => handleSearchKecamatan(customerResource.data[index].Kabupaten!.code, '')}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label='Kecamatan *'
                          size='small'
                          onChange={e => handleSearchKecamatan(customerResource.data[index].Kabupaten!.code, e.target.value)}
                          variant='outlined'
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <Fragment>
                                {loadKecamatan && <CircularProgress color='inherit' size={20} />}
                                {params.InputProps.endAdornment}
                              </Fragment>
                            )
                          }}
                        />
                      )}
                    />
                  </FlexBox>
                  <FlexBox flex='0.49'>
                    <TextField
                      id='postalCode'
                      fullWidth
                      label='Kode Pos'
                      value={customerResource.data[index].postalCode}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAddCustumerReseler(index, event)}
                    />
                  </FlexBox>

                  <Grid xs={12} style={{ marginTop: '0.8em' }}>
                    <ReactQuill
                      id='notes'
                      value={customerResource.data[index].notes}
                      onChange={(value: any) =>
                        setCustomerResource(prev => ({
                          ...prev,
                          data: customerResource.data.map((val, key) => {
                            if (key === index) {
                              return { ...val, notes: value };
                            } else {
                              return { ...val };
                            }
                          })
                        }))
                      }
                      placeholder='Catatan'
                    />
                  </Grid>
                </Grid>

                <Grid xs={12} container justify='flex-end' className={classes.spacing}>
                  {index !== countCustomer.length - 1 ? (
                    <Button
                      variant='text'
                      className={classes.secondary}
                      size='small'
                      startIcon={<DeleteForever className={classes.secondary} />}
                      onClick={() => handleRemoveFormCustumerReseler(index)}
                    >
                      Hapus Customer
                    </Button>
                  ) : (
                    <>
                      {index > 0 && (
                        <Button
                          variant='text'
                          className={classes.secondary}
                          size='small'
                          startIcon={<DeleteForever className={classes.secondary} />}
                          onClick={() => handleRemoveFormCustumerReseler(index)}
                        >
                          Hapus Customer
                        </Button>
                      )}
                      <Button
                        variant='text'
                        color='primary'
                        size='small'
                        disabled={
                          customerResource.data[index].name === '' ||
                          customerResource.data[index].address === '' ||
                          !customerResource.data[index].Provinsi ||
                          !customerResource.data[index].Kabupaten ||
                          !customerResource.data[index].Kecamatan
                        }
                        startIcon={<Add />}
                        onClick={handleAddFormCustumerReseler}
                      >
                        Tambah Customer
                      </Button>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>
        ))}
      </DialogContent>
      <DialogActions>
        <Grid xs={12} container justify='center'>
          <FlexBox xs={11} container justify='flex-end' columnGap={1}>
            <Button color='secondary' onClick={() => handleSubmitCustomerReseller('cancel')}>
              Batal
            </Button>
            <Button
              disabled={
                customerResource.isLoading ||
                customerResource.data.filter(val => val.name === '' || val.address === '' || !val.Provinsi || !val.Kabupaten || !val.Kecamatan)
                  .length > 0
              }
              onClick={() => handleSubmitCustomerReseller()}
            >
              {customerResource.isLoading ? <CircularProgress size={20} /> : 'Simpan'}
            </Button>
          </FlexBox>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ResellerForm;
