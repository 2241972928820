import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { GREEN } from 'constants/colors';
import useRouter from 'hooks/useRouter';
import React, { FC } from 'react';

interface Props {
  open: boolean;
  handleClick: () => void;
}
const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    fontWeight: 500,
    fontSize: '1.2rem'
  },
  button: {
    borderRadius: '15px',
    padding: '7px 0'
  }
}));
const SuccessModal = ({ open, handleClick }: Props) => {
  const classes = useStyles();
  const { history } = useRouter();

  return (
    <Dialog open={open} style={{ maxHeight: 80 + 'vh', marginTop: '60px' }} scroll='paper' fullWidth>
      <DialogTitle>
        <Grid item container direction='column' alignItems='center' xs={12}>
          <CheckCircle fontSize='large' style={{ color: GREEN, fontSize: '3.5rem' }} />
          <Typography className={classes.titleContainer}>Sukses</Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid xs={12} container justify='center'>
          <Grid xs={10}>
            <Typography align='center'>Berhasil melakukan pembayaran</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid xs={12} container justify='center'>
          <Grid xs={10}>
            <Button size='small' fullWidth className={classes.button} color='primary' onClick={handleClick}>
              Tutup
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessModal;
