import React, { FC, useState, useEffect } from 'react';
import { Button, Container, Grid, makeStyles, Typography, Divider, CircularProgress } from '@material-ui/core';
import axios from 'axios';
import useRouter from 'hooks/useRouter';
import DetailInvoice from './components/DetailInvoice';
import { Page, Breadcrumb } from 'components';
import { PRIMARY, WHITE, YELLOW } from 'constants/colors';
import {
  GET_PURCHASE_INVOICE_DETAIL_BASE_URL,
  COMPANY_BASE_URL,
  PURCHASE_INVOICE_BASE_URL,
  GET_PURCHASE_INVOICE_ITEMS_BASE_URL,
  PURCHASE_PAYMENT
} from 'constants/url';
import { dummyPurchaseInvoice, dummyCompany, dummyInvoicePayment } from 'utils/dummy';
import { format } from 'date-fns';
import NumberFormat from 'react-number-format';
import PurchasePaymentModal from './components/PurchasePayment/PurchasePaymentModal';
import PaymentStatus from 'typings/enum/PaymentStatus';
import { NoteAdd, Payment } from '@material-ui/icons';
import FlexBox from 'components/FlexBox';
import SuccessModal from './components/PurchasePayment/SuccessModal';
import clsx from 'clsx';
import InvoicePayment from './components/PurchasePayment/InvoicePayment';

const useStyles = makeStyles({
  box: {
    backgroundColor: WHITE,
    borderRadius: '5px',
    padding: 24,
    border: '1px solid #F2F2F2',
    boxShadow: '0px 5px 12px rgba(0, 0, 0, 0.08)',
    marginTop: 10,
    marginBottom: 10,
    overflowX: 'auto'
  },
  boxTitle: {
    marginBottom: 8
  },
  invoice: {
    padding: '.1em 1em',
    color: WHITE,
    marginBottom: '1em',
    borderRadius: '1em'
  },
  invoiceUnpaid: {
    background: YELLOW
  },
  invoicePaid: {
    background: PRIMARY
  }
});

const PurchaseInvoiceDetailPage: FC = () => {
  const { location } = useRouter();
  // eslint-disable-next-line
  const params: any = location.state;
  const classes = useStyles();
  const [invoicePaymentConfirm, setInvoicePaymentConfirm] = useState<boolean>(false);
  const [invoicePaymentData, setInvoicePaymentData] = useState<InvoicePaymentModel>(dummyInvoicePayment);
  const [invoicePaymentDataLoading, setInvoicePaymentDataLoading] = useState<boolean>(false);
  const [purchaseInvoice, setPurchaseInvoice] = useState<PurchaseInvoiceModel>(dummyPurchaseInvoice);
  const [hasSuccess, setHasSuccess] = useState<boolean>(false);
  const [company, setCompany] = useState<CompanyModel>(dummyCompany);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);
  const [invoiceItem, setInvoiceItem] = useState<PurchaseInvoiceItemModel[]>([]);
  const [invoicePaymentClicked, setInvoicePaymentClicked] = useState<{ id: number; isOpen: boolean }>({
    id: 0,
    isOpen: false
  });
  const [invoicePayment, setInvoicePayment] = useState<InvoicePaymentModel[]>([]);
  const fetchData = async () => {
    setIsLoadingData(true);

    Promise.all([
      axios.get(GET_PURCHASE_INVOICE_DETAIL_BASE_URL(params.id)),
      axios.get(GET_PURCHASE_INVOICE_ITEMS_BASE_URL(params.id)),
      axios.get(COMPANY_BASE_URL)
    ])
      .then(result => {
        const inv = result[0].data.data;
        setPurchaseInvoice(inv);
        setId(inv.id);

        const itm = result[1].data.data;
        setInvoiceItem(itm);

        const cpy = result[2].data.data;
        setCompany(cpy);

        handleChangeInvoicePayment<number>('InvoiceId', inv.id);
        handleChangeInvoicePayment<number>('totalPay', inv.totalPrice - inv.totalPay);
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoadingData(false));
  };

  const handlePayment = async () => {
    setInvoicePaymentDataLoading(true);
    const dataFinal = { ...invoicePaymentData, ['InvoiceId']: [invoicePaymentData.InvoiceId] };
    try {
      const { data } = await axios.post(`${PURCHASE_PAYMENT}`, dataFinal);
      setInvoicePayment(prev => [...prev, data.data]);
      setPurchaseInvoice(prev => ({
        ...prev,
        totalPay: prev.totalPay + dataFinal.totalPay,
        statusPayment: prev.totalPrice === prev.totalPay + dataFinal.totalPay ? 'PAID' : 'UNPAID'
      }));
      setHasSuccess(true);
      setInvoicePaymentDataLoading(false);
      setInvoicePaymentConfirm(false);
    } catch (error) {
      console.log('error :', error);
    }
  };
  const handleOpenInvoicePayment = () => setInvoicePaymentConfirm(true);
  const handleClickInvoicePayment = (id: number) => setInvoicePaymentClicked(prev => ({ ...prev, id, isOpen: true }));
  const fetchPayment = async () => {
    try {
      const { data } = await axios.get(`${PURCHASE_PAYMENT}?InvoiceId=${params.id}`);
      setInvoicePayment(data.data);
    } catch (error) {
      console.log('error:', error);
    }
  };

  const handlePaymentStatus = (type: 'PAID' | 'UNPAID') => {
    if (type === PaymentStatus.UNPAID) {
      handleChangeInvoicePayment<number>('totalPay', 0);
    } else {
      handleChangeInvoicePayment<number>('totalPay', purchaseInvoice.totalPrice - purchaseInvoice.totalPay);
    }
  };
  const handleChangeInvoicePayment = <T,>(key: string, value: T) =>
    setInvoicePaymentData(prev => ({
      ...prev,
      [key]: value
    }));

  const downloadPdf = async () => {
    setIsLoadingDownload(true);
    try {
      const { data } = await axios.get(`${PURCHASE_INVOICE_BASE_URL}/download/${id}`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `purchase-invoice-${purchaseInvoice.invoiceNumber}.pdf`);
      document.body.appendChild(link);
      setIsLoadingDownload(false);
      link.click();
    } catch (error) {
      console.log('error :', error);
    }
  };

  useEffect(() => {
    fetchData();

    fetchPayment();
  }, [params]);

  return (
    <Page title='Purchase Invoice Detail'>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <Typography variant='h1'> Pembelian </Typography>
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Breadcrumb />
          </Grid>

          <FlexBox container item lg={6} sm={6} md={6} xs={6} justify='flex-end' columnGap={1}>
            <Button disabled={isLoadingDownload || isLoadingData} onClick={downloadPdf} startIcon={<NoteAdd />} size='small'>
              {isLoadingDownload ? <CircularProgress size={20} color='primary' /> : 'Download PDF'}
            </Button>
            <Button
              startIcon={<Payment />}
              color='primary'
              variant='contained'
              size='small'
              disabled={purchaseInvoice.statusPayment === PaymentStatus.PAID || isLoadingData}
              onClick={handleOpenInvoicePayment}
            >
              Pembayaran
            </Button>
          </FlexBox>

          <Grid item justify='space-between' lg={12} xl={12} md={12} sm={12} xs={12} container spacing={1} className={classes.box}>
            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
              <Typography variant='h6' className={classes.boxTitle}>
                Nama Supplier
              </Typography>
              <Typography variant='body2'> {purchaseInvoice.Partner ? purchaseInvoice.Partner.name : ''} </Typography>
            </Grid>

            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
              <Typography variant='h6' className={classes.boxTitle}>
                Tanggal Terbit
              </Typography>
              <Typography variant='body2'> {format(new Date(purchaseInvoice.orderDate), 'dd MMM YYY')} </Typography>
            </Grid>

            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
              <Typography variant='h6' className={classes.boxTitle}>
                Jatuh Tempo
              </Typography>
              <Typography variant='body2'> {format(new Date(purchaseInvoice.dueDate), 'dd MMM YYY')} </Typography>
            </Grid>

            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
              <Typography variant='h6' className={classes.boxTitle} align='right'>
                Total Tagihan
              </Typography>
              <Typography variant='body2' align='right'>
                <NumberFormat value={purchaseInvoice.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
              </Typography>
            </Grid>

            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
              <Typography variant='h6' className={classes.boxTitle} align='right'>
                Sisa Tagihan
              </Typography>
              <Typography variant='body2' align='right'>
                <NumberFormat
                  value={purchaseInvoice.totalPrice - purchaseInvoice.totalPay}
                  prefix={'Rp'}
                  thousandSeparator={true}
                  displayType='text'
                />
              </Typography>
            </Grid>

            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
              <Typography variant='h6' className={classes.boxTitle} align='right'>
                Status
              </Typography>
              {!isLoadingData && (
                <Grid container justify='flex-end'>
                  <Typography
                    variant='h6'
                    className={clsx(
                      classes.invoice,
                      purchaseInvoice.statusPayment === PaymentStatus.PAID ? classes.invoicePaid : classes.invoiceUnpaid
                    )}
                  >
                    {purchaseInvoice.statusPayment === PaymentStatus.PAID ? 'Lunas' : 'Belum Lunas'}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid container direction='row' spacing={2} justify='space-between'>
            <DetailInvoice
              purchaseInvoice={purchaseInvoice}
              invoiceItem={invoiceItem}
              handleClickInvoicePayment={handleClickInvoicePayment}
              company={company}
              isLoadingData={isLoadingData}
              invoicePayment={invoicePayment}
            />
          </Grid>
          <PurchasePaymentModal
            open={invoicePaymentConfirm}
            handleClose={() => setInvoicePaymentConfirm(false)}
            handleSubmit={handlePayment}
            handlePaymentStatus={handlePaymentStatus}
            handleChangePurchasePayment={handleChangeInvoicePayment}
            purchasePayment={invoicePaymentData}
            isLoading={invoicePaymentDataLoading}
            totalPrice={purchaseInvoice.totalPrice - purchaseInvoice.totalPay}
          />
          <SuccessModal open={hasSuccess} handleClick={() => setHasSuccess(false)} />
          <InvoicePayment
            open={invoicePaymentClicked.isOpen}
            handleClick={() => setInvoicePaymentClicked(prev => ({ ...prev, isOpen: false }))}
            invoicePayment={invoicePayment.find(payment => payment.id === invoicePaymentClicked.id) || dummyInvoicePayment}
          />
        </Grid>
      </Container>
    </Page>
  );
};

export default PurchaseInvoiceDetailPage;
