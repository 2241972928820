import React, { FC } from 'react';
import {
  Checkbox,
  ListItemText,
  makeStyles,
  Grid,
  MenuItem,
  Select,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  TableBody,
  TableCell
} from '@material-ui/core';
import { HeaderRow, TableCellHead, TableCustom } from 'components';
import BodyTable from './BodyTable';
import { Skeleton } from '@material-ui/lab';
import useRouter from 'hooks/useRouter';
interface Props {
  deliveryOrders: DeliveryOrders[];
  deliveryNumber: string;
  isLoading: boolean;
  isDeliveryPrint: { isLoading: boolean; id: number };
  handleDeliveryPrint: (id: number) => void;
  partnerName: string;
  setPartnerName: React.Dispatch<React.SetStateAction<string>>;
  setDeliveryNumber: React.Dispatch<React.SetStateAction<string>>;
}
const useStyles = makeStyles((theme: Theme) => ({
  cellTable: {
    width: '20%'
  },
  cellTableSecond: {
    width: '10%'
  },
  container: {
    minHeight: '50vh'
  }
}));
const BodySection: FC<Props> = (props): JSX.Element => {
  const classes = useStyles();
  const { history } = useRouter();
  const { deliveryOrders, isLoading, setDeliveryNumber, deliveryNumber, partnerName, setPartnerName, isDeliveryPrint, handleDeliveryPrint } = props;
  const handleLink = (id: number) => history.push(`/surat-jalan/${id}`);
  return (
    <Grid xs={12} className={classes.container}>
      <TableContainer>
        <TableCustom>
          <TableHead>
            <HeaderRow
              headers={[
                { id: 'doNumber', label: 'Nomor Surat Jalan', sort: true },
                { id: 'name', label: 'Nama Customer', sort: true },
                { id: 'createdAt', label: 'Tgl Surat Jalan', sort: true },
                { id: 'totalColly', label: 'Total Koli', sort: true },
                { id: 'invoiceNumber', label: 'Totoal Invoice', sort: true },
                { id: 'courier', label: 'Nama Kurir', sort: true }
              ]}
            />
            <TableRow>
              <TableCellHead variant='head' className={classes.cellTable}>
                <TextField placeholder='Nomor surat jalan' value={deliveryNumber} onChange={event => setDeliveryNumber(event.target.value)} />
              </TableCellHead>
              <TableCellHead variant='head' className={classes.cellTable}>
                <TextField placeholder='Nama Customer' value={partnerName} onChange={event => setPartnerName(event.target.value)} />
              </TableCellHead>
              <TableCellHead variant='head' className={classes.cellTable} />
              <TableCellHead variant='head' className={classes.cellTableSecond} />
              <TableCellHead variant='head' className={classes.cellTableSecond} />
              <TableCellHead variant='head' className={classes.cellTable} />
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={7} style={{ padding: 0 }}>
                  <Grid xs={12} container>
                    {[...new Array(5)].map((val, key) => (
                      <Grid xs={12}>
                        <Skeleton variant='text' animation='wave' height={60} key={key} />
                      </Grid>
                    ))}
                  </Grid>
                </TableCell>
              </TableRow>
            ) : deliveryOrders.length > 0 ? (
              deliveryOrders.map((deliveryOrder, key) => (
                <BodyTable
                  handleLink={() => handleLink(deliveryOrder.id)}
                  deliveryOrder={deliveryOrder}
                  handleDeliveryPrint={() => handleDeliveryPrint(deliveryOrder.id)}
                  isDeliveryPrint={isDeliveryPrint}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align='center'>
                  Data Tidak Tersedia.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </TableCustom>
      </TableContainer>
    </Grid>
  );
};

export default BodySection;
