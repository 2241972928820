import React, { FC, Fragment, useState } from 'react';
import {
  Grid,
  makeStyles,
  Theme,
  Typography,
  TableContainer,
  Paper,
  Tabs,
  Tab,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  ButtonGroup,
  CircularProgress,
  FormHelperText,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Divider
} from '@material-ui/core';
import ListProduct from './ListProduct';
import Skeleton from '@material-ui/lab/Skeleton';
import NumberFormat from 'react-number-format';
import { BLACK, RED, WHITE, GREEN, PRIMARY } from 'constants/colors';
import { TabPanel, a11yProps, StandardConfirmationDialog } from 'components';
import ItemStatus from 'typings/enum/ItemStatus';
import clx from 'clsx';
import PartnerModal from './PartnerModal';
import { dummyPartner } from 'utils/dummy';

interface Props {
  salesOrder: SalesOrderModel;
  company: CompanyModel;
  isLoadingData: boolean;
  salesOrderItem: SalesOrderItemModel[];
  value: number;
  isChange: boolean;
  hasInvoice: boolean;
  isChangeSubmit: boolean;
  isConvertAllowed: boolean;
  consistenMessage: string;
  currentWareHouse: string;
  totalWarehouse: { name: string; total: number }[];
  currentCheckWarehouse: string;
  anchorEl: any;
  buttonGroup: React.RefObject<HTMLDivElement>;
  totalApprove: number;
  setCurrentCheckWarehouse: React.Dispatch<React.SetStateAction<string>>;
  isValid: boolean;
  indexTooltip: number;
  openTooltip: boolean;
  handleOpenTooltip: (index: number) => React.MouseEventHandler;
  handleCloseTooltip: () => void;
  handleChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  handleConfirm: (index: number) => React.MouseEventHandler;
  handleReject: (index: number) => React.MouseEventHandler;
  handleOnSubmit: () => void;
  handleReset: () => void;
  openAlertPrice: boolean;
  indexAlertPrice: number;
  handleOpenAlertPrice: (index: number) => React.MouseEventHandler;
  handleCloseAlertPrice: () => void;

  openAlertDiscount: boolean;
  indexAlertDiscount: number;
  handleOpenAlertDiscount: (index: number) => React.MouseEventHandler;
  handleCloseAlertDiscount: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  cellSummaryRight: {
    border: 'none',
    padding: 4,
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontStyle: 'normal',
    color: BLACK
  },
  cellSummaryLeft: {
    width: '26%',
    border: 'none',
    padding: 4,
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal',
    color: BLACK
  },
  cellInfoRight: {
    padding: '2px 0px 2px 2px',
    border: 'none',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal'
  },
  cellInfoLeft: {
    padding: '2px 0px 2px 0px',
    border: 'none',
    fontSize: '0.8rem',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal'
  },
  cellFinalRight: {},
  cellFinalLeft: {},
  spaceTop: {
    marginTop: 10
  },
  cellFinalText: {
    marginRight: 105,
    marginTop: 10,
    paddingTop: 10
  },
  cellFinalRightText: {
    marginRight: 65,
    marginBottom: 10,
    paddingBottom: 10
  },
  paper: {
    border: '1px solid #ccc',
    padding: 2,
    backgroundColor: WHITE,
    marginTop: 4,
    borderRadius: 8
  },
  bigger: {
    backgroundColor: '#ffb400',
    color: WHITE,
    transform: 'scale(1.5) perspective(1px)'
  },
  cellOrderRightUnderline: {
    padding: '2px 0px 2px 2px',
    border: 'none',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal',
    color: BLACK,
    textDecorationLine: 'underline',
    cursor: 'pointer'
  },
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    fontSize: 0.5 + 'rem',
    width: '100px',
    '&:hover': {
      backgroundColor: GREEN
    }
  },
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: ' none'
    }
  },

  mobileContainer: {
    [theme.breakpoints.down('md')]: {
      margin: 0,
      '& > .MuiGrid-item': {
        padding: 0
      }
    }
  },

  desktop: {
    [theme.breakpoints.down('md')]: {
      display: ' none'
    }
  },
  fontMobile: {
    fontSize: 0.67 + 'rem',
    color: BLACK,
    lineHeight: 1 + 'rem'
  }
}));

const ProduckRequest: FC<Props> = props => {
  const classes = useStyles();
  const {
    salesOrder,
    isLoadingData,
    company,
    salesOrderItem,
    value,
    isChange,
    hasInvoice,
    isChangeSubmit,
    isConvertAllowed,
    consistenMessage,
    currentWareHouse,
    totalWarehouse,
    currentCheckWarehouse,
    anchorEl,
    buttonGroup,
    totalApprove,
    isValid,
    indexTooltip,
    openTooltip,
    handleOpenTooltip,
    handleCloseTooltip,
    setCurrentCheckWarehouse,
    handleOnSubmit,
    handleChange,
    handleConfirm,
    handleReject,
    handleReset,
    openAlertPrice,
    indexAlertPrice,
    handleOpenAlertPrice,
    handleCloseAlertPrice,
    openAlertDiscount,
    indexAlertDiscount,
    handleOpenAlertDiscount,
    handleCloseAlertDiscount
  } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleConfirmationSnackbar = () => {
    setOpenSnackbar(false);
    setOpen(false);
  };

  const handleOnClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Grid
        item
        lg={6}
        sm={12}
        md={12}
        xs={12}
        classes={{
          root: classes.mobileContainer
        }}
      >
        <Typography className={classes.fontMobile} style={{ fontWeight: 'bold', paddingBottom: '0.4em' }}>
          Daftar Permintaan Produk
        </Typography>

        <Typography className={classes.fontMobile} style={{ fontWeight: 500, color: PRIMARY }}>
          Total Item: 3
        </Typography>
      </Grid>

      <Grid container item direction='row' alignItems='center' xs={12} sm={12} md={12}>
        <Grid item>
          <FormControlLabel
            control={<Checkbox checked={true} color='primary' />}
            label={
              <Typography className={classes.fontMobile} style={{ fontWeight: 500 }}>
                Avian (2)
              </Typography>
            }
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={<Checkbox checked={false} color='secondary' />}
            label={
              <Typography className={classes.fontMobile} style={{ fontWeight: 500 }}>
                Garuda (1)
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={4}>
          <Button color='inherit' onClick={handleReset} className={clx(classes.refresh)}>
            Memuat Ulang
          </Button>
        </Grid>
      </Grid>
      <Grid item lg={12} sm={12} md={12} xs={12}>
        <Tabs value={value} indicatorColor='primary' textColor='inherit' onChange={handleChange}>
          <Tab className={classes.fontMobile} label={`Semua Produk (${salesOrderItem.length})`} {...a11yProps(0)} />
          <Tab
            className={classes.fontMobile}
            label={`Disetujui (${salesOrderItem.filter(value => value.status === ItemStatus.CONFIRM).length})`}
            {...a11yProps(1)}
          />
          <Tab
            className={classes.fontMobile}
            label={`Ditolak (${salesOrderItem.filter(value => value.status === ItemStatus.REJECT).length})`}
            {...a11yProps(2)}
          />
        </Tabs>

        <Divider className={classes.mobile} style={{ marginBottom: `10px` }} />

        <TabPanel value={value} index={0}>
          <ListProduct
            key={1}
            tab={value}
            handleConfirm={handleConfirm}
            handleReject={handleReject}
            isLoadingData={isLoadingData}
            salesOrderItem={
              currentCheckWarehouse === ''
                ? salesOrderItem
                : salesOrderItem.filter(value => value.warehouse && value.warehouse === currentCheckWarehouse)
            }
            hasInvoice={hasInvoice}
            isConvertAllowed={isConvertAllowed}
            salesOrder={salesOrder}
            currentWareHouse={currentWareHouse}
            indexTooltip={indexTooltip}
            openTooltip={openTooltip}
            handleOpenTooltip={handleOpenTooltip}
            handleCloseTooltip={handleCloseTooltip}
            openAlertPrice={openAlertPrice}
            indexAlertPrice={indexAlertPrice}
            handleOpenAlertPrice={handleOpenAlertPrice}
            handleCloseAlertPrice={handleCloseAlertPrice}
            openAlertDiscount={openAlertDiscount}
            indexAlertDiscount={indexAlertDiscount}
            handleOpenAlertDiscount={handleOpenAlertDiscount}
            handleCloseAlertDiscount={handleCloseAlertDiscount}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <ListProduct
            key={2}
            tab={value}
            handleConfirm={handleConfirm}
            handleReject={handleReject}
            isLoadingData={isLoadingData}
            salesOrderItem={salesOrderItem.filter(value => value.status === ItemStatus.CONFIRM)}
            hasInvoice={hasInvoice}
            isConvertAllowed={isConvertAllowed}
            salesOrder={salesOrder}
            currentWareHouse={currentWareHouse}
            indexTooltip={indexTooltip}
            openTooltip={openTooltip}
            handleOpenTooltip={handleOpenTooltip}
            handleCloseTooltip={handleCloseTooltip}
            openAlertPrice={openAlertPrice}
            indexAlertPrice={indexAlertPrice}
            handleOpenAlertPrice={handleOpenAlertPrice}
            handleCloseAlertPrice={handleCloseAlertPrice}
            openAlertDiscount={openAlertDiscount}
            indexAlertDiscount={indexAlertDiscount}
            handleOpenAlertDiscount={handleOpenAlertDiscount}
            handleCloseAlertDiscount={handleCloseAlertDiscount}
          />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <ListProduct
            key={3}
            tab={value}
            handleConfirm={handleConfirm}
            handleReject={handleReject}
            isLoadingData={isLoadingData}
            salesOrderItem={salesOrderItem.filter(value => value.status === ItemStatus.REJECT)}
            hasInvoice={hasInvoice}
            isConvertAllowed={isConvertAllowed}
            salesOrder={salesOrder}
            currentWareHouse={currentWareHouse}
            indexTooltip={indexTooltip}
            openTooltip={openTooltip}
            handleOpenTooltip={handleOpenTooltip}
            handleCloseTooltip={handleCloseTooltip}
            openAlertPrice={openAlertPrice}
            indexAlertPrice={indexAlertPrice}
            handleOpenAlertPrice={handleOpenAlertPrice}
            handleCloseAlertPrice={handleCloseAlertPrice}
            openAlertDiscount={openAlertDiscount}
            indexAlertDiscount={indexAlertDiscount}
            handleOpenAlertDiscount={handleOpenAlertDiscount}
            handleCloseAlertDiscount={handleCloseAlertDiscount}
          />
        </TabPanel>
      </Grid>

      <Grid container item lg={12} sm={12} md={12} xs={12} direction='row' justify='space-between' className={classes.desktop}>
        <Grid item lg={6} sm={12} md={6} xs={12}>
          {isConvertAllowed && !hasInvoice ? (
            salesOrder.statusOrder !== 'CANCEL' ? (
              <>
                <Tooltip title={`${totalApprove} Produk.`}>
                  <span>
                    <Button disabled={!isChange || isValid} size='small' onClick={handleOnSubmit}>
                      {isChangeSubmit ? <CircularProgress color='inherit' size={20} /> : `Simpan Perubahan ${totalApprove}`}
                    </Button>
                  </span>
                </Tooltip>
                <FormHelperText error={consistenMessage !== ''}>{consistenMessage}</FormHelperText>
              </>
            ) : (
              ''
            )
          ) : (
            ''
          )}
        </Grid>
        <Grid item lg={6} sm={12} md={6} xs={12} container justify='flex-end'>
          <Button color='inherit' size='small' onClick={handleReset} className={clx(classes.refresh)}>
            Memuat Ulang
          </Button>
        </Grid>
      </Grid>

      <Grid container item lg={6} sm={12} md={6} xs={12} className={classes.desktop}>
        <Grid item lg={12} sm={12} md={12} xs={12}>
          <Typography variant='h5' className={classes.cellFinalLeft}>
            Keterangan
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: salesOrder.notes || '-' }} className={classes.cellFinalLeft}></div>
        </Grid>
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.desktop}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell align='right' className={classes.cellSummaryRight}>
                  Subtotal
                </TableCell>
                <TableCell align='right' className={classes.cellSummaryLeft}>
                  <NumberFormat value={salesOrder.totalPrice + salesOrder.totalDiscount} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='right' className={classes.cellSummaryRight}>
                  Diskon
                </TableCell>
                <TableCell align='right' className={classes.cellSummaryLeft}>
                  <NumberFormat value={salesOrder.totalDiscount} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='right' className={classes.cellSummaryRight}>
                  Total Harga
                </TableCell>
                <TableCell align='right' className={classes.cellSummaryLeft}>
                  <NumberFormat value={salesOrder.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='right' className={classes.cellSummaryRight}>
                  Jumlah Tertagih
                </TableCell>
                <TableCell align='right' className={classes.cellSummaryLeft}>
                  <NumberFormat value={salesOrder.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <PartnerModal partners={salesOrder.Partner || dummyPartner} open={open} setOpen={setOpen} onCancel={handleOnClose} />
      <StandardConfirmationDialog
        variant={snackbarVariant}
        titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
        message={message}
        open={openSnackbar}
        handleClose={snackbarVariant === 'success' ? handleConfirmationSnackbar : handleCloseSnackbar}
        onConfirm={handleConfirmationSnackbar}
        noCancelButton={true}
      />
    </Fragment>
  );
};

export default ProduckRequest;
