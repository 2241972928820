enum TypeUser {
  SALES = 'SALES',
  ADMIN = 'HEAD_ADMIN',
  SUPERADMIN = 'SUPERADMIN',
  SUPERVISOR = 'SALES_SPV',
  DRIVER = 'DRIVER',
  ADMINWAREHOUSE = 'ADMINWAREHOUSE',
  ADMIN_RETURN = 'ADMIN_RETURN'
}

export default TypeUser;
