import React, { FC } from 'react';
import { Card, CardContent, Typography, makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import clx from 'clsx';

interface Props {
  partner:
    | {
        id: number;
        name: string;
        address: string;
        remarkAddress: string;
      }
    | PartnerModel;
  isLoading: boolean;
  isSelected: boolean;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  cover: {
    width: 75
  },
  selected: {
    boxShadow: '0px 5px 12px rgba(0, 136, 186, 0.4)'
  }
});

const CardCustomer: FC<Props> = props => {
  const classes = useStyles();
  const { partner, isLoading, isSelected } = props;

  return (
    <Card className={clx(classes.root, isSelected && classes.selected)}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography variant='subtitle1'>{isLoading ? <Skeleton variant='text' width={210} height={25} /> : partner.name}</Typography>

          <Typography variant='subtitle1' color='textSecondary'>
            {isLoading ? <Skeleton variant='text' width={210} height={25} /> : `Alamat: ${partner.address}, (${partner.remarkAddress || '-'})`}
          </Typography>
        </CardContent>
      </div>
    </Card>
  );
};

export default CardCustomer;
