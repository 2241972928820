import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { WarningRounded } from '@material-ui/icons';
import { YELLOW } from 'constants/colors';
import React, { FC } from 'react';

interface Props {
  open: boolean;
  isLoading: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '2em',
    display: 'flex',
    rowGap: '0.8em',
    flexDirection: 'column'
  },
  button: {
    borderRadius: '5px',
    padding: '7px 0'
  }
}));
const ConvetConfirm: FC<Props> = ({ open, isLoading, handleClose, handleSubmit }) => {
  const classes = useStyles();
  return (
    <Dialog open={open}>
      <Grid xs={12} className={classes.container}>
        <Grid item container direction='column' alignItems='center' xs={12}>
          <WarningRounded style={{ color: YELLOW, fontSize: '3.5rem' }} />
          <Typography variant='h5'>Konfirmasi Konversi Invoice dan Surat Jalan</Typography>
        </Grid>

        <Grid xs={12} container justify='center'>
          <Grid xs={10}>
            <Typography align='center' variant='h6'>
              Apakah anda yakin ingin mengkonversi Sales Order menjadi Invoice dan Surat Jalan?
            </Typography>
          </Grid>
        </Grid>

        <Grid xs={12} container justify='center'>
          <Grid xs={12} container style={{ columnGap: '1.5em' }} justify='center'>
            <Grid xs={5}>
              <Button size='small' fullWidth className={classes.button} color='primary' variant='outlined' onClick={handleClose}>
                Batalkan
              </Button>
            </Grid>
            <Grid xs={5}>
              <Button size='small' fullWidth className={classes.button} color='primary' disabled={isLoading} onClick={handleSubmit}>
                {isLoading ? <CircularProgress size={22} color='primary' /> : 'Convert Invoice'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ConvetConfirm;
