import React, { FC, useState, useEffect } from 'react';
import { Container, makeStyles, Typography, Grid, Button, Breadcrumbs, Link } from '@material-ui/core';
import { Page, StandardConfirmationDialog } from 'components';
import { COMPANY_BASE_URL, BANK_BASE_URL, DELETE_BANK_BASE_URL } from 'constants/url';
import { dummyCompany, dummyBank } from 'utils/dummy';
import axios from 'axios';
import useRouter from 'hooks/useRouter';
import CompanyDetail from './components/CompanyDetail';
import CompanyWizard from './components/CompanyWizard';
import Alert from '@material-ui/lab/Alert';
import BankDeleteConfirm from './components/BankDeleteConfirm';

const useStyles = makeStyles({
  active: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '16px'
  }
});

const CompanyPage: FC = () => {
  const classes = useStyles();
  const { history } = useRouter();
  // eslint-disable-next-line

  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [company, setCompany] = useState<CompanyModel>(dummyCompany);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [isEmpty, setEmpty] = useState<boolean>(false);
  const [bank, setBank] = useState<BankModel>(dummyBank);
  const [banks, setBanks] = useState<BankModel[]>([dummyBank]);
  const [bankErrors, setBankErrors] = useState<Record<string, boolean>>({});
  const [loadingBank, setLoadingBank] = useState<boolean>(false);
  const [bankProgress, setBankProgress] = useState<boolean>(false);
  const [openBankDeleteConfirm, setOpenBankDeleteConfirm] = useState<boolean>(false);
  const [bankIdDeleteConfirm, setBankIdDeleteConfirm] = useState<number>(0);
  const [isBankCreate, setBankCreate] = useState<boolean>(false);
  const [isBankUpdate, setBankUpdate] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);

  const fetchData = async () => {
    setIsLoadingData(true);
    try {
      const { data } = await axios.get(COMPANY_BASE_URL);
      setCompany(data.data);
    } catch (error) {
      console.log('error:', error);
      setEmpty(true);
    } finally {
      setIsLoadingData(false);
    }
  };

  const fetchBank = async () => {
    setLoadingBank(true);
    try {
      const { data } = await axios.get(BANK_BASE_URL);
      setBanks(data.data);
    } catch (error) {
      console.log('error:', error);
    } finally {
      setLoadingBank(false);
    }
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string) => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSetUpdate = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setUpdate(true);
  };

  const onClickHandler = (path: string): React.MouseEventHandler => event => {
    event.preventDefault();
    history.push(path);
  };

  const handleBankUpdate = (id: number) => {
    setBank(banks.find(value => value.id === id)!);
    setCurrentIndex(banks.findIndex(value => value.id === id));
    setBankUpdate(true);
  };

  const handleCancel = () => {
    setBank(dummyBank);
    setCurrentIndex(-1);
    setBankUpdate(false);
    setBankCreate(false);
  };

  const handleCreateBank = () => {
    setBankCreate(true);
  };

  const handleOpenBankDeleteConfirm = (id: number) => {
    setOpenBankDeleteConfirm(true);
    setBankIdDeleteConfirm(id);
  };

  const handleCloseBankDeleteConfirm = () => setOpenBankDeleteConfirm(false);

  const handleDeleteBank = async () => {
    setBankProgress(true);
    try {
      await axios.delete(DELETE_BANK_BASE_URL(bankIdDeleteConfirm));
      setBanks(prevState => prevState.filter(value => value.id !== bankIdDeleteConfirm));
      setCurrentIndex(-1);
      handleCloseBankDeleteConfirm();
    } catch (error) {
      console.log('error:', error);
    } finally {
      setBankProgress(false);
      handleCloseBankDeleteConfirm();
    }
  };

  const handleValidation = ({ ...args }) => {
    for (const key in args) {
      setBankErrors(prev => ({ ...prev, [key]: args[key as keyof typeof args] === '' }));
    }
  };

  const handleSubmit = async () => {
    handleValidation(bank);
    if (Object.entries(bank).some(value => typeof value[1] === 'string' && value[1] == '')) return;
    try {
      const { data } = await axios.post(BANK_BASE_URL, bank);
      setBanks(
        bank.id > 0
          ? banks.map(value => {
              if (value.id === bank.id) {
                value = data.data;
              }
              return value;
            })
          : [data.data, ...banks]
      );
      setCurrentIndex(-1);
      setBank(dummyBank);
    } catch (error) {
      console.log('error:', error);
    } finally {
      setBankProgress(false);
      setBankUpdate(false);
      setBankCreate(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchBank();
  }, []);

  return (
    <Page title='Perusahaan'>
      <Container>
        <Grid container direction='row'>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <Typography variant='h1'> Perusahaan </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumbs key={1} aria-label='breadcrumb'>
              <Link key={99} color='inherit' href='/' onClick={onClickHandler('/')}>
                Beranda
              </Link>
              <Link color='inherit' href='/' onClick={onClickHandler('/pengaturan')}>
                Pengaturan
              </Link>
              <Typography color='inherit' className={classes.active}>
                Perusahaan
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Grid container direction='row' justify='space-between'>
          <Grid item lg={6} sm={12} md={6} xs={12}>
            {isEmpty && (
              <Alert variant='filled' severity='warning'>
                Data Perusahaan Belum Ditambahkan, Tambahkan Sekarang!
              </Alert>
            )}
          </Grid>
          <Grid container item lg={6} sm={12} md={6} xs={12} justify='flex-end' spacing={2}>
            <Grid item>
              {!isUpdate && (
                <Button onClick={handleSetUpdate}>
                  <Typography>{isEmpty ? 'Tambahkan Data' : 'Edit Data'}</Typography>
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>

        {isUpdate ? (
          <CompanyWizard company={company} setCompany={setCompany} setUpdate={setUpdate} setEmpty={setEmpty} handleSnackBar={handleSnackBar} />
        ) : (
          <CompanyDetail
            banks={banks}
            loadingBank={loadingBank}
            company={company}
            bankErrors={bankErrors}
            isLoading={isLoadingData}
            isCreate={isBankCreate}
            isUpdate={isBankUpdate}
            bank={bank}
            currentIndex={currentIndex}
            bankProgress={bankProgress}
            setBank={setBank}
            handleCreate={handleCreateBank}
            handleUpdate={handleBankUpdate}
            handleDelete={handleOpenBankDeleteConfirm}
            handleCancel={handleCancel}
            handleSave={handleSubmit}
          />
        )}

        <StandardConfirmationDialog
          variant={snackbarVariant}
          titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
          message={message}
          open={openSnackbar}
          handleClose={handleCloseSnackbar}
          onConfirm={handleCloseSnackbar}
          noCancelButton={true}
        />
        <BankDeleteConfirm
          open={openBankDeleteConfirm}
          isLoading={bankProgress}
          handleClose={handleCloseBankDeleteConfirm}
          handleSubmit={handleDeleteBank}
        />
      </Container>
    </Page>
  );
};

export default CompanyPage;
