import React, { FC } from 'react';
import { Container, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { BusinessOutlined, HomeWorkRounded, People } from '@material-ui/icons';
import useRouter from 'hooks/useRouter';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Page } from 'components';
import { Breadcrumb } from 'components';
import { PRIMARY_SECONDARY } from 'constants/colors';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: '20px',
    boxShadow: '0px 5px 24px rgba(2, 169, 234, 0.12)',
    borderRadius: '5px',
    cursor: 'pointer'
  },
  iconCard: {
    fontSize: 48,
    color: PRIMARY_SECONDARY
  },
  icon: {
    paddingTop: 5,
    marginTop: 5,
    fontSize: 48,
    color: PRIMARY_SECONDARY
  },
  gridPaper: {
    paddingTop: '24px'
  }
}));

const SettingsPage: FC = () => {
  const classes = useStyles();
  const { history } = useRouter();

  const onClickHandler = (path: string): React.MouseEventHandler => event => {
    event.preventDefault();
    history.push(path);
  };
  return (
    <Page title='Pengaturan'>
      <Container>
        <Grid container direction='row'>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'> Pengaturan </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>

        <Grid container spacing={2} direction='row' className={classes.gridPaper}>
          <Grid container item lg={3} md={3} sm={12} xs={12}>
            <Paper onClick={onClickHandler('/users')} className={classes.paper}>
              <Grid direction='row' item container lg={12} md={12} sm={12} xs={12} spacing={1} justify='space-between'>
                <Grid item lg={9} md={9} sm={9} xs={9}>
                  <Typography variant='h5'>Pengguna</Typography>
                  <Typography variant='subtitle2'>Pengaturan Pengguna dan Hak Akses Pengguna</Typography>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                  <Typography align='center'>
                    <People className={classes.iconCard} />
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid container item lg={3} md={3} sm={12} xs={12}>
            <Paper onClick={onClickHandler('/perusahaan')} className={classes.paper}>
              <Grid direction='row' container justify='space-between' item lg={12} md={12} sm={12} xs={12}>
                <Grid item lg={9} md={9} sm={9} xs={9}>
                  <Typography variant='h5'>Perusahaan</Typography>
                  <Typography variant='subtitle2'> Informasi Perusahaan terkait nama, logo dan alamat</Typography>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                  <Typography align='center'>
                    <HomeWorkRounded className={classes.iconCard} />
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid container item lg={3} md={3} sm={12} xs={12}>
            <Paper onClick={onClickHandler('/gudang')} className={classes.paper}>
              <Grid direction='row' container justify='space-between' item lg={12} md={12} sm={12} xs={12}>
                <Grid item lg={9} md={9} sm={9} xs={9}>
                  <Typography variant='h5'>Gudang</Typography>
                  <Typography variant='subtitle2'>Pengaturan Stok Pergudangan</Typography>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                  <Typography align='center'>
                    <BusinessOutlined className={classes.iconCard} />
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default SettingsPage;
