import React, { FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { TableCellMiddle, TableCellEnd, TableCellStart, TableRowCustom, TableRowCollapse } from 'components/Table';
import { IconButton, Menu, MenuItem, makeStyles, Theme, TableHead, TableBody, TableRow, TableCell, Table, Grid, Typography } from '@material-ui/core';
import { Create, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { format } from 'date-fns';
interface Props {
  allStock: StockAllItemModel;
  index: number;
  indexCollapse: number;
  stockId: number;
  isLoading: boolean;
  anchorEl: null | HTMLElement;
  openOptions: boolean;
  openCollapse: boolean;
  loadingItem: boolean;
  handleStockSales: React.MouseEventHandler;
  handleOpenCollapse: React.MouseEventHandler;
  handleStockDamaged: React.MouseEventHandler;
  handleCloseOption: React.MouseEventHandler;
  handleOpenOption: React.MouseEventHandler;
  setStockId: React.Dispatch<React.SetStateAction<number>>;
}
const useStyles = makeStyles((theme: Theme) => ({
  tableCollapse: {
    width: '100%'
  },
  cellCollapse: {
    border: 'none'
  }
}));

const BodyRow: FC<Props> = props => {
  const {
    index,
    allStock,
    isLoading,
    anchorEl,
    openOptions,
    handleCloseOption,
    openCollapse,
    indexCollapse,
    handleOpenCollapse,
    loadingItem,
    handleOpenOption,
    handleStockSales,
    handleStockDamaged,
    setStockId,
    stockId
  } = props;
  const classes = useStyles();

  return (
    <>
      <TableRowCustom>
        <TableCellStart>
          {isLoading ? (
            <Skeleton variant='text' width={60} height={25} />
          ) : (
            <IconButton aria-label='expand row' size='small' onClick={handleOpenCollapse}>
              {openCollapse && index === indexCollapse ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          )}
        </TableCellStart>
        <TableCellMiddle style={{ fontWeight: 500 }}>
          {isLoading ? (
            <Skeleton variant='text' width={'100%'} height={25} />
          ) : (
            <>
              {allStock.productName}
              <br />
              {allStock.productCode}
            </>
          )}
        </TableCellMiddle>

        <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : allStock.categoryName}</TableCellMiddle>

        <TableCellMiddle align='center'>
          {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : allStock.salesStock + ' ' + allStock.typeUnit}
        </TableCellMiddle>

        <TableCellMiddle align='center'>
          {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : allStock.holdItem + ' ' + allStock.typeUnit}
        </TableCellMiddle>
        <TableCellMiddle align='center'>
          {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : allStock.totalDamageEdit + ' ' + allStock.typeUnit}
        </TableCellMiddle>
        <TableCellMiddle align='center'>
          {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : allStock.netStock + ' ' + allStock.typeUnit}
        </TableCellMiddle>
        <TableCellMiddle style={{ fontWeight: 500 }}>
          {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : allStock.warehouseName}
        </TableCellMiddle>

        <TableCellEnd align='center' onClick={() => setStockId(allStock.id)}>
          {isLoading ? (
            <Skeleton variant='text' width={'100%'} height={25} />
          ) : (
            <Create color='primary' style={{ cursor: 'pointer' }} fontSize='small' onClick={handleOpenOption} />
          )}
          {stockId === allStock.id && (
            <Menu anchorEl={anchorEl} keepMounted open={openOptions} onClose={handleCloseOption} variant='selectedMenu'>
              <MenuItem key={0} onClick={handleStockSales}>
                Edit Stok Jual
              </MenuItem>
              <MenuItem key={1} onClick={handleStockDamaged}>
                Edit Stok Rusak
              </MenuItem>
            </Menu>
          )}
        </TableCellEnd>
      </TableRowCustom>
      <TableRowCollapse open={openCollapse && index === indexCollapse && !isLoading} colSpan={9}>
        <Table className={classes.tableCollapse} size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Tanggal</TableCell>
              <TableCell>Jam</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Alasan</TableCell>
              <TableCell>Satuan</TableCell>
              <TableCell>Stok Jual</TableCell>
              <TableCell>Stok Rusak Gudang</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {loadingItem ? (
              <TableRow>
                <TableCell colSpan={7}>
                  <Skeleton variant='text' width='100%' height={25} />
                </TableCell>
              </TableRow>
            ) : allStock.stockEdit && allStock.stockEdit.length > 0 ? (
              allStock.stockEdit.map((value, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.cellCollapse}>{format(new Date(value.createdAt), 'dd MMM yyyy')}</TableCell>
                  <TableCell className={classes.cellCollapse}>{format(new Date(value.createdAt), 'hh:mm:ss')}</TableCell>
                  <TableCell className={classes.cellCollapse}>{value.createdBy || '-'}</TableCell>
                  <TableCell className={classes.cellCollapse}>{value.remark || '-'}</TableCell>
                  <TableCell className={classes.cellCollapse}>{allStock.typeUnit || '-'}</TableCell>
                  <TableCell align='center' className={classes.cellCollapse}>
                    {value.salesStockInput !== null ? (
                      <>
                        {value.salesStock} &#8594; {value.salesStockInput}
                      </>
                    ) : (
                      '-'
                    )}
                  </TableCell>

                  <TableCell align='center' className={classes.cellCollapse}>
                    {value.damageStock !== null ? (
                      <>
                        {value.damageStockBefore} &#8594; {value.damageStock}{' '}
                      </>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7}>
                  <Grid xs={12} container direction='column' alignItems='center' justify='center'>
                    <Typography variant='h6'>Data Tidak Riwayat</Typography>
                  </Grid>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableRowCollapse>
    </>
  );
};

export default BodyRow;
