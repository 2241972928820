import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { BLUE } from 'constants/colors';
import { format } from 'date-fns';
import React from 'react';
import NumberFormat from 'react-number-format';
interface Props {
  courierSalary: CourierSalary;
  handleOpenDelivery: (id: number) => void;
}
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    border: '1px solid #E0E0E0',
    borderRadius: '5px',
    padding: '1em',
    cursor: 'pointer',
    transition: 'all 1s ease',
    '&:hover': {
      boxShadow: '0 2px 5px rgb(0 0 0 / 14%)'
    }
  },
  link: {
    fontWeight: 500,
    cursor: 'pointer',
    '&:hover': {
      color: BLUE
    }
  }
}));
const ContentData = ({ courierSalary, handleOpenDelivery }: Props) => {
  const classes = useStyles();
  const date = courierSalary.startDate && courierSalary.endDate;

  return (
    <Grid xs={12} container className={classes.container} key={courierSalary.id}>
      <Grid xs={4}>
        <Typography variant='h6' onClick={() => handleOpenDelivery(courierSalary.id)} className={classes.link}>
          {date && `${format(new Date(courierSalary.startDate), 'dd-MM-yyyy')} s/d ${format(new Date(courierSalary.endDate), 'dd-MM-yyyy')}`}
        </Typography>
      </Grid>
      <Grid xs={4}>
        <Typography>{courierSalary.totalDelivery}</Typography>
      </Grid>
      <Grid xs={4}>
        <Typography>
          <NumberFormat value={courierSalary.totalNominal} prefix={'Rp'} thousandSeparator={true} displayType='text' />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ContentData;
