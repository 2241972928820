import React, { FC } from 'react';
import { TableBody, TableHead, TableRow, TableCell, TableContainer } from '@material-ui/core';
import BodyRow from './components/BodyRow';
import { TableCustom } from 'components/Table';

interface Props {
  isLoadingData: boolean;
  salesOrderItem: SalesOrderItemModel[];
  handleOpenImage: (imagePath: string) => void;
}

const ListProduct: FC<Props> = props => {
  const { isLoadingData, salesOrderItem, handleOpenImage } = props;

  return (
    <TableContainer>
      <TableCustom size='small'>
        <TableHead>
          <TableRow>
            <TableCell>Nama Produk</TableCell>
            <TableCell>Satuan Harga</TableCell>
            <TableCell>Harga Jual</TableCell>
            <TableCell>Kuantitas</TableCell>
            <TableCell align='right'>Total Harga</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {salesOrderItem.length > 0 &&
            salesOrderItem.map((value, index) => <BodyRow isLoading={isLoadingData} salesOrderItem={value} handleOpenImage={handleOpenImage} />)}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default ListProduct;
