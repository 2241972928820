import React, { FC } from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import Skeleton from '@material-ui/lab/Skeleton';

interface Props {
  item: PurchaseInvoiceItemModel;
  isLoading: boolean;
}

const BodyRow: FC<Props> = props => {
  const { item, isLoading } = props;

  return (
    <TableRow>
      <TableCell>{isLoading ? <Skeleton variant='text' width='100%' /> : item.Product ? item.Product.productName : '-'}</TableCell>
      <TableCell align='right'>
        {isLoading ? (
          <Skeleton variant='text' width='100%' />
        ) : (
          <NumberFormat value={item.price} prefix={'Rp'} thousandSeparator={true} displayType='text' />
        )}
      </TableCell>
      <TableCell align='center'>{isLoading ? <Skeleton variant='text' width='100%' /> : item.totalItem + ' ' + item.typeUnit}</TableCell>
      <TableCell align='right'>
        {isLoading ? (
          <Skeleton variant='text' width='100%' />
        ) : (
          <NumberFormat value={item.discount} prefix={'Rp'} thousandSeparator={true} displayType='text' />
        )}
      </TableCell>
      <TableCell align='right'>
        {isLoading ? (
          <Skeleton variant='text' width='100%' />
        ) : (
          <>
            <NumberFormat value={parseFloat(item.totalPrice.toFixed())} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          </>
        )}
      </TableCell>

      <TableCell align='right'>
        {isLoading ? (
          <Skeleton variant='text' width='100%' />
        ) : (
          <NumberFormat value={item.unitNetPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
        )}
      </TableCell>
    </TableRow>
  );
};

export default BodyRow;
