import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, makeStyles, Slide, Theme, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import ContentData from './ContentData';
import Empty from 'images/icon/EmptyIcon.svg';
import clsx from 'clsx';
import { format } from 'date-fns';
import { GREY } from 'constants/colors';
import { TransitionProps } from '@material-ui/core/transitions';
import useRouter from 'hooks/useRouter';
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    rowGap: '1em'
  },
  containerEmpty: {
    minHeight: '15em',
    borderTop: `1px solid ${GREY}`
  },
  titleContainer: {
    padding: '0 1em'
  },
  deliveryContainer: {
    border: '1px solid #E0E0E0',
    borderRadius: '5px',
    padding: '1em',
    cursor: 'pointer',
    transition: 'all 1s ease',
    '&:hover': {
      boxShadow: '0 2px 5px rgb(0 0 0 / 14%)'
    }
  }
}));
interface Props {
  isAction: { isOpen: boolean; id: number };
  deliveryResource: DeliveryOrders[];
  handleClose: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const ContentDelivery = ({ deliveryResource, isAction, handleClose }: Props) => {
  const classes = useStyles();
  const { history } = useRouter();
  const handleLink = (id: number) => history.push(`/surat-jalan/${id}`);
  return (
    <Dialog open={isAction.isOpen} maxWidth='md' TransitionComponent={Transition} keepMounted onClose={handleClose} fullWidth>
      <DialogTitle>
        <Typography variant='h5'>Detail Komisi</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid xs={12} container className={classes.container}>
          <Grid xs={12} container className={classes.titleContainer}>
            <Grid xs={4}>
              <Typography color='textSecondary'>NO SURAT JALAN</Typography>
            </Grid>
            <Grid xs={3}>
              <Typography color='textSecondary'>NAMA MITRA</Typography>
            </Grid>
            <Grid xs={3}>
              <Typography color='textSecondary'>TANGGAL</Typography>
            </Grid>
            <Grid xs={2}>
              <Typography color='textSecondary' align='center'>
                TOTAL KOLI
              </Typography>
            </Grid>
          </Grid>
          {deliveryResource.length > 0 ? (
            deliveryResource.map(delivery => (
              <Grid xs={12} container className={classes.deliveryContainer} key={delivery.id}>
                <Grid xs={4}>
                  <Typography display='inline' variant='h6' color='primary' onClick={() => handleLink(delivery.id)}>
                    {delivery.deliveryNumber}
                  </Typography>
                </Grid>
                <Grid xs={3}>
                  <Typography>{delivery.partnerName}</Typography>
                </Grid>
                <Grid xs={3}>
                  <Typography>{delivery.createdAt}</Typography>
                </Grid>
                <Grid xs={2}>
                  <Typography align='center'>{delivery.totalKoli}</Typography>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid
              xs={12}
              container
              direction='column'
              justify='center'
              alignItems='center'
              className={clsx(classes.container, classes.containerEmpty)}
            >
              <img src={Empty} alt='Empty Logo' />
              <Typography variant='h6' color='primary'>
                Data tidak tersedia
              </Typography>
            </Grid>
          )}
          <Grid container justify='flex-end' onClick={handleClose}>
            <Button color='primary'>Tutup</Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ContentDelivery;
