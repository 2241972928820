import React, { FC, useState, useEffect, useContext } from 'react';
import {
  Button,
  Container,
  Grid,
  makeStyles,
  ButtonGroup,
  Typography,
  Divider,
  Hidden,
  Tooltip,
  CircularProgress,
  TextField,
  Badge
} from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';
import useRouter from 'hooks/useRouter';
import ContentInvoice from './components/ContentInvoice';
import { Page, Breadcrumb, StandardConfirmationDialog, PaperCustom, FormPinDialog } from 'components';
import useConfirmationPin from 'hooks/useConfirmationPin';
import { PRIMARY, WHITE, RED, YELLOW } from 'constants/colors';
import {
  GET_INVOICE_DETAIL_BASE_URL,
  INVOICE_ITEM_UPDATE_BASE_URL,
  COMPANY_BASE_URL,
  INVOICE_BASE_URL,
  INVOICE_PAYMENT_BASE_URL,
  INVOICE_ROUNDING_BASE_URL,
  INVOICE_REQUEST_EDIT_BASE_URL,
  INVOICE_REQUEST_CANCEL_BASE_URL,
  INVOICE_ITEM_DELETE_URL,
  INVOICE_CANCEL_BASE_URL,
  INVOICE_NEW_EDIT_BASE_URL,
  GET_INVOICE_PAYMENT_LIST_BASE_URL,
  PARTNER_DETAIL_BASE_URL,
  PARTNER_BASE_URL,
  BANK_BASE_URL
} from 'constants/url';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import { dummyInvoice, dummyCompany, dummyPartner, dummyInvoicePayment, dummyBank } from 'utils/dummy';
import { format } from 'date-fns';
import Alert from '@material-ui/lab/Alert';
import ErrorIcon from '@material-ui/icons/Error';
import NumberFormat from 'react-number-format';
import useRole from 'hooks/useRole';
import PaymentStatus from 'typings/enum/PaymentStatus';
import PartnerModal from './components/PartnerModal';
import PrintConfirm from './components/PrintConfirm';
import TypeUser from 'typings/enum/TypeUser';

import useToggle from 'hooks/useToggle';
import PrintIcon from '@material-ui/icons/Print';
import { Payment } from '@material-ui/icons';
import InvoicePaymentCreate from './components/InvoicePayment/InvoicePaymentCreate';
import SuccessModal from './components/InvoicePayment/SuccessModal';
import clsx from 'clsx';
import InvoicePayment from './components/InvoicePayment/InvoicePayment';

const useStyles = makeStyles({
  box: {
    backgroundColor: WHITE,
    borderRadius: '5px',
    padding: 24,
    border: '1px solid #F2F2F2',
    boxShadow: '0px 5px 12px rgba(0, 0, 0, 0.08)',
    marginTop: 2,
    marginBottom: 10,
    overflowX: 'auto'
  },
  boxTitle: {
    marginBottom: 8
  },
  panel: {
    width: '100%'
  },
  cancelConfirmButton: {
    backgroundColor: RED
  },
  invoice: {
    padding: '.1em 1em',
    color: WHITE,
    marginBottom: '1em',
    borderRadius: '1em'
  },
  invoiceUnpaid: {
    background: YELLOW
  },
  invoicePaid: {
    background: PRIMARY
  }
});

const InvoiceDetailPage = () => {
  const { match } = useRouter();
  // eslint-disable-next-line

  const { history } = useRouter();
  const params = match.params.id;
  const classes = useStyles();
  const { currentUser } = useContext(CurrentUserContext);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [partner, setPartner] = useState<PartnerModel>(dummyPartner);
  const [invoice, setInvoice] = useState<InvoiceModel>(dummyInvoice);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [invoiceItem, setInvoiceItem] = useState<InvoiceItemModel[]>([]);
  const [invoicePayment, setInvoicePayment] = useState<InvoicePaymentModel[]>([]);
  const [invoicePaymentConfirm, setInvoicePaymentConfirm] = useState<boolean>(false);
  const [invoicePiece, setInvoicePiece] = useState<InvoicePieceModel[]>([]);
  const [company, setCompany] = useState<CompanyModel>(dummyCompany);
  const [id, setId] = useState<number>(0);
  const [pin, setPin] = useState<number | null>(null);
  const [isComplete, setComplete] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [loadingConfirmPrint, setLoadingConfirmPrint] = useState<boolean>(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [invoicePaymentData, setInvoicePaymentData] = useState<InvoicePaymentModel>(dummyInvoicePayment);
  const [invoicePaymentDataLoading, setInvoicePaymentDataLoading] = useState<boolean>(false);
  const [hasSuccess, setHasSuccess] = useState<boolean>(false);
  const [loadingTotalPrice, setLoadingTotalPrice] = useState<boolean>(false);
  const [loadingEdit, setLoadingEdit] = useState<boolean>(false);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
  const [indexCollapse, setIndexCollapse] = useState<number>(-1);
  const [indexEdit, setIndexEdit] = useState<number>(-1);
  const [isRequestEdit, setRequestEdit] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openTotalPrice, setOpenTotalPrice] = useState<boolean>(false);
  const [itemId, setItemId] = useState<{ id: number; notes: string; isRequestEdit: boolean }[]>([]);
  const [typeEdit, setTypeEdit] = useState<string>('');
  const [isCancel, setCancel] = useState<boolean>(false);
  const [isPartner, setIsPartner] = useState<boolean>(false);
  const [isRequestCancel, setRequestCancel] = useState<boolean>(false);
  const [cancelNote, setCancelNote] = useState<string>('');
  const [cancelLoading, setCancelLoading] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<InvoiceItemModel>();
  const [openPartner, setOpenPartner] = useState<boolean>(false);
  const [openPrintConfirm, setOpenPrintConfirm] = useState<boolean>(false);
  const [loadingPrint, setLoadingPrint] = useState<boolean>(false);
  const [messagePrintDate, setMessagePrintDate] = useState<string>('');
  const [customerReseller, setCustomerReseller] = useState<string>('');
  const [messagePrintUser, setMessagePrintUser] = useState<string>('');
  const [remark, setRemark] = useState<string>('');
  const [openRemark, setOpenRemark] = useToggle();
  const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
  const [invoicePaymentClicked, setInvoicePaymentClicked] = useState<{ id: number; isOpen: boolean }>({
    id: 0,
    isOpen: false
  });
  const [loadingBank, setLoadingBank] = useState<boolean>(false);
  const [banks, setBanks] = useState<BankModel>(dummyBank);
  const isSuperAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERADMIN]
  });

  const isAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ADMIN]
  });

  const printPdf = async () => {
    setLoadingPrint(true);

    try {
      const { data } = await axios.get(`${INVOICE_BASE_URL}/pdf/${id}`, { responseType: 'blob' });

      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

      setLoadingPrint(false);
      setMessagePrintDate(format(new Date(), 'dd/MM/yyyy HH:mm:ss'));
      setMessagePrintUser(currentUser ? currentUser.firstName : '');
      handleClosePrintConfirm();
      setInvoice({ ...invoice, printDate: new Date(), canPrint: false, requestPrint: false });

      const newwindow = window.open(fileURL, 'name', 'height=700,width=750');
      if (newwindow) {
        newwindow.focus();
      }
    } catch (error) {
      console.log('error :', error);
      setLoadingPrint(false);
    }
  };

  const pinMessage = useConfirmationPin({
    pin,
    isCompletePin: isComplete,
    setOpenConfirmation: setOpenConfirmation,
    confirmationPlafon: true,
    handlePlafon: printPdf,
    setLoading: setLoadingConfirmPrint,
    setCompletePin: setComplete,
    setPin: setPin
  });

  const handleOpenConfirmation = () => {
    setOpenConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  const handleOpenUpdateTotalPrice = () => {
    setOpenTotalPrice(true);
  };

  const updateTotalPrice = async () => {
    try {
      setLoadingTotalPrice(true);

      const { data } = await axios.post(INVOICE_ROUNDING_BASE_URL, {
        id: invoice.id,
        totalPrice
      });

      setInvoice(data.data);
      setTotalPrice(data.data.totalPrice);
      handleOpenSnackBar('success', 'Total biaya telah berhasil diperbaharui.');
    } catch (error) {
      console.log('error', error);
      handleOpenSnackBar('error', 'Total biaya telah gagal diperbaharui.');
    } finally {
      setLoadingTotalPrice(false);
      setOpenTotalPrice(false);
    }
  };

  const handleCancelUpdateTotalPrice = () => {
    setOpenTotalPrice(false);
    setTotalPrice(invoice.totalPrice);
  };

  const fetchData = async () => {
    setIsLoadingData(true);

    try {
      const { data } = await axios.get(GET_INVOICE_DETAIL_BASE_URL(+params));
      const invoice = { ...data.data, totalPrice: data.data.totalPriceReal };
      if (data.data.Customer_reseller) {
        setCustomerReseller(data.data.Customer_reseller.name);
      }

      setInvoice(invoice);
      setTotalPrice(invoice.totalPrice);
      setInvoiceItem(data.data.InvoiceItem);
      setInvoicePiece(data.data.InvoicePiece);
      setId(data.data.id);
      handleChangeInvoicePayment<number>('InvoiceId', data.data.id);
      handleChangeInvoicePayment<number>('totalPay', data.data.totalPrice - data.data.totalPay);
    } catch (error) {
      console.log('error:', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const fetchCompany = async () => {
    try {
      const { data } = await axios.get(COMPANY_BASE_URL);
      setCompany(data.data);
    } catch (error) {
      console.log('error:', error);
    }
  };
  const fetchBank = async () => {
    setLoadingBank(true);
    try {
      const { data } = await axios.get(BANK_BASE_URL);
      if (data.data.length > 0) {
        setBanks(data.data[0]);
      }
    } catch (error) {
      console.log('error:', error);
    } finally {
      setLoadingBank(false);
    }
  };

  const fetchPayment = async () => {
    try {
      const { data } = await axios.get(GET_INVOICE_PAYMENT_LIST_BASE_URL(id));
      setInvoicePayment(data.data);
    } catch (error) {
      console.log('error:', error);
    }
  };

  const handleClickInvoicePayment = (id: number) => setInvoicePaymentClicked(prev => ({ ...prev, id, isOpen: true }));

  const getPartner = async () => {
    setIsPartner(true);
    try {
      const { data } = await axios.get(PARTNER_DETAIL_BASE_URL(invoice.PartnerId), { cancelToken: cancelTokenSource.token });
      setPartner(data.data);
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsPartner(false);
    }
  };

  const onCancel = async () => {
    setCancelLoading(true);
    try {
      if (isAdmin) {
        const { data } = await axios.post(INVOICE_REQUEST_CANCEL_BASE_URL, {
          id: invoice.id,
          cancelNote
        });

        setInvoice(prevState => {
          prevState.cancelNote = cancelNote;
          prevState.isRequestEdit = true;
          return prevState;
        });

        handleOpenSnackBar('success', 'Invoice berhasil request batal');
      } else {
        const { data } = await axios.post(INVOICE_CANCEL_BASE_URL, {
          id: invoice.id,
          cancelNote
        });

        setInvoice(prevState => {
          prevState.cancelAt = data.data.cancelAt;
          prevState.statusPayment = data.data.statusPayment;
          prevState.cancelNote = data.data.cancelNote;
          prevState.statusPayment = 'CANCEL';
          return prevState;
        });

        handleOpenSnackBar('success', 'Invoice berhasil dibatalkan');
      }
    } catch (error) {
      console.log('error:', error);
      handleOpenSnackBar('error', 'Invoice gagal dibatalkan');
    } finally {
      setRequestCancel(true);
      setCancelLoading(false);
      setCancel(false);
    }
  };

  const onRefresh = () => {
    fetchData();
    setItemId([]);
    setIndexCollapse(-1);
    setRequestEdit(false);
  };

  const onSubmit = async () => {
    setLoadingEdit(true);
    try {
      await axios.post(INVOICE_ITEM_UPDATE_BASE_URL, currentItem);
      const { data } = await axios.get(GET_INVOICE_DETAIL_BASE_URL(parseInt(params)));
      const invoice = { ...data.data, totalPrice: data.data.totalPriceReal };

      setInvoice(invoice);
      setTotalPrice(invoice.totalPrice);
      setInvoiceItem(data.data.InvoiceItem);
      setInvoicePiece(data.data.InvoicePiece);
      setId(data.data.id);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoadingEdit(false);
      setCurrentItem(undefined);
      setIndexEdit(-1);
      setTypeEdit('');
    }
  };

  const onRequest = async () => {
    setLoadingRequest(true);
    try {
      await axios.post(INVOICE_REQUEST_EDIT_BASE_URL, {
        id,
        items: itemId
      });
      handleOpenSnackBar('success', 'Permintaan telah dikirim.');
    } catch (error) {
      console.log('error', error);
      handleOpenSnackBar('error', 'Permintaan gagal dikirim.');
    } finally {
      setLoadingRequest(false);
      setItemId([]);
      setRequestEdit(false);
      setIndexCollapse(-1);
    }
  };

  const onDeleteItem = async (id: number) => {
    setTypeEdit('DELETE');
    setLoadingEdit(true);
    try {
      await axios.delete(INVOICE_ITEM_DELETE_URL(id));
      setInvoiceItem(prevState => prevState.filter(value => value.id !== id));
      const { data } = await axios.get(GET_INVOICE_DETAIL_BASE_URL(parseInt(params)));
      const invoice = { ...data.data, totalPrice: data.data.totalPriceReal };
      setInvoice(invoice);
      setTotalPrice(invoice.totalPrice);
      setInvoiceItem(data.data.InvoiceItem);
      setInvoicePiece(data.data.InvoicePiece);
      handleOpenSnackBar('success', 'Item berhasil dihapus.');
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoadingEdit(false);
      setCurrentItem(undefined);
      setIndexEdit(-1);
      setTypeEdit('');
    }
  };

  const updateStatusNewEdit = async () => {
    try {
      await axios.get(INVOICE_NEW_EDIT_BASE_URL(id));
    } catch (error) {
      console.log('error', error);
    } finally {
    }
  };

  const handleOpenPrintConfirm = () => {
    if (invoice.printDate && invoice.userPrint) {
      setMessagePrintDate(format(new Date(invoice.printDate), 'dd/MM/yyyy HH:mm:ss'));
      setMessagePrintUser(invoice.userPrint);
    }

    setOpenPrintConfirm(true);
  };

  const handleClosePrintConfirm = () => {
    setOpenPrintConfirm(false);
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string): void => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handlePayment = async () => {
    setInvoicePaymentDataLoading(true);
    try {
      const { data } = await axios.post(`${INVOICE_PAYMENT_BASE_URL}`, invoicePaymentData);

      setInvoicePayment(prev => [...prev, data.data]);
      setHasSuccess(true);
      setInvoicePaymentDataLoading(false);
      setInvoicePaymentConfirm(false);
    } catch (error) {
      const err = error as { data: any };
      handleSnackBar(true, 'error', `${err.data && err.data.message}`);
      setInvoicePaymentConfirm(false);
      console.log('error :', error);
    }
  };

  const handlePaymentStatus = (type: 'PAID' | 'UNPAID') => {
    if (type === PaymentStatus.UNPAID) {
      handleChangeInvoicePayment<number>('totalPay', 0);
    } else {
      handleChangeInvoicePayment<number>('totalPay', invoice.totalPrice - invoice.totalPay);
    }
  };
  const handleChangeInvoicePayment = <T,>(key: string, value: T) =>
    setInvoicePaymentData(prev => ({
      ...prev,
      [key]: value
    }));
  const handleOpenInvoicePayment = () => setInvoicePaymentConfirm(true);

  const handleOpenSnackBar = (variant: 'success' | 'error', message: string): void => {
    setSnackbarVariant(variant);
    setMessage(message);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (): void => {
    setOpenSnackbar(false);
  };

  const handleConfirmSnackbar = (): void => {
    setOpenSnackbar(false);
  };

  const handleEdit = (id: number, type: string) => {
    setIndexEdit(prevState => (prevState === id ? -1 : id));
    setTypeEdit(prevState => (prevState === type ? '' : type));
  };

  const handleRequestEdit = () => {
    setRequestEdit(prevState => !prevState);

    setItemId(prevState => {
      if (invoice.isRequestEdit) {
        return invoiceItem
          .filter(value => value.isRequestEdit)
          .map(value => {
            return { id: value.id, notes: value.notes || '', isRequestEdit: value.isRequestEdit || false };
          });
      } else {
        return [];
      }
    });
  };

  const handleOpenCollapse = (index: number): React.MouseEventHandler => () => {
    setIndexCollapse(indexCollapse === index ? -1 : index);
  };

  const handleRequestCheck = (item: { id: number; notes: string; isRequestEdit: boolean }) => {
    setItemId(prevState => {
      if (prevState.some(value => value.id === item.id)) {
        setIndexCollapse(-1);
        const _item = invoiceItem.find(_value => _value.id === item.id);

        if (_item && _item.isRequestEdit) {
          return prevState.map(value => {
            if (value.id === item.id) {
              value.isRequestEdit = !value.isRequestEdit;
              value.notes = '';
            }
            return value;
          });
        } else {
          return prevState.filter(value => value.id !== item.id);
        }
      } else {
        setIndexCollapse(item.id);
        return [...prevState, item];
      }
    });
  };

  const individualEdit = (item: InvoiceItemModel) => {
    let currItem = item;

    currItem.totalPrice = currItem.price * currItem.totalItem - ((currItem.discountUnit && currItem.discountUnit) || 0) * currItem.totalItem;
    currItem.subTotalPrice = currItem.price * currItem.totalItem;

    setInvoiceItem(prevState =>
      prevState.map(value => {
        if (value.id === currItem.id) {
          value = currItem;
        }

        return value;
      })
    );

    setCurrentItem(currItem);
  };

  const handleRequestNote = (item: { id: number; notes: string }) => {
    const index = itemId.findIndex(value => value.id === item.id);
    if (index >= 0) {
      itemId[index].notes = item.notes;
      setItemId([...itemId]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [params]);

  useEffect(() => {
    if (id === 0) return;
    fetchPayment();
  }, [id]);

  useEffect(() => {
    fetchCompany();
    fetchBank();
  }, []);

  useEffect(() => {
    if (invoice.id === 0) return;

    if (invoice.isRequestEdit && invoice.cancelNote) {
      setRequestCancel(true);
      setCancelNote(invoice.cancelNote);
    }

    if (!invoice.isNewEdited) return;

    updateStatusNewEdit();
  }, [invoice, id]);

  useEffect(() => {
    if (!openPartner && !openRemark) {
      return;
    }

    getPartner();
  }, [openPartner, openRemark]);

  const hasPrint = !!invoice.printDate;
  const hasRemarkAddress = invoice.Partner && invoice.Partner.remarkAddress && invoice.Partner.remarkAddress !== '' ? true : false;
  const canPrint = hasPrint ? (invoice.canPrint ? hasRemarkAddress : false) : hasRemarkAddress;

  return (
    <Page title='Invoice Penjualan'>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'> Penjualan </Typography>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12} xl={6}>
            <Breadcrumb />
          </Grid>

          <Grid container item lg={6} sm={12} md={6} xs={12} xl={6} justify='flex-end' alignItems='center' style={{ gap: '1rem' }}>
            {!(invoice.statusPayment === 'CANCEL') && (
              <Badge
                color='error'
                variant='dot'
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                invisible={!invoice.requestPrint}
              >
                <ButtonGroup size='small'>
                  <Button
                    disabled={isLoadingData}
                    style={{ backgroundColor: WHITE, color: PRIMARY, cursor: 'pointer' }}
                    onClick={handleOpenPrintConfirm}
                  >
                    {isLoadingData ? (
                      <CircularProgress size={20} color='inherit' />
                    ) : (
                      <>
                        <PrintIcon fontSize='small' style={{ verticalAlign: 'middle' }} />
                        Cetak
                      </>
                    )}
                  </Button>
                </ButtonGroup>
              </Badge>
            )}

            <Button
              startIcon={<Payment />}
              color='primary'
              variant='contained'
              size='small'
              disabled={invoice.statusPayment === PaymentStatus.PAID}
              onClick={handleOpenInvoicePayment}
            >
              Pembayaran
            </Button>
          </Grid>

          {(isCancel || isRequestCancel) && !(invoice.statusPayment === 'CANCEL') && (
            <PaperCustom>
              <Grid item lg={12} md={12} sm={12} xs={12} xl={6}>
                <code>{isAdmin ? 'Dengan ini saya sadar ingin mengajukan pembatalan invoice ini' : 'Catatan Pembatalan'}</code>
              </Grid>

              <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                <TextField
                  fullWidth
                  label='Catatan Pembatalan'
                  value={cancelNote}
                  onChange={e => setCancelNote(e.target.value)}
                  multiline
                  rows={3}
                  placeholder='Tulis Catatan Disini'
                  disabled={isRequestCancel}
                />
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12} xl={6}>
                <Button
                  disabled={cancelLoading || (isAdmin && isRequestCancel)}
                  className={isSuperAdmin ? classes.cancelConfirmButton : 'containedPrimary'}
                  onClick={onCancel}
                >
                  {cancelLoading ? <CircularProgress size={20} color='primary' /> : isAdmin ? 'Request Batal' : 'Konfirmasi Batalkan'}
                </Button>
              </Grid>
            </PaperCustom>
          )}

          {invoice.statusPayment === 'CANCEL' ? (
            <Grid item lg={12} md={12} sm={12} xs={12} xl={6}>
              <Alert variant='filled' severity='error'>
                Invoice Ini telah dibatalkan pada tanggal {invoice.cancelAt || ''}
                <br />
                Catatan Pembatalan:
                <br />
                {invoice.cancelNote || '-'}
              </Alert>
            </Grid>
          ) : invoice.isEdited ? (
            <Grid item lg={12} md={12} sm={12} xs={12} xl={6}>
              <Alert variant='filled' severity='warning'>
                Invoice Ini telah di perbaharui pada tanggal {invoice.updatedAt || ''}
              </Alert>
            </Grid>
          ) : (
            ''
          )}

          <Grid item justify='space-between' lg={12} xl={12} md={12} sm={12} xs={12} container spacing={1} className={classes.box}>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
              <Typography variant='h6' className={classes.boxTitle}>
                Nama Mitra &nbsp;
                {invoice.typeInvoice && invoice.typeInvoice === 'ALLOWED' && (
                  <span style={{ verticalAlign: 'middle' }}>
                    <Tooltip title='Invoice ini melalui izin plafon.'>
                      <ErrorIcon fontSize='small' color='action' />
                    </Tooltip>
                  </span>
                )}
              </Typography>
              <Typography variant='body2'> {invoice.Partner ? invoice.Partner.name : ''} </Typography>
            </Grid>
            <Hidden smUp smDown>
              <Divider orientation='vertical' />
            </Hidden>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
              <Typography variant='h6' className={classes.boxTitle}>
                Tanggal Terbit
              </Typography>
              <Typography variant='body2'> {invoice.createdAt ? format(new Date(invoice.createdAt), 'dd MMM yyyy') : ''} </Typography>
            </Grid>
            <Hidden smUp smDown>
              <Divider orientation='vertical' />
            </Hidden>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
              <Typography variant='h6' className={classes.boxTitle}>
                Jatuh Tempo
              </Typography>
              <Typography variant='body2'> {format(new Date(invoice.dueDate), 'dd MMM YYY')} </Typography>
            </Grid>
            <Hidden smUp smDown>
              <Divider orientation='vertical' />
            </Hidden>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
              <Typography variant='h6' align='right' className={classes.boxTitle}>
                Total Tagihan
              </Typography>
              <Typography align='right' variant='body2'>
                <NumberFormat value={totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
              </Typography>
            </Grid>
            <Hidden smUp smDown>
              <Divider orientation='vertical' />
            </Hidden>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
              <Typography variant='h6' align='right' className={classes.boxTitle}>
                Sisa Tagihan
              </Typography>
              <Typography align='right' variant='body2'>
                <NumberFormat value={invoice.totalPrice - invoice.totalPay} prefix={'Rp'} thousandSeparator={true} displayType='text' />
              </Typography>
            </Grid>
            <Grid xs={2} container direction='column' item>
              <Typography variant='h6' align='right' className={classes.boxTitle}>
                Status Invoice
              </Typography>
              {!isLoadingData && (
                <Grid container justify='flex-end'>
                  <Typography
                    variant='h6'
                    className={clsx(classes.invoice, invoice.statusPayment === PaymentStatus.PAID ? classes.invoicePaid : classes.invoiceUnpaid)}
                  >
                    {invoice.statusPayment === PaymentStatus.PAID ? 'Lunas' : 'Belum Lunas'}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} xl={12} md={12} sm={12} xs={12} container>
            <PaperCustom>
              <ContentInvoice
                isCancel={invoice.statusPayment === 'CANCEL'}
                onDeleteItem={onDeleteItem}
                onRefresh={onRefresh}
                isAdmin={isAdmin}
                isSuperAdmin={isSuperAdmin}
                loadingRequest={loadingRequest}
                isFinance={false}
                loadingEdit={loadingEdit}
                isInvoice={false}
                handleClickInvoicePayment={handleClickInvoicePayment}
                onUpdate={onSubmit}
                individualEdit={individualEdit}
                invoice={invoice}
                banks={banks}
                invoiceItem={invoiceItem}
                invoicePayment={invoicePayment}
                company={company}
                isLoadingData={isLoadingData}
                totalPrice={totalPrice}
                openTotalPrice={openTotalPrice}
                loadingTotalPrice={loadingTotalPrice}
                customerReseller={customerReseller}
                isRequestEdit={isRequestEdit}
                item={itemId}
                indexCollapse={indexCollapse}
                indexEdit={indexEdit}
                type={typeEdit}
                setOpenPartner={setOpenPartner}
                handleRequest={onRequest}
                handleEdit={handleEdit}
                handleRequestNote={handleRequestNote}
                handleRequestCheck={handleRequestCheck}
                handleRequestEdit={handleRequestEdit}
                handleOpenCollapse={handleOpenCollapse}
                setTotalPrice={setTotalPrice}
                handleOpenUpdateTotalPrice={handleOpenUpdateTotalPrice}
                handleCancelUpdateTotalPrice={handleCancelUpdateTotalPrice}
                updateTotalPrice={updateTotalPrice}
              />
            </PaperCustom>
          </Grid>
        </Grid>

        <StandardConfirmationDialog
          variant={snackbarVariant}
          titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
          message={message}
          open={openSnackbar}
          handleClose={snackbarVariant === 'success' ? handleConfirmSnackbar : handleCloseSnackbar}
          onConfirm={handleConfirmSnackbar}
          noCancelButton={true}
        />

        <PrintConfirm
          open={openPrintConfirm}
          isLoading={loadingPrint}
          messageDate={messagePrintDate}
          messageUser={messagePrintUser}
          handleClose={handleClosePrintConfirm}
          onConfirm={messagePrintDate && messagePrintUser ? handleOpenConfirmation : printPdf}
        />

        <PartnerModal partner={partner} open={openPartner} isLoadingData={isPartner} setOpen={setOpenPartner} />

        <InvoicePaymentCreate
          open={invoicePaymentConfirm}
          handleClose={() => setInvoicePaymentConfirm(false)}
          handleSubmit={handlePayment}
          handlePaymentStatus={handlePaymentStatus}
          handleChangeInvoicePayment={handleChangeInvoicePayment}
          invoicePayment={invoicePaymentData}
          isLoading={invoicePaymentDataLoading}
          totalPrice={invoice.totalPrice - invoice.totalPay}
        />
        <SuccessModal open={hasSuccess} handleClick={() => history.go(0)} />
        <InvoicePayment
          open={invoicePaymentClicked.isOpen}
          handleClick={() => setInvoicePaymentClicked(prev => ({ ...prev, isOpen: false }))}
          invoicePayment={invoicePayment.find(payment => payment.id === invoicePaymentClicked.id) || dummyInvoicePayment}
        />
        <FormPinDialog
          open={openConfirmation}
          pinMessage={pinMessage}
          isComplete={isComplete}
          setComplete={setComplete}
          setPin={setPin}
          handleClose={handleCloseConfirmation}
        />
      </Container>
    </Page>
  );
};

export default InvoiceDetailPage;
