import { Button, Grid, makeStyles, TextField } from '@material-ui/core';
import React, { memo } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { EventRounded } from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
interface Props {
  filter: { from: Date; to: Date };
  handleChangeFilter: <T>(key: string, value: T) => void;
}

const useStyles = makeStyles({
  container: {
    columnGap: '1em'
  }
});

const FilterSection = ({ filter, handleChangeFilter }: Props) => {
  const classes = useStyles();
  return (
    <Grid xs={12} container className={classes.container} alignItems='center'>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid xs={2}>
          <KeyboardDatePicker
            disableToolbar
            format='dd/MM/yyyy'
            margin='normal'
            required
            fullWidth
            label='Dari'
            cancelLabel='Batal'
            okLabel='Filter'
            value={filter.from}
            onChange={date => {
              if (date) {
                handleChangeFilter<Date>('from', date);
              }
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            keyboardIcon={<EventRounded fontSize='small' />}
          />
        </Grid>
        <Grid xs={2}>
          <KeyboardDatePicker
            disableToolbar
            format='dd/MM/yyyy'
            margin='normal'
            required
            fullWidth
            label='Sampai'
            value={filter.to}
            onChange={date => {
              if (date) {
                handleChangeFilter<Date>('to', date);
              }
            }}
            cancelLabel='Batal'
            okLabel='Filter'
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            keyboardIcon={<EventRounded fontSize='small' />}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    </Grid>
  );
};
export default memo(FilterSection);
