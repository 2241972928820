import { Button, Grid, IconButton, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import { Create, Delete, Visibility } from '@material-ui/icons';
import clsx from 'clsx';
import { TableCellEnd, TableCellMiddle, TableCellStart, TableRowCustom } from 'components';
import { BLACK, BLUE, WHITE } from 'constants/colors';
import { format } from 'date-fns';
import useRouter from 'hooks/useRouter';
import React from 'react';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    fontWeight: 500,
    cursor: 'pointer',
    '&:hover': {
      color: BLUE
    }
  },
  cellStart: {
    width: '3%'
  },
  cellMiddle: {
    width: '18%'
  },
  cellEnd: {
    width: '15%'
  },
  descriptionContainer: {
    backgroundColor: WHITE,
    borderRadius: '6px',
    boxShadow: '0 2px 5px rgb(0 0 0 / 14%)',
    border: '1px solid #E0E0E0'
  },
  description: {
    color: BLACK
  }
}));
interface Props {
  courierSalaryResource: CourierSalary;
}
const ContentBody = ({ courierSalaryResource }: Props) => {
  const classes = useStyles();
  const { history } = useRouter();
  const handleLink = () => history.push(`/komisi/${courierSalaryResource.idUser}`);
  return (
    <TableRowCustom key={courierSalaryResource.id}>
      <TableCellStart className={classes.cellStart}>{courierSalaryResource.idUser}</TableCellStart>
      <TableCellMiddle className={clsx(classes.cellMiddle, classes.link)} onClick={handleLink}>
        {courierSalaryResource.name}
      </TableCellMiddle>
      <TableCellMiddle className={classes.cellMiddle}>
        {(courierSalaryResource.startDate && format(new Date(courierSalaryResource.startDate), 'dd-MM-yyyy')) || '-'}
      </TableCellMiddle>
      <TableCellMiddle className={classes.cellMiddle}>
        {(courierSalaryResource.endDate && format(new Date(courierSalaryResource.endDate), 'dd-MM-yyyy')) || '-'}
      </TableCellMiddle>
      <TableCellMiddle className={classes.cellMiddle}>{courierSalaryResource.totalDelivery}</TableCellMiddle>
      <TableCellEnd className={classes.cellEnd}>
        <NumberFormat value={courierSalaryResource.totalNominal} prefix={'Rp'} thousandSeparator={true} displayType='text' />
      </TableCellEnd>
    </TableRowCustom>
  );
};

export default ContentBody;
