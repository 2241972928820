import React, { FC, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  Grid,
  makeStyles,
  Theme,
  Typography,
  TextField,
  DialogActions,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  MenuItem,
  CircularProgress
} from '@material-ui/core';

interface Props {
  open: boolean;
  stockResource: StockAllItemModel;
  isLoading: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmitStock: (ProductId: number, salesStock: number, salesStockInput: number, damageStock: number, remark: string, isStock: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    padding: '7px 0'
  }
}));

const StockSales: FC<Props> = props => {
  const classes = useStyles();

  const { open, setOpen, isLoading, stockResource, handleSubmitStock } = props;
  const [salesStock, setSalesStock] = useState<number>(0);
  const [remark, setRemark] = useState<string>('');
  useEffect(() => {
    setSalesStock(0);
    setRemark('');
  }, [open]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogContent>
        <Grid xs={12} container style={{ rowGap: '1em' }}>
          <Grid xs={12}>
            <Typography variant='h5'>Edit Stok Jual</Typography>
          </Grid>
          <Grid xs={12}>
            <TextField
              id='name produk'
              size='small'
              required
              label='Nama Produk'
              fullWidth
              value={stockResource && stockResource.productName}
              disabled
            />
          </Grid>
          <Grid xs={12} container style={{ columnGap: '1em' }} justify='space-between'>
            <Grid style={{ flex: '0.75' }}>
              <TextField
                id='category'
                size='small'
                required
                label='Kategori'
                fullWidth
                value={stockResource && stockResource.categoryName}
                disabled
              />
            </Grid>
            <Grid style={{ flex: '0.25' }}>
              <TextField
                id='warehouse'
                size='small'
                required
                label='Gudang'
                fullWidth
                value={stockResource && stockResource.warehouseName}
                disabled
              />
            </Grid>
          </Grid>

          <Grid xs={12} container style={{ columnGap: '1em' }}>
            <Grid xs={4}>
              <FormControl variant='outlined' size='small' required disabled>
                <InputLabel htmlFor='outlined-adornment-amount'>Stok Jual</InputLabel>
                <OutlinedInput
                  id='outlined-adornment-amount'
                  value={stockResource && stockResource.salesStock}
                  autoComplete='off'
                  // error={}
                  endAdornment={<InputAdornment position='end'>{stockResource && stockResource.typeUnit}</InputAdornment>}
                  // onChange={e => setNewQty(Number(e.target.value))}
                  labelWidth={80}
                />
              </FormControl>
            </Grid>
            <Grid xs={4}>
              <FormControl variant='outlined' size='small' required>
                <InputLabel htmlFor='outlined-adornment-amount'>Stok Perubahan</InputLabel>
                <OutlinedInput
                  id='outlined-adornment-amount'
                  value={salesStock}
                  autoComplete='off'
                  // error={}
                  type={stockResource && stockResource.typeUnit.includes('KG') ? 'number' : 'text'}
                  autoFocus
                  endAdornment={<InputAdornment position='end'>{stockResource && stockResource.typeUnit}</InputAdornment>}
                  onChange={e => setSalesStock(+e.target.value)}
                  labelWidth={140}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid xs={12}>
            <TextField
              size='small'
              id='edit'
              label='Alasan Perubahan'
              autoComplete='off'
              fullWidth
              onChange={e => setRemark(e.target.value)}
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item container direction='row' justify='center' alignItems='center' style={{ columnGap: '1em' }}>
          <Grid xs={3}>
            <Button size='small' className={classes.button} onClick={() => setOpen(false)} color='secondary' fullWidth>
              Batal
            </Button>
          </Grid>
          <Grid xs={3}>
            <Button
              size='small'
              className={classes.button}
              color='primary'
              fullWidth
              disabled={isLoading || remark === ''}
              onClick={() =>
                handleSubmitStock(stockResource && stockResource.id, stockResource && stockResource.salesStock, salesStock, 0, remark, true)
              }
            >
              {isLoading ? <CircularProgress size={25} color='primary' /> : 'Simpan'}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default StockSales;
