import React, { FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { TableCellEnd, TableCellMiddle, TableCellStart, TableRowCustom, StatusOrder } from 'components';
import { IconButton, Tooltip } from '@material-ui/core';
import { format } from 'date-fns';
import DeleteOutline from '@material-ui/icons/DeleteOutline';

interface Props {
  purchaseOrder: PurchaseOrderModel;
  isLoading: boolean;
  onDelete: React.MouseEventHandler;
}

const BodyRow: FC<Props> = props => {
  const { purchaseOrder, isLoading, onDelete } = props;
  const handleLink = (id: number) => {
    window.open(`/purchase/${id}`, '_blank');
  };

  return (
    <TableRowCustom>
      <TableCellStart onClick={() => handleLink(purchaseOrder.id)}>
        {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : purchaseOrder.orderNumber}
      </TableCellStart>
      <TableCellMiddle onClick={() => handleLink(purchaseOrder.id)}>
        {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : purchaseOrder.supplierName || '-'}
      </TableCellMiddle>
      <TableCellMiddle align='center'>
        {isLoading ? <Skeleton variant='text' width={100} height={25} /> : <StatusOrder status={purchaseOrder.statusOrder} />}
      </TableCellMiddle>
      <TableCellMiddle align='center' onClick={() => handleLink(purchaseOrder.id)}>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : purchaseOrder.orderDate ? (
          format(new Date(purchaseOrder.orderDate), 'dd/MM/yyyy')
        ) : (
          '-'
        )}
      </TableCellMiddle>
      <TableCellMiddle onClick={() => handleLink(purchaseOrder.id)}>
        {isLoading ? <Skeleton variant='text' width={'100%'} height={25} /> : purchaseOrder.warehouse && purchaseOrder.warehouse}
      </TableCellMiddle>

      <TableCellEnd align='center'>
        {isLoading ? (
          <Skeleton variant='text' width={'100%'} height={25} />
        ) : (
          purchaseOrder.statusOrder !== 'CONFIRMATION' && (
            <Tooltip title='Hapus '>
              <IconButton size='small' onClick={onDelete}>
                <DeleteOutline fontSize='small' />
              </IconButton>
            </Tooltip>
          )
        )}
      </TableCellEnd>
    </TableRowCustom>
  );
};

export default BodyRow;
