import { Container, Grid, makeStyles, Typography } from '@material-ui/core';
import { BarChartRounded, PieChartRounded } from '@material-ui/icons';
import { Breadcrumb, Page } from 'components';
import { GREY } from 'constants/colors';
import React from 'react';
import ReportList from './ReportList';

const useStyles = makeStyles({
  container: {
    marginTop: '1.5em'
  },
  icon: {
    fontSize: '4rem'
  }
});

const ReportPage = () => {
  const classes = useStyles();
  return (
    <Page title='Laporan'>
      <Container>
        <Grid xs={12}>
          <Typography variant='h1' component='h1'>
            Laporan
          </Typography>
          <Breadcrumb />
        </Grid>
        <Grid xs={12} className={classes.container}>
          <ReportList
            items={[
              {
                id: 0,
                path: 'laporan-laba-rugi',
                title: 'Laporan Laba Rugi',
                description: 'Laporan laba rugi berdasarkan dari pemasukan dan pengeluaran pada periode tertentu',
                iconList: <BarChartRounded color='primary' className={classes.icon} />
              },
              {
                id: 1,
                path: 'laporan-orderan',
                title: 'Laporan Orderan',
                description: 'Lihat rekapan laporan penjualan dan pembelian lebih rinci berdasarkan periode tertentu',
                iconList: <PieChartRounded color='primary' className={classes.icon} />
              }
            ]}
          />
        </Grid>
      </Container>
    </Page>
  );
};

export default ReportPage;
