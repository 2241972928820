import React, { FC, memo, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import NumberFormatMask from 'components/NumberFormatMask';
import { YELLOW } from 'constants/colors';
import { format } from 'date-fns';
import PaymentStatus from 'typings/enum/PaymentStatus';
import getCurrency from 'utils/getCurrency';

interface Props {
  open: boolean;
  totalPrice: number;
  isLoading: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}
const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fontSize: '3em',
    color: YELLOW
  },
  button: {
    borderRadius: '15px',
    padding: '7px 0'
  }
}));
const InvoicePaymentCreate = ({ open, handleClose, handleSubmit, totalPrice, isLoading }: Props) => {
  const classes = useStyles();

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>
        <Typography variant='h5'>Pembayaran Sekaligus!</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid xs={12} container>
          <TextField id='paymentMethod' required label='Metode Pembayaran' value='Bank Transfer' disabled fullWidth />
          <TextField
            id='payDate'
            required
            fullWidth
            type='date'
            label='Tanggal Pembayaran'
            value={format(new Date(), 'yyyy-MM-dd')}
            disabled
            InputLabelProps={{
              shrink: true
            }}
          />

          <RadioGroup row aria-label='position' name='position' defaultValue='end'>
            <FormControlLabel value='1' control={<Radio color='primary' checked={true} />} label='Bayar Full' labelPlacement='end' />
          </RadioGroup>
          <TextField
            label='Jumlah Bayar'
            name='totalPay'
            fullWidth
            disabled
            value={totalPrice}
            InputProps={{
              inputComponent: NumberFormatMask as any
            }}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid xs={12} container justify='space-evenly'>
          <Grid xs={5}>
            <Button size='small' fullWidth className={classes.button} variant='outlined' color='primary' onClick={handleClose}>
              Kembali
            </Button>
          </Grid>
          <Grid xs={5}>
            <Button size='small' fullWidth className={classes.button} color='primary' onClick={handleSubmit} disabled={isLoading}>
              {isLoading ? <CircularProgress size={20} color='primary' /> : 'Bayar'}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default InvoicePaymentCreate;
