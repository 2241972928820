import { Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { TcourierSalary, Tresource } from '..';
import ContentData from './ContentData';
import Empty from 'images/icon/EmptyIcon.svg';
import clsx from 'clsx';
import { format } from 'date-fns';
import { GREY } from 'constants/colors';
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    rowGap: '1em'
  },
  containerEmpty: {
    minHeight: '15em',
    borderTop: `1px solid ${GREY}`
  },
  titleContainer: {
    padding: '0 1em'
  }
}));
interface Props {
  deliveryResource: Tresource<DeliveryOrders>;
  courierSalary: TcourierSalary;
}
const ContentHistory = ({ deliveryResource, courierSalary }: Props) => {
  const classes = useStyles();
  return (
    <Grid xs={12} container className={classes.container}>
      <Grid xs={12} className={classes.container} container direction='column'>
        <Typography variant='h6'>Riwayat pengantaran kurir ({deliveryResource.data.length})</Typography>
        <Typography color='textSecondary'>
          Dari taggal : {`${format(courierSalary.data.startDate, 'dd-MM-yyyy')} s/d ${format(courierSalary.data.endDate, 'dd-MM-yyyy')}`}
        </Typography>
      </Grid>
      <Grid xs={12}>
        <Divider />
      </Grid>
      <Grid xs={12} container className={classes.titleContainer}>
        <Grid xs={1}>
          <Typography color='textSecondary'>NO.</Typography>
        </Grid>
        <Grid xs={4}>
          <Typography color='textSecondary'>NO SURAT JALAN</Typography>
        </Grid>
        <Grid xs={4}>
          <Typography color='textSecondary'>NAMA MITRA</Typography>
        </Grid>
        <Grid xs={3}>
          <Typography color='textSecondary'>TANGGAL</Typography>
        </Grid>
      </Grid>
      {deliveryResource.isLoading ? (
        <Grid xs={12}>
          {[...new Array(5)].map((val, key) => (
            <Grid xs={12}>
              <Skeleton variant='text' animation='wave' height={60} key={key} />
            </Grid>
          ))}
        </Grid>
      ) : deliveryResource.data.length > 0 ? (
        deliveryResource.data.map((delivery, index) => <ContentData delivery={delivery} no={index + 1} />)
      ) : (
        <Grid xs={12} container direction='column' justify='center' alignItems='center' className={clsx(classes.container, classes.containerEmpty)}>
          <img src={Empty} alt='Empty Logo' />
          <Typography variant='h6' color='primary'>
            Data tidak tersedia
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default ContentHistory;
