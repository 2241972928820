import React, { FC, useState } from 'react';
import { Dialog, DialogContent, Button, Grid, makeStyles, Theme, Typography, DialogActions } from '@material-ui/core';
import { DialogTitle } from 'components/Dialog';
import { BLACK, YELLOW } from 'constants/colors';
import { WarningRounded } from '@material-ui/icons';

interface Props {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    borderRadius: '15px',
    padding: '7px 0'
  },

  titleContainer: {
    fontWeight: 500,
    fontSize: '1.2rem',
    color: BLACK,
    paddingLeft: '6px'
  },
  containerContent: {
    [theme.breakpoints.up('md')]: {
      width: '70vh'
    }
  },
  containerAction: {
    marginBottom: theme.spacing(1)
  },
  spacing: {
    marginTop: theme.spacing(2)
  }
}));

const ModalDelete = ({ open, handleClose, handleSubmit }: Props) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose} scroll='paper'>
      <DialogTitle>
        <Grid item container direction='column' alignItems='center' xs={12}>
          <WarningRounded style={{ color: YELLOW, fontSize: '3rem' }} />
          <Typography className={classes.titleContainer}>Hapus Item</Typography>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.containerContent}>
        <Grid xs={12}>
          <Typography align='center' style={{ fontSize: '1rem' }}>
            Apakah kamu yakin ingin menghapus item ini?
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.containerAction}>
        <Grid item container direction='row' justify='space-evenly' alignItems='center'>
          <Grid xs={5}>
            <Button size='small' fullWidth className={classes.button} variant='outlined' onClick={handleClose}>
              Batal
            </Button>
          </Grid>

          <Grid xs={5}>
            <Button size='small' fullWidth className={classes.button} color='primary' onClick={handleSubmit}>
              Hapus
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ModalDelete;
