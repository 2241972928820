import React, { FC, useEffect, useState, useContext } from 'react';
import { Grid, Container, Typography, makeStyles, Button, Paper, Breadcrumbs, Link } from '@material-ui/core';
import { DeleteRounded } from '@material-ui/icons';
import { Page, StandardConfirmationDialog } from 'components';
import axios, { CancelTokenSource } from 'axios';
import { PRODUCT_BASE_URL } from 'constants/url';
import { GREY_6 } from 'constants/colors';
import { dummyProduct, dummyProductImage } from 'utils/dummy';
import ImageSlider from './components/ImageSlider';
import ProductDetail from './components/ProductDetail';
import ProductPackage from './components/ProductPackage';
import useRouter from 'hooks/useRouter';
import TypeUser from 'typings/enum/TypeUser';
import useRole from 'hooks/useRole';
import { CurrentUserContext } from 'contexts/CurrentUserContext';

const useStyles = makeStyles({
  paper: {
    padding: 15,
    marginTop: 15,
    marginBottom: 20
  },
  defaultImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '409px',
    height: '409px',
    backgroundColor: GREY_6
  },
  active: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '16px'
  },
  breadCrumb: {
    marginTop: '3px'
  }
});

const ProductPackageDetailPage: FC = () => {
  const classes = useStyles();
  // eslint-disable-next-line
  const { match } = useRouter();
  const params = match.params.id;
  const { history } = useRouter();
  const [open, setOpen] = useState<boolean>(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [id, setId] = useState<number>();
  const [product, setProduct] = useState<ProductModel>(dummyProduct);
  const [productImages, setProductImages] = useState<ProductImageModel[]>([dummyProductImage]);
  const [confirmationDelete, setConfirmationDelete] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [isDelete, setDelete] = useState<boolean>(false);
  const { currentUser } = useContext(CurrentUserContext);

  const fetchData = async () => {
    setIsLoadingData(true);
    try {
      const { data } = await axios.get(`${PRODUCT_BASE_URL}/${params}`);
      setProduct(data.data);
      setId(data.data.id);
      setIsLoadingData(false);
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    // if (params && params.page) {
    //   setCurrentPage(params.page);
    // }

    fetchData();
  }, []);

  useEffect(() => {
    if (product.id === 0) {
      return;
    }

    setProductImages(product.ProductImages ? product.ProductImages : [dummyProductImage]);
  }, [product]);

  const handleConfirmationDelete = (): void => {
    setConfirmationDelete(true);
  };

  const handleCloseConfirmationDelete = (): void => {
    setConfirmationDelete(false);
  };

  const deleteProduct = async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    try {
      await axios.delete(`${PRODUCT_BASE_URL}/${id}`, { cancelToken: cancelTokenSource.token });
      handleSnackBar(true, 'success', 'Paket berhasil dihapus.', true);
    } catch (err) {
      console.log(err);
      handleSnackBar(true, 'error', 'Paket gagal dihapus.');
    } finally {
      setConfirmationDelete(false);
    }
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string, hasDelete?: boolean): void => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
    setDelete(hasDelete || false);
  };

  const handleCloseSnackbar = (): void => {
    setOpenSnackbar(false);
  };

  const handleConfirmSnackbar = (): void => {
    setOpenSnackbar(false);
    if (isDelete) {
      history.push('/produk');
    }
  };

  const handleOpenForm = (): void => {
    setOpen(true);
  };

  const onClickHandler = (path: string): React.MouseEventHandler => event => {
    event.preventDefault();
    history.push(path);
  };

  const isSuperVisor = useRole({
    type: (currentUser && currentUser.type) || 'SALES',
    allowed: ['SUPERVISOR']
  });

  return (
    <Page title='Produk'>
      <Container>
        <Grid container direction='row'>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1' component='h1'>
              Semua Produk
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumbs key={1} aria-label='breadcrumb' className={classes.breadCrumb}>
              <Link key={99} color='inherit' href='/' onClick={onClickHandler('/')}>
                Beranda
              </Link>
              <Link color='inherit' href='/' onClick={onClickHandler('/produk')}>
                Produk
              </Link>
              <Typography color='inherit' className={classes.active}>
                Detail
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Grid container direction='row' justify='space-between' spacing={3}>
          <Grid container item lg={8} sm={8} md={8} justify='flex-start'></Grid>

          {!!currentUser && currentUser.type !== TypeUser.SALES && (
            <Grid container item lg={4} sm={4} md={4} spacing={2} justify='flex-end'>
              {!isSuperVisor && (
                <>
                  <Grid item>
                    <Button disabled={isLoadingData} color='secondary' onClick={handleConfirmationDelete}>
                      <DeleteRounded fontSize='small' /> &nbsp; Hapus
                    </Button>
                  </Grid>

                  {/* <Grid item>
                    <Button disabled={isLoadingData} onClick={handleOpenForm}>
                      <EditRounded fontSize='small' /> &nbsp; Edit Produk
                    </Button>
                  </Grid> */}
                </>
              )}
            </Grid>
          )}
        </Grid>

        <Paper elevation={2} className={classes.paper}>
          <Grid container direction='row' spacing={2} justify='space-between'>
            <Grid container justify='center' item xl={6} lg={6} md={6} sm={12} xs={12}>
              {isLoadingData ? (
                <Typography>Menunggu data...</Typography>
              ) : product.ProductImages && product.ProductImages.length > 0 ? (
                <ImageSlider productImages={productImages} />
              ) : (
                <div className={classes.defaultImage}>
                  <div>
                    <h3 style={{ textAlign: 'center' }}>{product.productName}</h3>
                    {product.ProductPackage &&
                      product.ProductPackage.ProductItem &&
                      product.ProductPackage.ProductItem.map((value, index) => (
                        <div key={index}>
                          <p style={{ textAlign: 'center' }}>
                            {value.Product ? `${value.Product.productName} ${value.minimumItem} ${value.Product.typeUnit}` : '-'}
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ProductDetail product={product} isLoading={isLoadingData} />
            </Grid>

            <ProductPackage product={product} setOpen={setOpen} open={open} setProduct={setProduct} handleSnackBar={handleSnackBar} />

            <StandardConfirmationDialog
              variant={snackbarVariant}
              titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
              message={message}
              open={openSnackbar}
              handleClose={snackbarVariant === 'success' ? handleConfirmSnackbar : handleCloseSnackbar}
              onConfirm={handleCloseSnackbar}
              noCancelButton={true}
            />

            <StandardConfirmationDialog
              variant={'danger'}
              titleMessage={'Delete'}
              message={'Apakah kamu yakin menghapus data ini?'}
              open={confirmationDelete}
              handleClose={handleCloseConfirmationDelete}
              onConfirm={deleteProduct}
            />
          </Grid>
        </Paper>
      </Container>
    </Page>
  );
};

export default ProductPackageDetailPage;
