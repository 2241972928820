import React, { FC, useState, useEffect, useCallback } from 'react';
import { Page, Breadcrumb, StandardConfirmationDialog } from 'components';
import { dummyUser } from 'utils/dummy';
import { Container, Typography, Grid } from '@material-ui/core';
import { INVOICE_BASE_URL, USER_BASE_URL, SALES_ORDER_BASE_URL, ZONE_BASE_URL } from 'constants/url';
import axios from 'axios';
import useRouter from 'hooks/useRouter';
import SalesDetail from './components/SalesDetail';
import UserTable from './components/UserTable';
const UserDetail: FC = () => {
  const { location } = useRouter();
  // eslint-disable-next-line
  const param: any = location.state;
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [commission, setCommission] = useState<CommissionModel[]>([]);
  const [sales, setSales] = useState<UserDetailsModel>(dummyUser);
  const [loadCommission, setLoadCommission] = useState<boolean>(false);

  const [selectedId, setSelectedId] = useState<number>();
  const [confirmationDelete, setConfirmationDelete] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [invoiceId, setInvoiceId] = useState<string>('');
  const [invoices, setInvoices] = useState<InvoiceModel[]>([]);
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [indexCollapse, setIndexCollapse] = useState<number>(-1);
  const [statusPayment, setStatusPayment] = useState<string>('');
  const [loadInvoice, setLoadInvoice] = useState<boolean>(false);
  const [loadingItem, setLoadingItem] = useState<boolean>(false);

  const handleCloseConfirmationDelete = () => {
    setConfirmationDelete(false);
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string) => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleConfirmSnackbar = () => {
    setOpenSnackbar(false);
  };

  const fetchData = useCallback(async () => {
    const getQueryParams = () => {
      const params = new URLSearchParams();
      params.append('salesId', String(sales.id));

      params.append('page', currentPage.toString());
      return params.toString();
    };

    try {
      const { data } = await axios.get(`${SALES_ORDER_BASE_URL}?${getQueryParams()}`);
      setCount(data.meta.last_page);
      setCurrentPage(data.meta.current_page);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setIsLoadingData(false);
    }
  }, [orderBy, order, currentPage, sales]);

  const fetchSales = async () => {
    setIsLoadingData(true);
    try {
      const { data } = await axios.get(`${USER_BASE_URL}/${param.id}`);
      setSales(data.data);
    } catch (error) {
      console.log('error:', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const fetchCommission = useCallback(async () => {
    const queryParams = () => {
      const params = new URLSearchParams();
      params.append('salesId', String(sales.id));

      if (orderBy || order) {
        params.append('orderBy', orderBy);
        params.append('ordered', order);
      }

      params.append('page', currentPage.toString());
      return params.toString();
    };
    setLoadCommission(true);
    try {
      const { data } = await axios.get(`${INVOICE_BASE_URL}?${queryParams()}`);
      setInvoices(data.data);
      setCount(data.meta.last_page);
      setCurrentPage(data.meta.current_page);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoadCommission(false);
    }
  }, [orderBy, order, currentPage, sales]);

  const getData = async (id: number) => {
    setLoadingItem(true);
    try {
      const { data } = await axios.get(`${INVOICE_BASE_URL}/${id}`);
      setInvoices(prevState =>
        prevState.map(value => {
          if (value.id === id) {
            value.InvoiceItem = data.data.InvoiceItem;
          }
          return value;
        })
      );
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoadingItem(false);
    }
  };

  const handleOpenCollapse = (index: number, id: number): React.MouseEventHandler => () => {
    setIndexCollapse(index);
    setOpenCollapse(openCollapse ? (index === indexCollapse ? false : true) : true);
    getData(id);
  };

  const deleteCommission = async () => {
    try {
      await axios.delete(`${INVOICE_BASE_URL}/${selectedId}`);
      setCommission(prevState => prevState.filter(value => value.id !== selectedId));
      handleSnackBar(true, 'success', 'Komisi berhasi dihapus');
    } catch (err) {
      console.log(err);
    } finally {
      setConfirmationDelete(false);
    }
  };

  useEffect(() => {
    fetchSales();
  }, []);

  useEffect(() => {
    fetchCommission();
  }, [fetchCommission]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Page title='Komisi'>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'>Pengguna</Typography>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <SalesDetail sales={sales} isLoading={isLoadingData} />
          </Grid>

          <Grid item lg={7} md={7} sm={12} xs={12}>
            <UserTable
              statusPayment={statusPayment}
              invoiceId={invoiceId}
              isLoadingData={loadInvoice}
              count={count}
              currentPage={currentPage}
              invoices={invoices}
              order={order}
              orderBy={orderBy}
              openCollapse={openCollapse}
              indexCollapse={indexCollapse}
              setInvoiceId={setInvoiceId}
              setStatusPayment={setStatusPayment}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              handleOpenCollapse={handleOpenCollapse}
              setCurrentPage={setCurrentPage}
              loadingItem={loadingItem}
            />
          </Grid>
        </Grid>
        <StandardConfirmationDialog
          variant={snackbarVariant}
          titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
          message={message}
          open={openSnackbar}
          handleClose={handleCloseSnackbar}
          onConfirm={handleConfirmSnackbar}
          noCancelButton={true}
        />
        <StandardConfirmationDialog
          variant={'danger'}
          titleMessage={'Menghapus Data'}
          message={'Apakah kamu yakin akan menghapus data ini?'}
          open={confirmationDelete}
          handleClose={handleCloseConfirmationDelete}
          onConfirm={deleteCommission}
        />
      </Container>
    </Page>
  );
};

export default UserDetail;
