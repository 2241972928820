import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Theme,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CardActions,
  IconButton,
  TextField,
  Button,
  FormHelperText
} from '@material-ui/core';
import useRouter from 'hooks/useRouter';
import { GREEN, PRIMARY, PRIMARY_SECONDARY, WHITE, YELLOW } from 'constants/colors';
import { Skeleton } from '@material-ui/lab';
import NumberFormat from 'react-number-format';
import { useProductCart } from 'contexts/ProductCartContext';
import { Add, Remove } from '@material-ui/icons';
import clsx from 'clsx';
import PartnerType from 'typings/enum/PartnerType';

interface Props {
  handleOpenImage: (imagePath: string) => void;
  productResource: ProductModel;
  productLoading: boolean;
  isUpdate: boolean;
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: '0.5rem',
    position: 'relative',
    transition: 'transform 10s ease-in',
    '&:hover': {
      boxShadow: '0 3px 3px 0 rgba(0, 0, 0, 0.007), 0 4px 7px 0 ' + PRIMARY_SECONDARY,
      transform: 'scale(1,1)'
    }
  },

  media: {
    height: 200,
    display: 'grid',
    placeItems: 'center',
    padding: '0 1.2rem',
    backgroundColor: '#e0e0e0',
    '& .MuiCardMedia-img ': {
      objectFit: 'cover'
    }
  },

  containerNotif: {
    position: 'absolute',
    display: 'flex',
    top: 10,
    right: 10,
    flexDirection: 'column',
    gap: '0.6em',
    zIndex: 1
  },
  isNew: {
    background: PRIMARY,
    border: '1px solid #fff',
    padding: '0.1em 0.3em',
    fontSize: '0.8rem',
    color: '#fff',
    borderRadius: '0.4em'
  },
  isReStock: {
    background: GREEN,
    border: '1px solid #fff',
    padding: '0.1em 0.5em',
    fontSize: '0.8rem',
    color: '#fff',
    borderRadius: '0.4em'
  },
  price: {
    color: YELLOW
  },
  iconButton: {
    backgroundColor: PRIMARY,
    '&:hover': {
      backgroundColor: PRIMARY_SECONDARY
    }
  },
  icon: {
    color: WHITE
  },
  fontSize: {
    fontSize: '.9rem'
  }
}));
const CardItem = ({ productResource, handleOpenImage, productLoading, isUpdate }: Props) => {
  const classes = useStyles();
  const { history } = useRouter();
  const { getItemQuantity, getSalesStock, increaseCartQuantity, decreaseCartQuantity, changeQuantity, productCartInformation } = useProductCart();
  const totalQty = getItemQuantity(productResource.id);
  const salesStock = isUpdate
    ? getSalesStock(productResource.id) > 0
      ? getSalesStock(productResource.id)
      : productResource.salesStock
    : productResource.salesStock;
  const disabled =
    productLoading ||
    salesStock <= 0 ||
    totalQty > salesStock ||
    (productCartInformation.partnerType.includes(PartnerType.CUSTOMER)
      ? productCartInformation.PartnerId < 1
      : productCartInformation.PartnerId < 1 || productCartInformation.resellerCustomerId < 1);
  return (
    <Grid xs={12} lg={11} xl={11}>
      <Card className={classes.root}>
        {
          <Grid xs={12} className={classes.containerNotif}>
            {productResource.new && (
              <Typography className={classes.isNew} align='center' variant='h6'>
                Baru
              </Typography>
            )}
            {productResource.reStock && (
              <Typography className={classes.isReStock} align='center' variant='h6'>
                Re-Stok
              </Typography>
            )}
          </Grid>
        }
        <CardActionArea>
          {productLoading ? (
            <Skeleton animation='wave' variant='rect' width={300} height={200} />
          ) : (
            <CardMedia
              className={classes.media}
              image={(productResource.ProductImage && productResource.ProductImage.url) || ''}
              title='gambar-produk'
              onClick={() => !productLoading && handleOpenImage((productResource.ProductImage && productResource.ProductImage.url) || '')}
            >
              {!(productResource.ProductImage && productResource.ProductImage.url) && (
                <Typography variant='h6' style={{ color: '#000000' }}>
                  {productResource.productName}
                </Typography>
              )}
            </CardMedia>
          )}

          {productLoading ? (
            <CardContent>
              <Skeleton animation='wave' height={30} />
              <Skeleton animation='wave' height={30} />
              <Skeleton animation='wave' height={30} />
              <Skeleton animation='wave' height={30} />
              <Skeleton animation='wave' height={30} />
            </CardContent>
          ) : (
            <CardContent onClick={() => history.push(`/produk/${productResource.id}`)}>
              <Typography
                gutterBottom
                component='h6'
                variant='h6'
                style={{ overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}
              >
                {productResource.productName}
              </Typography>
              <Grid xs={12} container>
                <Grid xs={6}>
                  <Typography color='textSecondary' className={classes.fontSize}>
                    Kode
                  </Typography>
                  <Typography color='textSecondary' className={classes.fontSize}>
                    Kategori
                  </Typography>
                  <Typography color='textSecondary' className={classes.fontSize}>
                    Stok Jual
                  </Typography>
                  <Typography color='textSecondary' className={classes.fontSize}>
                    Harga Customer
                  </Typography>
                  <Typography color='textSecondary' className={classes.fontSize}>
                    Harga Reseller
                  </Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography className={classes.fontSize}>: {productResource.productCode}</Typography>
                  <Typography className={classes.fontSize}>: {productResource.Category!.name}</Typography>
                  <Typography className={classes.fontSize}>: {`${salesStock} ${productResource.typeUnit}`}</Typography>
                  <Typography className={clsx(classes.price, classes.fontSize)}>
                    :{' '}
                    <NumberFormat
                      value={productResource.customerPrice ? productResource.customerPrice : 0}
                      prefix={'Rp'}
                      thousandSeparator={true}
                      displayType='text'
                    />
                  </Typography>
                  <Typography className={clsx(classes.price, classes.fontSize)}>
                    :{' '}
                    <NumberFormat
                      value={productResource.resellerPrice ? productResource.resellerPrice : 0}
                      prefix={'Rp'}
                      thousandSeparator={true}
                      displayType='text'
                    />
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          )}
        </CardActionArea>
        <CardActions>
          <Grid container justify='center'>
            <Grid xs={11}>
              {totalQty !== 0 ? (
                <Grid xs={12} container justify='space-between' alignItems='center'>
                  <IconButton
                    color='primary'
                    aria-label='upload picture'
                    component='div'
                    className={classes.iconButton}
                    size='small'
                    onClick={() => decreaseCartQuantity(productResource.id)}
                  >
                    <Remove className={classes.icon} />
                  </IconButton>
                  <Grid xs={6}>
                    <TextField
                      id='quantity'
                      value={totalQty}
                      type={productResource.typeUnit.includes('KG') ? 'number' : 'text'}
                      inputProps={{ min: 0, style: { textAlign: 'center' }, inputMode: 'numeric', pattern: '[0-9]*' }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      autoComplete='off'
                      variant='outlined'
                      error={totalQty > salesStock}
                      onChange={event => {
                        if (event.target.value) {
                          changeQuantity(productResource.id, +event.target.value);
                        } else {
                          changeQuantity(productResource.id, 0);
                        }
                      }}
                    />
                  </Grid>
                  <IconButton
                    color='primary'
                    aria-label='increaseQuantity'
                    component='div'
                    className={classes.iconButton}
                    size='small'
                    onClick={() =>
                      increaseCartQuantity(
                        productResource.id,
                        productResource.productName,
                        (productResource.Category && productResource.Category) || { id: 0, name: '' },
                        productResource.customerPrice,
                        productResource.resellerPrice,
                        productResource.typeUnit,
                        salesStock
                      )
                    }
                  >
                    <Add className={classes.icon} />
                  </IconButton>
                </Grid>
              ) : (
                <Button
                  variant='outlined'
                  color='primary'
                  fullWidth
                  size='medium'
                  onClick={() => {
                    increaseCartQuantity(
                      productResource.id,
                      productResource.productName,
                      (productResource.Category && productResource.Category) || { id: 0, name: '' },
                      productResource.customerPrice,
                      productResource.resellerPrice,
                      productResource.typeUnit,
                      salesStock
                    );
                  }}
                  disabled={disabled}
                >
                  Masuk Keranjang
                </Button>
              )}
              {totalQty > salesStock && (
                <Grid xs={12} container justify='center'>
                  <FormHelperText error={true}>Melebihi jumlah stok ({salesStock})</FormHelperText>
                </Grid>
              )}
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default CardItem;
