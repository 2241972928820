import React, { FC, useEffect, useCallback, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { Grid, Container, Typography, Tooltip, Button, TextField, makeStyles, Theme, IconButton } from '@material-ui/core';
import { Page, Breadcrumb, PaperCustom } from 'components';
import { APPLOG_BASE_URL } from 'constants/url';
import AppLogPageTable from './components/AppLogPageTable';
import Pagination from '@material-ui/lab/Pagination';
import RefreshIcon from '@material-ui/icons/Refresh';
import { format, startOfMonth } from 'date-fns';
import useDebounce from 'hooks/useDebounce';
import DateRangeFilter from 'components/DateRangeFilter';
import CalendarIcon from '@material-ui/icons/EventNote';
import { GREEN, WHITE } from 'constants/colors';

const useStyles = makeStyles((theme: Theme) => ({
  calendarIcon: {
    fontSize: 30,
    color: theme.palette.primary.main
  },
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN
    }
  }
}));

const AppLogPage: FC = () => {
  const classes = useStyles();

  const [count, setCount] = useState<number>(0);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [appLogs, setAppLogs] = useState<AppLogsModel[]>([]);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [indexCollapse, setIndexCollapse] = useState<number>(-1);
  const [total, setTotal] = useState<number>(0);
  const [to, setTo] = useState<number>(0);
  const [from, setFrom] = useState<number>(0);
  const [query, setQuery] = useState<string>('');
  const [queryString, setQueryString] = useState<string>();
  const [filterBy, setFilterBy] = useState<string>('');

  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [openCalendarFilter, setOpenCalendarFilter] = useState<boolean>(false);

  const handleRefresh = () => {
    setStartDate(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
    setEndDate(format(new Date(), 'yyyy-MM-dd'));

    setOrderBy('id');
    setOrder('desc');
    setCurrentPage(1);
    setFilterBy('');
  };

  const fetchData = useCallback(async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIsLoadingData(true);

    const getQueryParams = () => {
      const params = new URLSearchParams();

      if (queryString) {
        params.append('keyword', queryString);
      }

      if (!queryString && startDate) {
        params.append('startDate', startDate !== null ? format(new Date(startDate), 'yyyy-MM-dd').toString() : '');
      }

      if (!queryString && endDate) {
        params.append('endDate', endDate !== null ? format(new Date(endDate), 'yyyy-MM-dd').toString() : '');
      }

      if (orderBy || order) {
        params.append('orderBy', orderBy);
        params.append('ordered', order);
      }

      params.append('page', currentPage.toString());

      return params.toString();
    };

    try {
      const { data } = await axios.get(`${APPLOG_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelTokenSource.token });
      setAppLogs(data.data);
      setCount(data.meta.last_page);
      setCurrentPage(data.meta.current_page);
      setTotal(data.meta.total);
      setTo(data.meta.to);
      setFrom(data.meta.from);
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoadingData(false);
    }
    return () => {
      cancelTokenSource.cancel();
    };
  }, [queryString, orderBy, order, currentPage, filterBy, startDate, endDate]);

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    setCurrentPage(1);
    action(actionParam);
  };

  const handleCalendarFilterLog = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenCalendarFilter(!openCalendarFilter);
  };

  const handleSearch = useCallback((searchQuery: string) => {
    performActionAndRevertPage(setQueryString, searchQuery);
  }, []);

  const handleOpenCollapse = (index: number): React.MouseEventHandler => () => {
    setIndexCollapse(index);
    setOpenCollapse(openCollapse ? (index === indexCollapse ? false : true) : true);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const debouncedSearchTerm = useDebounce(query, 500);

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      handleSearch(debouncedSearchTerm);
    } else if (debouncedSearchTerm.length === 0) {
      handleSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, handleSearch]);

  return (
    <Page title='App Logs'>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'> App Log </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>

        <PaperCustom>
          <Grid container direction='row' alignItems='center' item lg={12} md={12} sm={12} xs={12} spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={6} xs={6}>
              <Tooltip title='Memuat Ulang'>
                <Button onClick={handleRefresh} color='inherit' className={classes.refresh}>
                  <RefreshIcon />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <Typography>{`Menampilkan ${total} app log (${from} - ${to} dari ${total})`}</Typography>
              {startDate && endDate && (
                <Typography color='primary'>
                  Data tanggal {format(new Date(startDate), 'dd-MM-yyyy')} s/d {format(new Date(endDate), 'dd-MM-yyyy')}
                </Typography>
              )}
            </Grid>

            <Grid item container alignItems='center' xs={7}>
              <Grid item sm={1} xs={1}>
                <Tooltip title='Calendar filter' placement='top'>
                  <IconButton size='small' onClick={event => handleCalendarFilterLog(event)}>
                    <CalendarIcon className={classes.calendarIcon} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={11} container justify='flex-end'>
                <TextField fullWidth value={query} onChange={e => setQuery(e.target.value)} placeholder='Cari Deskripsi...' />
              </Grid>
            </Grid>

            <AppLogPageTable
              isLoadingData={isLoadingData}
              order={order}
              orderBy={orderBy}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              logs={appLogs}
              openCollapse={openCollapse}
              indexCollapse={indexCollapse}
              handleOpenCollapse={handleOpenCollapse}
            />

            <DateRangeFilter
              openCalendarFilter={openCalendarFilter}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              handleClose={() => {
                setOpenCalendarFilter(false);
              }}
            />
            <Grid container justify='center' item xl={12} md={12} sm={12}>
              {appLogs && appLogs.length > 0 && (
                <Pagination count={count} onChange={(event, page) => setCurrentPage(page)} page={currentPage} boundaryCount={2} variant='outlined' />
              )}
            </Grid>
          </Grid>
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default AppLogPage;
