import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import { Theme, makeStyles } from '@material-ui/core';
import { Switch, Route } from 'react-router';
import SettingsPage from 'pages/SettingsPage';
import UserPage from 'pages/UserPage';
import AppLogPage from 'pages/AppLogPage';
import AccessPage from 'pages/AccessPage';
import ConditionalRoute from 'components/ConditionalRoute';
import LoginPage from 'pages/LoginPage';
import HomePage from 'pages/HomePage';
import NotFoundPage from 'pages/NotFoundPage';
import CompanyPage from 'pages/CompanyPage';
import PartnerPage from 'pages/PartnerPage';
import CategoryPage from 'pages/CategoryPage';
import ProductPage from 'pages/ProductPage';
import ProductDetailPage from 'pages/ProductDetailPage';
import ProductPackageDetailPage from 'pages/ProductPackageDetailPage';
import PartnerDetailPage from 'pages/PartnerDetailPage';
import AppHeader from 'components/AppHeader';
import AppDrawer from 'components/AppDrawer';
import WareHousePage from 'pages/WareHousePage';
import PurchaseOrderPage from 'pages/PurchaseOrderPage';
import PurchaseOrderDetailPage from 'pages/PurchaseOrderDetailPage';
import PurchaseOrderCreatePage from 'pages/PurchaseOrderCreatePage';
import AllStockPage from 'pages/AllStockPage';
import SalesOrderDetailPage from 'pages/SalesOrderDetailPage';
import SalesOrderPage from 'pages/SalesOrderPage';
import SalesOrderCreatePage from 'pages/SalesOrderCreatePage';
import InvoicePage from 'pages/InvoicePage';
import InvoiceCreatePage from 'pages/InvoiceCreatePage';
import PurchaseInvoicePage from 'pages/PurchaseInvoicePage';
import PurchaseInvoiceCreatePage from 'pages/PurchaseInvoiceCreatePage';
import InvoiceDetailPage from 'pages/InvoiceDetailPage';
import PurchaseInvoiceDetailPage from 'pages/PurchaseInvoiceDetailPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from 'pages/ResetPassowordPage';
import ReviewStock from 'pages/ReviewStock';
import ReviewStockDetail from 'pages/ReviewStockDetail';
import { CurrentUserContext, CurrentUserProvider } from 'contexts/CurrentUserContext';
import { CartProvider } from 'contexts/CartContext';
import { isUserAuthenticated } from 'selectors';
import { attachTokenToHeader, detachTokenFromHeader } from 'utils/AxiosUtils';
import { GET_CURRENT_USER_URL, BADGE_NOTIFICATION_BASE_URL } from 'constants/url';
import 'react-quill/dist/quill.snow.css';
import UserDetailPage from 'pages/UserDetail';
import DeliveryOrder from 'pages/DeliveryOrder';
import DeliveryOrderDetail from 'pages/DeliveryOrderDetail';
import { ProductCartProvider } from 'contexts/ProductCartContext';
import CourierSalary from 'pages/CourierSalary';
import CourierSalaryCreate from 'pages/CourierSalaryCreate';
import OperationalPage from 'pages/OperationalPage';
import CourierSalaryDetail from 'pages/CourierSalaryDetail';
import ReportPage from 'pages/ReportPage';
import ReportProfitPage from 'pages/ReportProfitPage';
import ReportOrderPage from 'pages/ReportOrderPage';
import useRole from 'hooks/useRole';
import TypeUser from 'typings/enum/TypeUser';

const { REACT_APP_DRAWER_WIDTH = '240' } = process.env;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex'
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  contentMarginSM: {
    marginLeft: '72px'
  },
  contentSpace: {
    [theme.breakpoints.between('md', 'xl')]: {
      marginLeft: +REACT_APP_DRAWER_WIDTH
    }
  }
}));

const App: React.FC = () => {
  const classes = useStyles();

  const [CurrentUserData, setCurrentUserData] = useState<CurrentUser>();

  const { currentUser } = useContext(CurrentUserContext);
  const [isAuthenticating, setAuthenticating] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(true);
  const [openDrawerMobile, setOpenDrawerMobile] = useState<boolean>(false);
  const [carts, setCarts] = useState<CartModel | null>(null);
  const [totalSalesOrder, setTotalSalesOrder] = useState<number>(0);
  const isLoggedIn = isUserAuthenticated(CurrentUserData);

  const isSuperAdmin = useRole({
    type: (CurrentUserData && CurrentUserData.type) || TypeUser.SUPERADMIN,
    allowed: [TypeUser.SUPERADMIN]
  });
  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerOpenMobile = () => {
    setOpenDrawerMobile(true);
  };

  const handleDrawerCloseMobile = () => {
    setOpenDrawerMobile(false);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const setCurrentUser = (currentUser: CurrentUser, token: string): void => {
    localStorage.setItem('token', token);
    attachTokenToHeader(token);
    setCurrentUserData(currentUser);
  };

  const unsetCurrentUser = (): void => {
    localStorage.removeItem('token');
    detachTokenFromHeader();

    setCurrentUserData(undefined);
  };

  useEffect(() => {
    const getPersistedToken = () => {
      return localStorage.getItem('token');
    };

    const getCurrentUserData = async () => {
      setAuthenticating(true);

      const token = getPersistedToken();
      if (token) {
        try {
          const response = await axios.get(GET_CURRENT_USER_URL, { headers: { Authorization: `Bearer ${token}` } });
          const data: CurrentUser = response.data.data;

          setCurrentUser(data, token);
        } catch (err) {
          unsetCurrentUser();
        }
      }
      setAuthenticating(false);
    };
    getCurrentUserData();
  }, []);

  const setCart = (cart: CartModel | null): void => {
    setCarts(cart);
  };

  useEffect(() => {
    if (!CurrentUserData) {
      return;
    }

    if (CurrentUserData.type === 'SALES') {
      return;
    }

    const getPersistedToken = () => {
      return localStorage.getItem('token');
    };

    const notif = async () => {
      const token = getPersistedToken();
      if (token) {
        try {
          const { data } = await axios.get(BADGE_NOTIFICATION_BASE_URL, { headers: { Authorization: `Bearer ${token}` } });
          setTotalSalesOrder(data.data.totalSalesOrder);
        } catch (error) {
          console.log('badge error:', error);
        }
      }
    };

    notif();
  }, [CurrentUserData]);

  return isAuthenticating ? null : (
    <CurrentUserProvider
      value={{
        currentUser: CurrentUserData,
        setCurrentUser,
        unsetCurrentUser
      }}
    >
      <ProductCartProvider>
        <CartProvider
          value={{
            cart: carts,
            setCart
          }}
        >
          <div className={classes.root}>
            {isLoggedIn && (
              <nav>
                <AppHeader
                  open={openDrawer}
                  handleDrawerOpen={handleDrawerOpen}
                  openMobile={openDrawerMobile}
                  handleDrawerOpenMobile={handleDrawerOpenMobile}
                />
                <AppDrawer
                  openDrawer={openDrawer}
                  currentUserData={CurrentUserData}
                  handleDrawerClose={handleDrawerClose}
                  openMobile={openDrawerMobile}
                  handleDrawerCloseMobile={handleDrawerCloseMobile}
                  useNotification={totalSalesOrder === 0}
                  totalNotification={totalSalesOrder}
                />
              </nav>
            )}

            <main className={clsx(classes.content, isLoggedIn && openDrawer && classes.contentSpace, !openDrawer && classes.contentMarginSM)}>
              {isLoggedIn && <div className={classes.appBarSpacer} />}
              <Switch>
                <ConditionalRoute exact={true} path={'/'} routeCondition={!isLoggedIn} component={LoginPage} redirectTo={'/penjualan'} />
                <ConditionalRoute exact={true} path={'/home'} routeCondition={isLoggedIn} component={HomePage} redirectTo={'/'} />
                <ConditionalRoute exact={true} path={'/pengaturan'} routeCondition={isLoggedIn} component={SettingsPage} redirectTo={'/'} />
                <ConditionalRoute exact={true} path={'/users'} routeCondition={isLoggedIn} component={UserPage} redirectTo={'/'} />
                <ConditionalRoute exact={true} path={'/users/detail'} routeCondition={isLoggedIn} component={UserDetailPage} redirectTo={'/'} />
                <ConditionalRoute exact={true} path={'/access'} routeCondition={isLoggedIn} component={AccessPage} redirectTo={'/'} />
                <ConditionalRoute exact={true} path={'/perusahaan'} routeCondition={isLoggedIn} component={CompanyPage} redirectTo={'/'} />
                <ConditionalRoute exact={true} path={'/mitra'} routeCondition={isLoggedIn} component={PartnerPage} redirectTo={'/'} />
                <ConditionalRoute exact={true} path={'/mitra/:id'} routeCondition={isLoggedIn} component={PartnerDetailPage} redirectTo={'/'} />
                <ConditionalRoute exact={true} path={'/produk'} routeCondition={isLoggedIn} component={ProductPage} redirectTo={'/'} />
                <ConditionalRoute exact={true} path={'/produk/:id'} routeCondition={isLoggedIn} component={ProductDetailPage} redirectTo={'/'} />
                <ConditionalRoute exact={true} path={'/kategori'} routeCondition={isLoggedIn} component={CategoryPage} redirectTo={'/'} />
                <ConditionalRoute exact={true} path={'/gudang'} routeCondition={isLoggedIn} component={WareHousePage} redirectTo={'/'} />
                <ConditionalRoute exact={true} path={'/purchase'} routeCondition={isLoggedIn} component={PurchaseOrderPage} redirectTo={'/'} />
                <ConditionalRoute exact={true} path={'/invoice'} routeCondition={isLoggedIn} component={InvoicePage} redirectTo={'/'} />
                <ConditionalRoute exact={true} path={'/invoice/:id'} routeCondition={isLoggedIn} component={InvoiceDetailPage} redirectTo={'/'} />
                <ConditionalRoute exact={true} path={'/invoice/tambah'} routeCondition={isLoggedIn} component={InvoiceCreatePage} redirectTo={'/'} />
                <ConditionalRoute
                  exact={true}
                  path={'/invoice-pembelian'}
                  routeCondition={isLoggedIn}
                  component={PurchaseInvoicePage}
                  redirectTo={'/'}
                />
                <ConditionalRoute
                  exact={true}
                  path={'/invoice-pembelian/detail'}
                  routeCondition={isLoggedIn}
                  component={PurchaseInvoiceDetailPage}
                  redirectTo={'/'}
                />
                <ConditionalRoute
                  exact={true}
                  path={'/invoice-pembelian/tambah'}
                  routeCondition={isLoggedIn}
                  component={PurchaseInvoiceCreatePage}
                  redirectTo={'/'}
                />

                <ConditionalRoute exact={true} path={'/surat-jalan'} routeCondition={isLoggedIn} component={DeliveryOrder} redirectTo={'/'} />

                <ConditionalRoute
                  exact={true}
                  path={'/surat-jalan/:id'}
                  routeCondition={isLoggedIn}
                  component={DeliveryOrderDetail}
                  redirectTo={'/'}
                />

                <ConditionalRoute exact={true} path={'/komisi'} routeCondition={isLoggedIn} component={CourierSalary} redirectTo={'/'} />
                <ConditionalRoute
                  exact={true}
                  path={'/komisi/kalkulasi'}
                  routeCondition={isLoggedIn}
                  component={CourierSalaryCreate}
                  redirectTo={'/'}
                />
                <ConditionalRoute exact={true} path={'/komisi/:id'} routeCondition={isLoggedIn} component={CourierSalaryDetail} redirectTo={'/'} />
                <ConditionalRoute exact={true} path={'/operasional'} routeCondition={isLoggedIn} component={OperationalPage} redirectTo={'/'} />
                <ConditionalRoute exact={true} path={'/laporan'} routeCondition={isLoggedIn} component={ReportPage} redirectTo={'/'} />
                <ConditionalRoute
                  exact={true}
                  path={'/laporan/laporan-laba-rugi'}
                  routeCondition={isLoggedIn}
                  component={ReportProfitPage}
                  redirectTo={'/'}
                />
                <ConditionalRoute
                  exact={true}
                  path={'/laporan/laporan-orderan'}
                  routeCondition={isLoggedIn}
                  component={ReportOrderPage}
                  redirectTo={'/'}
                />

                <ConditionalRoute
                  exact={true}
                  path={'/purchase/tambah'}
                  routeCondition={isLoggedIn}
                  component={PurchaseOrderCreatePage}
                  redirectTo={'/'}
                />

                <ConditionalRoute
                  exact={true}
                  path={'/purchase/edit'}
                  routeCondition={isLoggedIn}
                  component={PurchaseOrderCreatePage}
                  redirectTo={'/'}
                />

                <ConditionalRoute
                  exact={true}
                  path={'/purchase/:id'}
                  routeCondition={isLoggedIn}
                  component={PurchaseOrderDetailPage}
                  redirectTo={'/'}
                />

                <ConditionalRoute exact={true} path={'/semuastok'} routeCondition={isLoggedIn} component={AllStockPage} redirectTo={'/'} />
                <ConditionalRoute exact={true} path={'/penjualan'} routeCondition={isLoggedIn} component={SalesOrderPage} redirectTo={'/'} />
                <ConditionalRoute
                  exact={true}
                  path={'/penjualan/tambah'}
                  routeCondition={isLoggedIn}
                  component={SalesOrderCreatePage}
                  redirectTo={'/'}
                />

                <ConditionalRoute
                  exact={true}
                  path={'/penjualan/:id'}
                  routeCondition={isLoggedIn}
                  component={SalesOrderDetailPage}
                  redirectTo={'/'}
                />
                <ConditionalRoute
                  exact={true}
                  path={'/paket/detail/:id'}
                  routeCondition={isLoggedIn}
                  component={ProductPackageDetailPage}
                  redirectTo={'/'}
                />

                <ConditionalRoute
                  exact={true}
                  path={'/forgotpassword'}
                  routeCondition={!isLoggedIn}
                  component={ForgotPasswordPage}
                  redirectTo={'/mitra'}
                />
                <ConditionalRoute exact={true} path={'/resetpassword'} routeCondition={!isLoggedIn} component={ResetPasswordPage} redirectTo={'/'} />
                <ConditionalRoute exact={true} path={'/appLog'} routeCondition={isLoggedIn} component={AppLogPage} redirectTo={'/'} />
                <ConditionalRoute exact={true} path={'/review-stock'} routeCondition={isLoggedIn} component={ReviewStock} redirectTo={'/'} />
                <ConditionalRoute
                  exact={true}
                  path={'/review-stock/:id'}
                  routeCondition={isLoggedIn}
                  component={ReviewStockDetail}
                  redirectTo={'/'}
                />
                <Route component={NotFoundPage} />
              </Switch>
            </main>
          </div>
        </CartProvider>
      </ProductCartProvider>
    </CurrentUserProvider>
  );
};

export default App;
