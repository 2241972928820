import { Grid, LinearProgress, makeStyles, MenuItem, TextField } from '@material-ui/core';
import React from 'react';
import { IFilter, IReport } from 'pages/ReportOrderPage';
import { Autocomplete } from '@material-ui/lab';
import DateFnsUtils from '@date-io/date-fns';
import { EventRounded } from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

interface Props {
  partners: IReport<PartnerModel>;
  filter: IFilter;
  products: IReport<{ id: number; productName: string }>;
  tab: number;
  handleChangeFilter: <T>(key: string, value: T) => void;
}

const useStyles = makeStyles({
  container: {
    columnGap: '1em'
  },
  box: {
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    boxShadow: ' 0px 4px 24px rgba(50, 50, 50, 0.16)',
    borderRadius: '5px',
    marginTop: '0.5em'
  }
});

const FilterSection = ({ partners, products, filter, handleChangeFilter, tab }: Props): JSX.Element => {
  const classes = useStyles();

  const renderContent = () => {
    if (tab === 0 || tab === 1) {
      return (
        <Grid xs={3}>
          <Autocomplete
            inputValue={filter.partnerName}
            onChange={(event, value, reason) => {
              if (reason === 'clear') {
                handleChangeFilter<number>('partnerId', 0);
                handleChangeFilter<string>('partnerName', '');
              }
              if (value) {
                handleChangeFilter<number>('partnerId', value.id);
                handleChangeFilter<string>('partnerName', value.name);
              }
            }}
            loading={partners.isLoading}
            loadingText={<LinearProgress />}
            disableListWrap
            fullWidth
            getOptionLabel={option => option.name}
            options={partners.data}
            PaperComponent={({ children }) => <Grid className={classes.box}>{children}</Grid>}
            renderInput={params => (
              <TextField
                {...params}
                required
                label={tab === 0 ? 'Pilih Customer' : 'Pilih Mitra'}
                variant='outlined'
                onChange={e => handleChangeFilter<string>('partnerName', e.target.value)}
              />
            )}
          />
        </Grid>
      );
    } else {
      return (
        <>
          <Grid xs={3}>
            <Autocomplete
              onChange={(event, value, reason) => {
                if (reason === 'clear') {
                  handleChangeFilter<number>('productId', 0);
                }
                if (value) {
                  handleChangeFilter<number>('productId', value.id);
                }
              }}
              loading={products.isLoading}
              loadingText={<LinearProgress />}
              disableListWrap
              fullWidth
              getOptionLabel={option => option.productName}
              options={products.data}
              PaperComponent={({ children }) => <Grid className={classes.box}>{children}</Grid>}
              renderInput={params => (
                <TextField
                  {...params}
                  required
                  label='Pilih Produk'
                  variant='outlined'
                  onChange={e => handleChangeFilter<string>('productName', e.target.value)}
                />
              )}
            />
          </Grid>
          <Grid xs={2}>
            <Autocomplete
              value={filter.type === 'order' ? 'Penjualan' : 'Pembelian'}
              onChange={(event, value) => {
                if (value) {
                  handleChangeFilter<string>('type', value === 'Penjualan' ? 'order' : 'purchase');
                }
              }}
              disableListWrap
              fullWidth
              options={['Penjualan', 'Pembelian']}
              PaperComponent={({ children }) => <Grid className={classes.box}>{children}</Grid>}
              renderInput={params => <TextField {...params} required label='Pilih Tipe' variant='outlined' />}
            />
          </Grid>
        </>
      );
    }
  };
  return (
    <Grid xs={12} container className={classes.container} alignItems='center'>
      {renderContent()}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid xs={2}>
          <KeyboardDatePicker
            disableToolbar
            format='dd/MM/yyyy'
            margin='normal'
            required
            fullWidth
            label='Dari'
            cancelLabel='Batal'
            okLabel='Filter'
            value={filter.from}
            onChange={date => {
              if (date) {
                handleChangeFilter<Date>('from', date);
              }
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            keyboardIcon={<EventRounded fontSize='small' />}
          />
        </Grid>
        <Grid xs={2}>
          <KeyboardDatePicker
            disableToolbar
            format='dd/MM/yyyy'
            margin='normal'
            required
            fullWidth
            label='Sampai'
            cancelLabel='Batal'
            okLabel='Filter'
            value={filter.to}
            onChange={date => {
              if (date) {
                handleChangeFilter<Date>('to', date);
              }
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            keyboardIcon={<EventRounded fontSize='small' />}
          />
        </Grid>
      </MuiPickersUtilsProvider>
      <Grid xs={2}>
        <TextField
          select
          value={filter.statusPayment}
          onChange={event => handleChangeFilter<string>('statusPayment', event.target.value)}
          fullWidth
          label='Status Pembayaran'
        >
          <MenuItem selected key={1} value='' disabled>
            Pilih Status Pembayaran
          </MenuItem>
          <MenuItem key={2} value='PAID'>
            Lunas
          </MenuItem>
          <MenuItem key={3} value='UNPAID'>
            Belum Lunas
          </MenuItem>
          <MenuItem key={4} value='CANCEL'>
            Batal
          </MenuItem>
        </TextField>
      </Grid>
    </Grid>
  );
};

export default FilterSection;
