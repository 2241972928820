import React, { FC } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { TableCellMiddle, TableCellEnd, TableCellStart, TableRowCustom } from 'components/Table';
import Skeleton from '@material-ui/lab/Skeleton';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import NewIcon from '@material-ui/icons/FiberNewOutlined';
import { PRIMARY } from 'constants/colors';

interface Props {
  partner: PartnerModel;
  isLoading: boolean;
  isDelete: boolean;
  onDelete: React.MouseEventHandler;
}

const useStyles = makeStyles({
  tableCellAction: {
    width: 30
  },
  newIcon: {
    color: PRIMARY,
    fontSize: 30
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },
  nameTextCell: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 2
  }
});

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const { partner, isLoading, isDelete, onDelete } = props;
  const { new: isNew } = partner;

  const handleViewDetailClick = (id: number) => {
    window.open(`/mitra/${id}`, '_blank');
  };

  return (
    <TableRowCustom>
      <TableCellStart onClick={() => handleViewDetailClick(partner.id)}>
        {isLoading ? <Skeleton variant='text' width={100} height={25} /> : partner.partnerId}
      </TableCellStart>

      <TableCellMiddle onClick={() => handleViewDetailClick(partner.id)}>
        {isLoading ? (
          <Skeleton width={180} />
        ) : (
          <div className={classes.tableCellInner}>
            <div className={classes.nameTextCell}>{partner.name}</div>
            {isNew ||
              (!isNew && (
                <div>
                  <NewIcon className={classes.newIcon} />
                </div>
              ))}
          </div>
        )}
      </TableCellMiddle>

      <TableCellMiddle onClick={() => handleViewDetailClick(partner.id)} align='center'>
        {isLoading ? <Skeleton variant='text' width={100} height={25} /> : partner.partnerType}
      </TableCellMiddle>

      <TableCellMiddle onClick={() => handleViewDetailClick(partner.id)}>
        {isLoading ? <Skeleton variant='text' width={100} height={25} /> : partner.phoneNumber}
      </TableCellMiddle>
      <TableCellEnd align='center' className={classes.tableCellAction}>
        {isLoading ? (
          <Skeleton variant='text' width={100} height={25} />
        ) : (
          <>
            {isDelete && (
              <Tooltip title='Hapus '>
                <IconButton size='small' onClick={onDelete}>
                  <DeleteOutline fontSize='small' />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </TableCellEnd>
    </TableRowCustom>
  );
};

export default BodyRow;
