import React, { FC, Fragment, useEffect, useState, useContext, useCallback, useMemo } from 'react';
import axios from 'axios';
import {
  Grid,
  Container,
  Typography,
  makeStyles,
  Button,
  TextField,
  CircularProgress,
  Theme,
  IconButton,
  Paper,
  InputBase,
  MenuItem,
  Divider,
  useMediaQuery,
  useTheme,
  useScrollTrigger,
  Zoom,
  List,
  ListItem,
  ListItemText,
  Badge,
  InputAdornment
} from '@material-ui/core';
import { Page, StandardConfirmationDialog, PaperCustom, Breadcrumb, CardCustomer, NumberFormatMask } from 'components';
import {
  PARTNER_BASE_URL,
  PRODUCT_SEARCH,
  GET_SALES_ORDER_NUMBER_BASE_URL,
  PRODUCT_BASE_URL,
  CATEGORY_BASE_URL,
  SALES_ORDER_BASE_URL,
  GET_SALES_ORDER_DETAIL_BASE_URL,
  COURIER_URL
} from 'constants/url';
import { createBrowserHistory } from 'history';
import { format } from 'date-fns';
import { dummyPartner, dummyCategory, dummyProduct } from 'utils/dummy';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import useDebounce from 'hooks/useDebounce';
import { Autocomplete, AutocompleteInputChangeReason, Pagination } from '@material-ui/lab';
import useRouter from 'hooks/useRouter';
import Alert from '@material-ui/lab/Alert';
import _ from 'lodash';
import CardItem from './components/CardItem';
import ProductCart from './components/ProductCart';
import Search from '@material-ui/icons/Search';
import { PRIMARY, WHITE, YELLOW } from 'constants/colors';
import { Add, ArrowUpward, CloseRounded, Error, SearchRounded, ShoppingCart } from '@material-ui/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import ImageModal from 'components/ImageModal';
import FlexBox from 'components/FlexBox';
import { useProductCart } from 'contexts/ProductCartContext';
import PartnerType from 'typings/enum/PartnerType';
import { getTotal } from 'utils/getTotal';
import NumberFormat from 'react-number-format';
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    rowGap: '1em'
  },
  cellSummary: {
    border: 'none',
    padding: 5
  },
  cellSummaryRight: {
    border: 'none',
    padding: 5,
    width: '20%'
  },
  editorNotes: {
    marginBottom: '10px'
  },
  productPriview: {
    padding: '1em 0.5em',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    borderRadius: '0.8em',
    minHeight: '20vh',
    transition: 'transform 10s ease-in',
    '&:hover': {
      boxShadow: '0 3px 3px 0 rgba(0, 0, 0, 0.007), 0 4px 7px 0 #BDE9FA',
      transform: 'scale(1,1)'
    }
  },
  tabPanel: {
    [theme.breakpoints.down('md')]: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center'
    }
  },
  containerCenter: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'center'
    }
  },
  search: {
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-end'
    }
  },
  containerSugestSearch: {
    position: 'absolute',
    backgroundColor: '#fff',
    overflowY: 'scroll',
    borderRadius: '0 0 0.5em 0.5em',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    top: 50,
    zIndex: 2,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxHeight: '40vh'
    },
    [theme.breakpoints.up('md')]: {
      width: '98%',
      maxHeight: '30vh'
    }
  },
  textContentMobile: {
    fontSize: '0.875rem'
  },
  CardContainer: {
    margin: '0.6em 0'
  },
  searchingContainer: {
    marginTop: theme.spacing(1),
    padding: '0px 10px',
    display: 'flex',
    alignItems: 'center',
    zIndex: 2,
    border: '1px solid rgba(0, 0, 0, 0.08)',
    borderRadius: '5px',
    [theme.breakpoints.up('md')]: {
      flexGrow: 0.97
    }
  },
  sectionProduct: {
    marginTop: theme.spacing(1),
    maxHeight: '100vh',
    overflowX: 'scroll',
    borderTop: '1px solid rgba(0, 0, 0, 0.08)',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  sectionProductMobile: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignContent: 'center'
  },

  spacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  paginationContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },

  buttonBottom: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      zIndex: 1,
      bottom: 0,
      right: 0,
      backgroundColor: WHITE,
      borderTop: '1px solid rgba(0, 0, 0, 0.08)',
      left: 0,
      padding: '0.6em 0'
    }
  },
  root: {
    minHeight: '30vh'
  },
  iconButton: {
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    backgroundColor: PRIMARY,
    position: 'fixed',
    zIndex: 1,
    bottom: 25,
    right: 10,
    transition: 'all .2s ease-in-out',
    [theme.breakpoints.down('md')]: {
      zIndex: 1,
      bottom: 5,
      right: 16
    },
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: PRIMARY,
        transform: 'scale(1.2)'
      }
    }
  },

  shoppingIcon: {
    color: WHITE
  },
  rootContainer: {
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      marginTop: '-2em'
    }
  },
  hideMobile: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  upButton: {
    position: 'fixed',
    zIndex: 1,
    bottom: 60,
    right: 16,
    backgroundColor: WHITE,
    borderRadius: '2rem',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  category: {
    border: 0,
    backgroundColor: '#FFF'
  },
  upButtonArrow: {
    border: '1px solid rgb(0, 0, 0, 0.12)'
  },
  centerFilter: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '0.3em'
    }
  },
  alertWarning: {
    backgroundColor: YELLOW,
    borderRadius: '.5em',
    padding: '.6em 0'
  },
  containerBox: {
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    boxShadow: '0 2px 5px rgb(0 0 0 / 14%)',
    borderRadius: '5px',
    marginTop: '0.5em'
  }
}));

interface IData<T> {
  isLoading: boolean;
  data: T;
}

const dummyData = {
  isLoading: false,
  data: []
};

const SalesOrderCreatePage: FC = () => {
  const classes = useStyles();
  const { location, history } = useRouter();
  const params: any = location.state;

  const { currentUser } = useContext(CurrentUserContext);
  const {
    productCart,
    productCartInformation,
    handleChangeInformation,
    setProductCart,
    setProductCartInformation,
    deleteProductCartInformation,
    deleteProductCart
  } = useProductCart();
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const theme = useTheme();
  const [loadCategory, setLoadCategory] = useState<boolean>(false);
  const showComponent = useMediaQuery(theme.breakpoints.down('md'));
  const [orderNumber, setOrderNumber] = useState<string>('');
  const [orderDate, setOrderDate] = useState<Date | null>(new Date());
  const [orderDateMessage, setOrderDateMessage] = useState<string>('');
  const [openImage, setOpenImage] = useState<boolean>(false);
  const [imagePath, setImagePath] = useState<string>('');
  const [partner, setPartner] = useState<PartnerModel>(dummyPartner);
  const [partners, setPartners] = useState<PartnerModel[]>([dummyPartner]);

  const [partnerMessage, setPartnerMessage] = useState<string>('');
  const [hasPlafon, setHasPlafon] = useState<boolean>(false);
  const [loadPartner, setLoadPartner] = useState<boolean>(false);
  const [currentSortProduct, setCurrentSortProduct] = useState<string>('id-desc');
  const [currentSortPackage, setCurrentSortPackage] = useState<string>('id-desc');
  const [currentPage, setCurrentPage] = useState<number>(-1);
  const [currentPagePackage, setCurrentPagePackage] = useState<number>(1);
  const [currentPageProduct, setCurrentPageProduct] = useState<number>(1);
  const [currentPageSearch, setCurrentPageSearch] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [to, setTo] = useState<number>(0);
  const [sort, setSort] = useState<string>('');
  const [from, setFrom] = useState<number>(0);
  const [totalProduct, setTotalProduct] = useState<number>(0);
  const [categories, setCategories] = useState<CategoryModel[]>([dummyCategory]);
  const [courierResource, setCourierResource] = useState<IData<{ id: number; firstName: string }[]>>(dummyData);
  const [category, setCategory] = useState<CategoryModel>();
  const [isDeny, setDeny] = useState<boolean>(false);
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [orderNumberLoad, setOrderNumberLoad] = useState<boolean>(false);
  const [hasDebt, setHasDebt] = useState<boolean>(false);
  const [productResource, setProductResource] = useState<ProductModel[]>([]);
  const [productLoading, setProductLoading] = useState<boolean>(false);
  const [pageNumberInfinity, setPageNumberInfinity] = useState<number>(2);
  const [queryString, setQueryString] = useState<string>();
  const [isOpenCart, setIsOpenCart] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [searchTemp, setSearchTemp] = useState('');
  const [keyWordResource, setKeyWordResource] = useState<{ productName: string }[]>([]);
  const [isKeyWord, setIsKeyWord] = useState<boolean>(false);
  const [keyWord, setKeyWord] = useState<string>('');
  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 600
  });

  const validation =
    productCart.length < 1 ||
    productCart.some(value => value.quantity > value.salesStock || value.quantity <= 0) ||
    productCartInformation.PartnerId === 0 ||
    productCartInformation.kurirId === 0 ||
    (productCartInformation.partnerType.includes(PartnerType.RESELLER) ? productCartInformation.resellerCustomerId === 0 : false);

  useEffect(() => {
    createBrowserHistory().replace('/penjualan/tambah', {
      page: currentPageProduct,
      pagePackage: currentPagePackage,
      sortProduct: currentSortProduct,
      sortPackage: currentSortPackage
    });
  }, [currentPageProduct, currentPagePackage, currentSortProduct, currentSortPackage]);

  const handleOpenImage = (imagePath: string) => {
    setOpenImage(true);
    setImagePath(imagePath);
  };
  const handleCloseImage = () => setOpenImage(false);

  const handleChangeCategory = (category: CategoryModel) => {
    setCurrentPageSearch(1);
    setCurrentPagePackage(1);
    setCurrentPageProduct(1);
    setCategory(category);
  };

  const handleSearchPartner = async (value: string) => {
    const params = new URLSearchParams();
    if (value) {
      params.append('keyword', value);
    }

    params.append('partnerType', 'CUSTOMER,RESELLER');

    setLoadPartner(true);
    setPartnerMessage('');
    setHasPlafon(false);
    setDeny(false);
    try {
      const { data } = await axios.get(`${PARTNER_BASE_URL}?${params.toString()}`);
      setPartners(data.data);
      deleteProductCart();
      handleChangeInformation('resellerCustomerName', '');
      handleChangeInformation('resellerCustomerId', 0);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadPartner(false);
    }
  };

  useEffect(() => {
    setProductCartInformation(prev => {
      if (partners.filter((val: PartnerModel) => val.id === productCartInformation.PartnerId).length > 0) {
        return {
          ...prev,
          ['resellerCustomer']: partners.filter((val: PartnerModel) => val.id === productCartInformation.PartnerId)[0].customerReseller || []
        };
      } else {
        return { ...prev, ['resellerCustomer']: [] };
      }
    });
  }, [partners, productCartInformation.PartnerId]);

  const handleOrderNumber = async () => {
    setOrderNumberLoad(true);
    try {
      const { data } = await axios.get(GET_SALES_ORDER_NUMBER_BASE_URL);
      setOrderNumber(data.data.orderNumber);
    } catch (error) {
      console.log('error :', error);
    } finally {
      setOrderNumberLoad(false);
    }
  };

  const handleCloseSnackbar = (): void => {
    setOpenSnackbar(false);
  };

  const handleConfirmSnackbar = (): void => {
    setOpenSnackbar(false);
    params && params.id > 0 ? history.push(`/penjualan/${params.id}`) : history.push(`/penjualan`);
  };

  const handleCancelSearch = () => {
    setKeyWord('');
    setSearch('');
    setSearchTemp('');
    setIsKeyWord(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      setSearch(searchTemp);
      setIsKeyWord(false);
    }
  };

  const handleSearchProduct = () => {
    setSearch(searchTemp);
    setIsKeyWord(false);
  };

  const debouncedSearchTerm = useDebounce(keyWord, 500);

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      dispatchProductSearch();
    }
  }, [debouncedSearchTerm]);

  const dispatchProductSearch = async () => {
    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (keyWord) {
        params.append('keyword', keyWord);
      }

      return params;
    };
    try {
      const { data } = await axios.get(`${PRODUCT_SEARCH}?${getQueryParams()}`);

      data.data.length > 0 && setIsKeyWord(true);

      setKeyWordResource(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchCourier = async () => {
    setCourierResource({ ...courierResource, isLoading: true });
    try {
      const { data } = await axios.get(`${COURIER_URL}`);

      setCourierResource({ ...courierResource, isLoading: false, data: data.data });
    } catch (error) {
      console.log('error :', error);
    }
  };

  const getSalesOrder = useCallback(async () => {
    if (params && params.id > 0) {
      handleOpenCart();
      try {
        const { data } = await axios.get(GET_SALES_ORDER_DETAIL_BASE_URL(Number(params.id)));

        setProductCartInformation(prev => ({ ...prev, ...data.data }));
        const dataSalesOrder = data.data.SalesOrderItem.map((product: SalesOrderItemModel, index: number) => ({
          id: product.id,
          ProductId: product.ProductId,
          quantity: +product.totalItem,
          productName: product.productName,
          Category: product.category,
          salesStock: +product.totalItem + ((product.salesStock && +product.salesStock) || 0),
          customerPrice: product.price,
          resellerPrice: product.price,
          totalPrice: product.totalPrice,
          typeUnit: product.typeUnit
        }));

        setProductCart(dataSalesOrder);
      } catch (error) {
        console.log('error:', error);
      }
    }
  }, [params]);

  useEffect(() => {
    getSalesOrder();
  }, [getSalesOrder]);

  const handleDispatchProduct = useCallback(async () => {
    setProductLoading(true);

    const getQueryParams = () => {
      const params = new URLSearchParams();
      params.append('route', (currentUser && currentUser.SalesRoute ? currentUser.SalesRoute.map(value => value.ZoneId) : []).join(','));
      if (search) {
        params.append('keyword', search);
      }

      if (category && category.id > 0) {
        params.append('CategoryId', String(category.id));
      }

      const [orderby, order] = currentSortProduct.split('-');
      params.append('orderBy', orderby);
      params.append('ordered', order);

      params.append('page', String(currentPageSearch));

      setSort(currentSortProduct);
      return params.toString();
    };
    try {
      const { data } = await axios.get(`${PRODUCT_BASE_URL}?${getQueryParams()}`);

      setProductResource(data.data);
      setCount(data.meta.last_page);
      setTotal(data.meta.total);
      setCurrentPage(data.meta.current_page);
      setTotalProduct(data.meta.total);
      setTo(data.meta.to);
      setFrom(data.meta.from);
    } catch (error) {
      console.log(error);
    } finally {
      setProductLoading(false);
    }
  }, [queryString, category, currentPageProduct, currentPagePackage, currentSortProduct, currentSortPackage, currentPageSearch, search]);

  const fetchMoreData = async () => {
    setPageNumberInfinity(pageNumberInfinity + 1);
    const params = new URLSearchParams();

    if (search) {
      params.append('keyword', search);
    }
    if (category && category.id > 0) {
      params.append('CategoryId', String(category.id));
    }

    const [orderby, order] = currentSortProduct.split('-');
    params.append('orderBy', orderby);
    params.append('ordered', order);

    params.append('page', pageNumberInfinity.toString());

    try {
      const { data } = await axios.get(`${PRODUCT_BASE_URL}?${params.toString()}`);
      setProductResource(prevData => [...prevData, ...data.data]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string): void => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleSubmit = async () => {
    setSubmit(true);

    const items = productCart.map(value => ({
      id: value.id,
      ProductId: value.ProductId,
      typeUnit: value.typeUnit,
      totalItem: value.quantity,
      price: productCartInformation.partnerType.includes(PartnerType.RESELLER) ? value.resellerPrice.toFixed() : value.customerPrice.toFixed(),
      upPrice: false,
      discountUnit: 0
    }));
    const getProductCartInformation = {
      ...productCartInformation,
      items,
      orderDate
    };

    try {
      if (params && params.id > 0) {
        await axios.put(SALES_ORDER_BASE_URL, getProductCartInformation);
      } else {
        await axios.post(SALES_ORDER_BASE_URL, getProductCartInformation);
      }

      handleSnackBar(true, 'success', params && params.id > 0 ? 'Sales order berhasil di Update' : 'Orderan berhasil dibuat');
      deleteProductCart();
      deleteProductCartInformation();
    } catch (error) {
      const err = error as { data: any };
      handleSnackBar(true, 'error', err.data && err.data.message);
    } finally {
      setSubmit(false);
    }
  };
  const handleOpenCart = async () => setIsOpenCart(true);

  useEffect(() => {
    handleOrderNumber();
  }, []);

  useEffect(() => {
    if (partner.id < 1) return;

    setHasDebt(partner.hasDebt || false);

    const totalBill = partner.totalBill && partner.totalBill > 0 ? partner.totalBill : 0;
    const plafon = partner.plafon || 0;

    if (totalBill > plafon && plafon > 0) {
      setPartnerMessage('Customer ini memiliki jumlah total tagihan belum terbayarkan.');
      setHasPlafon(true);
      setDeny(true);
    }

    setLoadPartner(false);
  }, [partner]);

  useEffect(() => {
    handleDispatchProduct();
  }, [handleDispatchProduct]);

  const handleBackTop = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector('#back-to-top');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const handleSearchCategory = async (value: string) => {
    const params = new URLSearchParams();
    params.append('product', 'true');
    params.append('keyword', value);
    setLoadCategory(true);
    try {
      const { data } = await axios.get(`${CATEGORY_BASE_URL}?${params.toString()}`);
      setCategories(data.data);
      setLoadCategory(false);
    } catch (error) {
      console.log('error :', error);
      setLoadCategory(false);
    }
  };

  useEffect(() => {
    handleSearchCourier();
  }, []);

  return (
    <Page title='Penjualan'>
      <Container className={classes.rootContainer}>
        <Grid container direction='row' className={classes.hideMobile}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'> Penjualan </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Breadcrumb />
          </Grid>
        </Grid>

        <PaperCustom>
          <ImageModal open={openImage} handleClose={handleCloseImage} imageUrl={imagePath} />
          {productCartInformation.isPlafon && (
            <Grid item xs={12} className={classes.alertWarning} container alignItems='center'>
              <Grid xs={1} container justify='center'>
                <Error color='error' fontSize='large' />
              </Grid>
              <Grid xs={11}>
                <Typography variant='h6'>Melebihi Batas Plafon</Typography>
                <Typography>
                  Pembelanjaan customer ini sudah melebihi batas plafon yang sudah ditentukan. Hubungi customer agar dapat melunasi sisa tagihan yang
                  ada
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container direction='row' spacing={2}>
            <Grid item xl={12} md={12} sm={12}>
              {hasDebt && (
                <Alert variant='filled' severity='warning'>
                  Mitra ini tidak bisa membuat SO, karena memeliki utang.
                </Alert>
              )}
            </Grid>
            <Grid item xl={6} lg={6} sm={12} xs={12} md={6}>
              <TextField
                id='orderNumber'
                required
                fullWidth
                label='No Order'
                placeholder='No Order Penjualan'
                value={orderNumber}
                onChange={e => setOrderNumber(e.target.value)}
                InputProps={{
                  endAdornment: orderNumberLoad && <CircularProgress color='inherit' size={20} />
                }}
                disabled
              />
            </Grid>

            <Grid item xl={6} lg={6} sm={12} xs={12} md={6}>
              <FlexBox columnGap={1}>
                <Grid xs={6}>
                  <Autocomplete
                    id='partner'
                    options={partners}
                    inputValue={productCartInformation.partnerName}
                    getOptionLabel={option => option.name}
                    getOptionSelected={(option, value) => {
                      return value.id === option.id;
                    }}
                    onChange={(event: any, value: any, reason: any) => {
                      if (reason === 'clear') {
                        handleChangeInformation('partnerName', '');
                        handleChangeInformation('PartnerId', 0);
                        handleChangeInformation('isPlafon', false);
                        handleChangeInformation('partnerType', PartnerType.CUSTOMER);
                      } else if (value) {
                        handleChangeInformation('partnerName', value.name);
                        handleChangeInformation('PartnerId', value.id);
                        handleChangeInformation('isPlafon', value.isPlafon);
                        handleChangeInformation('partnerType', value.partnerType);
                      }
                    }}
                    onOpen={e => handleSearchPartner('')}
                    onInputChange={(event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
                      if (reason === 'clear') {
                        setPartner(dummyPartner);
                        setHasDebt(false);
                      }
                    }}
                    loading={loadPartner}
                    renderOption={(option, state) => (
                      <CardCustomer partner={option} isLoading={loadPartner} isSelected={option.id === partner.id && !loadPartner} />
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        label='Pilih Mitra'
                        onChange={e => {
                          handleSearchPartner(e.target.value);
                          handleChangeInformation('PartnerId', 0);
                          handleChangeInformation('resellerCustomerId', 0);
                          handleChangeInformation('partnerName', e.target.value);
                          handleChangeInformation('resellerCustomerName', '');
                        }}
                        error={partnerMessage !== ''}
                        helperText={partnerMessage}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <Fragment>
                              {loadPartner && <CircularProgress color='inherit' size={20} />}
                              {params.InputProps.endAdornment}
                            </Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={6}>
                  <TextField
                    id='partnerType'
                    required
                    fullWidth
                    label='Tipe Mitra'
                    value={productCartInformation.partnerType}
                    disabled
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </FlexBox>
            </Grid>
            <Grid item xl={6} lg={6} sm={12} xs={12} md={6}>
              <TextField
                id='orderDate'
                required
                fullWidth
                type='date'
                label='Tanggal Order'
                placeholder='Tanggal Terbit Order Penjualan'
                value={orderDate && format(orderDate, 'yyyy-MM-dd')}
                onChange={e => setOrderDate(new Date(e.target.value))}
                error={orderDateMessage !== ''}
                helperText={orderDateMessage}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xl={6} lg={6} sm={12} xs={12} md={6}>
              <FlexBox columnGap={1}>
                {productCartInformation.partnerType && productCartInformation.partnerType.includes(PartnerType.RESELLER) && (
                  <Grid xs={6}>
                    <Autocomplete
                      id='partner'
                      options={productCartInformation.resellerCustomer}
                      inputValue={productCartInformation.resellerCustomerName}
                      getOptionLabel={option => option.name}
                      getOptionSelected={(option, value) => {
                        return value.id === option.id;
                      }}
                      onChange={(event: any, value: any) => {
                        if (value) {
                          handleChangeInformation('resellerCustomerName', value.name);
                          handleChangeInformation('resellerCustomerId', value.id);
                        }
                      }}
                      renderOption={(option, state) => (
                        <CardCustomer partner={option} isLoading={loadPartner} isSelected={option.id === productCartInformation.resellerCustomerId} />
                      )}
                      renderInput={params => (
                        <TextField
                          {...params}
                          required
                          label='Pilih Customer'
                          onChange={e => {
                            handleChangeInformation('resellerCustomerName', e.target.value);
                            handleChangeInformation('resellerCustomerId', 0);
                          }}
                          error={partnerMessage !== ''}
                          helperText={partnerMessage}
                        />
                      )}
                    />
                  </Grid>
                )}

                <Grid xs={productCartInformation.partnerType ? (productCartInformation.partnerType.includes(PartnerType.RESELLER) ? 6 : 12) : 12}>
                  <Autocomplete
                    id='kurirId'
                    options={courierResource.data}
                    inputValue={productCartInformation.courierName}
                    getOptionLabel={option => option.firstName}
                    getOptionSelected={(option, value) => {
                      return value.id === option.id;
                    }}
                    onChange={(event: any, value: any, reason: any) => {
                      if (reason === 'clear') {
                        handleChangeInformation('courierName', '');
                        handleChangeInformation('kurirId', 0);
                      } else if (value) {
                        handleChangeInformation('courierName', value.firstName);
                        handleChangeInformation('kurirId', value.id);
                      }
                    }}
                    PaperComponent={({ children }) => <Grid className={classes.containerBox}>{children}</Grid>}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        label='Pilih Kurir'
                        onChange={e => {
                          handleChangeInformation('courierName', e.target.value);
                          handleChangeInformation('kurirId', 0);
                        }}
                        error={partnerMessage !== ''}
                        helperText={partnerMessage}
                      />
                    )}
                  />
                </Grid>
              </FlexBox>
            </Grid>

            {!isOpenCart ? (
              <Grid xs={12}>
                <Grid xs={12}>
                  <Typography variant='body2'>Semua Produk</Typography>

                  <Grid xs={12} container alignItems='center' className={classes.containerCenter}>
                    <Grid xs={12} lg={5} xl={5} container>
                      <Grid lg={6} xl={6} xs={6} sm={6} md={6} container className={classes.centerFilter}>
                        <Grid style={{ flex: 0.96 }}>
                          <Autocomplete
                            id='category'
                            fullWidth
                            options={categories}
                            getOptionLabel={option => option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event: any, value: any) => value && handleChangeCategory(value)}
                            onOpen={() => handleSearchCategory('')}
                            onInputChange={(event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
                              if (reason === 'clear') {
                                setCategory(dummyCategory);
                                setCategories([dummyCategory]);
                              }
                            }}
                            loading={loadCategory}
                            renderInput={params => (
                              <TextField
                                {...params}
                                fullWidth
                                label='Kategori'
                                onChange={e => handleSearchCategory(e.target.value)}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <Fragment>
                                      {loadCategory && <CircularProgress color='inherit' size={20} />}
                                      {params.InputProps.endAdornment}
                                    </Fragment>
                                  )
                                }}
                                className={classes.category}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid lg={6} xl={6} xs={6} sm={6} md={6} container justify='flex-end' className={classes.centerFilter}>
                        <Grid style={{ flex: 0.96 }}>
                          <TextField
                            fullWidth
                            InputProps={{
                              startAdornment: 'Urutkan: '
                            }}
                            value={sort}
                            onChange={e => setCurrentSortProduct(e.target.value as string)}
                            select
                          >
                            <MenuItem key={1} value='productName-asc'>
                              A ke Z
                            </MenuItem>
                            <MenuItem key={3} value='id-desc'>
                              Terbaru
                            </MenuItem>
                            <MenuItem key={3} value='restock-desc'>
                              Re-Stock
                            </MenuItem>
                          </TextField>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid lg={7} xl={7} xs={12} md={12} sm={12} className={classes.search}>
                      <Paper className={classes.searchingContainer}>
                        <InputBase
                          classes={{
                            input: classes.textContentMobile
                          }}
                          style={{ flex: 1 }}
                          placeholder='Cari nama produk'
                          inputProps={{ 'aria-label': 'search' }}
                          value={searchTemp}
                          onKeyDown={handleKeyDown}
                          onChange={e => {
                            setSearchTemp(e.target.value);
                            setKeyWord(e.target.value);
                          }}
                        />
                        {searchTemp !== '' && (
                          <IconButton type='submit' aria-label='search' onClick={() => handleCancelSearch()}>
                            <CloseRounded fontSize='small' />
                          </IconButton>
                        )}
                        <IconButton type='submit' aria-label='search' onClick={() => handleSearchProduct()} disabled={searchTemp === ''}>
                          <Search fontSize='small' />
                        </IconButton>
                      </Paper>

                      {isKeyWord && keyWordResource.length > 0 && (
                        <Grid className={classes.containerSugestSearch}>
                          <List>
                            {keyWordResource.map((value, key) => (
                              <ListItem
                                button
                                key={key}
                                onClick={() => {
                                  handleSearchProduct();
                                  setIsKeyWord(false);
                                  setSearchTemp(value.productName);
                                  setSearch(value.productName);
                                }}
                              >
                                <SearchRounded style={{ paddingRight: '0.3em', color: 'grey' }} fontSize='default' />
                                <ListItemText primary={value.productName} />
                              </ListItem>
                            ))}
                          </List>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid xs={12} className={classes.paginationContainer} container justify='space-between'>
                    <Typography>
                      {`Menampilkan ${totalProduct} produk ${category && category.id > 0 ? `untuk "${category.name}"` : ''}  (${from || '0'} - ${to ||
                        '0'} dari ${totalProduct})`}
                    </Typography>

                    {productResource.length > 0 && (
                      <Pagination
                        count={count}
                        boundaryCount={2}
                        variant='outlined'
                        shape='rounded'
                        onChange={(event, page) => {
                          if (queryString !== '') {
                            setCurrentPageSearch(page);
                            setCurrentPageProduct(page);
                          }
                        }}
                        page={currentPage === -1 ? 1 : currentPage}
                        disabled={productLoading}
                      />
                    )}
                  </Grid>
                  <Grid xs={12} className={classes.sectionProduct} container>
                    {productLoading
                      ? [0, 1, 2, 3, 4, 5, 6, 7].map(key => (
                          <Grid xs={6} md={6} sm={6} xl={3} lg={3} className={classes.CardContainer}>
                            <CardItem
                              handleOpenImage={() => console.log('')}
                              productLoading={productLoading}
                              productResource={dummyProduct}
                              isUpdate={false}
                            />
                          </Grid>
                        ))
                      : productResource.map((val, key: number) => (
                          <Grid xs={6} md={6} sm={6} xl={3} lg={3} className={classes.CardContainer}>
                            <CardItem
                              handleOpenImage={handleOpenImage}
                              productLoading={productLoading}
                              productResource={val}
                              isUpdate={params && params.id > 0 ? true : false}
                            />
                          </Grid>
                        ))}
                  </Grid>
                  {showComponent && (
                    <>
                      <Grid id='back-to-top' />
                      <InfiniteScroll
                        dataLength={productResource.length}
                        next={() => fetchMoreData()}
                        hasMore={pageNumberInfinity <= count}
                        loader={
                          <Typography align='center'>
                            <CircularProgress color='primary' size={30} style={{ margin: '2em 0' }} />
                          </Typography>
                        }
                      >
                        <Grid className={classes.sectionProductMobile}>
                          {productLoading
                            ? [0, 1, 2, 3].map(key => (
                                <Grid style={{ width: '43vw' }} className={classes.CardContainer}>
                                  <CardItem
                                    handleOpenImage={() => console.log('')}
                                    productLoading={productLoading}
                                    productResource={dummyProduct}
                                    isUpdate={false}
                                  />
                                </Grid>
                              ))
                            : productResource.map((val, key: number) => (
                                <Grid style={{ width: '43vw' }} className={classes.CardContainer} key={key}>
                                  <CardItem
                                    handleOpenImage={handleOpenImage}
                                    productLoading={productLoading}
                                    productResource={val}
                                    isUpdate={params && params.id > 0 ? true : false}
                                  />
                                </Grid>
                              ))}
                        </Grid>
                      </InfiniteScroll>
                      <Zoom in={trigger}>
                        <Grid className={classes.upButton}>
                          <IconButton color='primary' aria-label='up icon' component='span' className={classes.upButtonArrow} onClick={handleBackTop}>
                            <ArrowUpward />
                          </IconButton>
                        </Grid>
                      </Zoom>
                    </>
                  )}
                </Grid>

                <IconButton aria-label='cart' size='medium' onClick={handleOpenCart} className={classes.iconButton}>
                  <Badge badgeContent={productCart.length} color='error'>
                    <ShoppingCart className={classes.shoppingIcon} fontSize='large' />
                  </Badge>
                </IconButton>
              </Grid>
            ) : (
              <Grid xs={12} container className={classes.container}>
                <Grid xs={12}>
                  <Divider />
                </Grid>
                <Grid xs={12} justify='space-between' container>
                  <Typography variant='h6'>Semua Produk di Keranjang</Typography>
                  <Button startIcon={<Add />} onClick={() => setIsOpenCart(false)} variant='text'>
                    Tambah Produk Lainnya
                  </Button>
                </Grid>

                <Grid xs={12} className={classes.root}>
                  <ProductCart />
                </Grid>

                <Grid xs={12} container alignItems='flex-start'>
                  <Grid xs={6} container>
                    <FlexBox columnGap={1} alignItems='center'>
                      <Typography>Catatan</Typography>
                      <Grid xs={7}>
                        <TextField
                          id='notes'
                          value={productCartInformation.notes}
                          multiline
                          rows={4}
                          variant='outlined'
                          fullWidth
                          onChange={event => handleChangeInformation('notes', event.target.value)}
                        />
                      </Grid>
                    </FlexBox>
                  </Grid>
                  <Grid xs={6} container justify='flex-end'>
                    <Grid xs={7} container>
                      <Grid xs={12} container alignItems='center' justify='space-between'>
                        <Grid xs={5}>
                          <Typography>Subtotal</Typography>
                        </Grid>
                        <Grid xs={5}>
                          <Typography align='right'>
                            <NumberFormat
                              value={productCartInformation.subTotal.toFixed()}
                              prefix={'Rp'}
                              thousandSeparator={true}
                              displayType='text'
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid xs={12} container alignItems='center' justify='space-between'>
                        <Grid xs={5}>
                          <Typography>Input Ongkos Kirim</Typography>
                        </Grid>
                        <Grid xs={5}>
                          <TextField
                            id='shippingFee'
                            required
                            fullWidth
                            error={productCartInformation.totalPrice !== 0 && productCartInformation.shippingFee >= productCartInformation.totalPrice}
                            size='small'
                            InputProps={{
                              startAdornment: <InputAdornment position='start'>Rp</InputAdornment>
                            }}
                            value={
                              Number.isNaN(productCartInformation.shippingFee)
                                ? 0
                                : new Intl.NumberFormat('id-ID').format(productCartInformation.shippingFee)
                            }
                            onChange={e => {
                              if (e.target.value === '' && Number.isNaN(productCartInformation.shippingFee)) {
                                handleChangeInformation('shippingFee', 0);
                              } else {
                                handleChangeInformation(
                                  'shippingFee',
                                  Number(
                                    e.target.value
                                      .split('.')
                                      .join('')
                                      .replace(/\,/g, '')
                                  )
                                );
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid xs={12} container alignItems='center' justify='space-between'>
                        <Grid xs={5}>
                          <Typography>Input Total Diskon</Typography>
                        </Grid>
                        <Grid xs={5}>
                          <TextField
                            id='totalDiscount'
                            required
                            fullWidth
                            error={productCartInformation.subTotal !== 0 && productCartInformation.totalDiscount >= productCartInformation.subTotal}
                            size='small'
                            InputProps={{
                              startAdornment: <InputAdornment position='start'>Rp</InputAdornment>
                            }}
                            value={
                              Number.isNaN(productCartInformation.totalDiscount)
                                ? 0
                                : new Intl.NumberFormat('id-ID').format(productCartInformation.totalDiscount)
                            }
                            onChange={e => {
                              if (e.target.value === '' && Number.isNaN(productCartInformation.totalDiscount)) {
                                handleChangeInformation('totalDiscount', 0);
                              } else {
                                handleChangeInformation(
                                  'totalDiscount',
                                  Number(
                                    e.target.value
                                      .split('.')
                                      .join('')
                                      .replace(/\,/g, '')
                                  )
                                );
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid xs={12} container alignItems='center' justify='space-between'>
                        <Grid xs={5}>
                          <Typography>Potongan Lainnya</Typography>
                        </Grid>
                        <Grid xs={5}>
                          <TextField
                            id='total'
                            required
                            fullWidth
                            error={productCartInformation.totalPrice !== 0 && productCartInformation.deduction >= productCartInformation.totalPrice}
                            size='small'
                            InputProps={{
                              startAdornment: <InputAdornment position='start'>Rp</InputAdornment>
                            }}
                            value={
                              Number.isNaN(productCartInformation.deduction)
                                ? 0
                                : new Intl.NumberFormat('id-ID').format(productCartInformation.deduction)
                            }
                            onChange={e => {
                              if (e.target.value === '' && Number.isNaN(productCartInformation.deduction)) {
                                handleChangeInformation('deduction', 0);
                              } else {
                                handleChangeInformation(
                                  'deduction',
                                  Number(
                                    e.target.value
                                      .split('.')
                                      .join('')
                                      .replace(/\,/g, '')
                                  )
                                );
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid xs={12} container alignItems='center' justify='space-between'>
                        <Grid xs={5}>
                          <Typography variant='h6'>Total Nominal</Typography>
                        </Grid>
                        <Grid xs={5}>
                          <Typography align='right' variant='h6'>
                            <NumberFormat
                              value={productCartInformation.totalPrice.toFixed()}
                              prefix={'Rp'}
                              thousandSeparator={true}
                              displayType='text'
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid xs={12} container justify='center' className={classes.buttonBottom}>
                  <Grid xs={11} sm={11} md={11} lg={12} xl={12}>
                    <Button
                      variant='contained'
                      fullWidth
                      color='primary'
                      style={{ borderRadius: '20px' }}
                      onClick={handleSubmit}
                      disabled={validation || isSubmit}
                    >
                      {isSubmit ? <CircularProgress size={20} color='primary' /> : 'Buat Order Penjualan'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <StandardConfirmationDialog
              variant={snackbarVariant}
              titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
              message={message}
              open={openSnackbar}
              handleClose={snackbarVariant === 'success' ? handleConfirmSnackbar : handleCloseSnackbar}
              onConfirm={handleConfirmSnackbar}
              noCancelButton={true}
            />
          </Grid>
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default SalesOrderCreatePage;
