import React, { FC, Fragment, useContext, useState, ComponentType } from 'react';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { ListItem, ListItemIcon, ListItemText, Tooltip, Theme, Badge, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import List from '@material-ui/core/List';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { WHITE } from 'constants/colors';
import IconMdi from '@mdi/react';
import { LOGOUT_URL } from 'constants/url';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import axios from 'axios';
import useRouter from 'hooks/useRouter';
import StandardConfirmationDialog from '../../StandardConfirmationDialog';
import { RED } from 'constants/colors';
import useRole from 'hooks/useRole';
import TypeUser from 'typings/enum/TypeUser';
interface Props {
  Icon?: ComponentType<SvgIconProps>;
  path: string;
  iconMdi?: string;
  label: string;
  child?: boolean;
  childData?: ChildDrawerMenuModel[];
  useNotification?: boolean;
  totalNotification?: number;
  totalRequestEdit?: number;
  totalNewEdit?: number;
  handleDrawerClose(): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  colorDrawer: {
    color: WHITE,
    fontSize: '20px'
  },
  textDrawer: {
    fontSize: 40
  },
  nested: {
    paddingLeft: theme.spacing(8)
  },
  chip: {
    backgroundColor: RED,
    color: '#fff'
  }
}));

const DrawerItem: FC<Props> = props => {
  const { history } = useRouter();
  const classes = useStyles();
  const { unsetCurrentUser, currentUser } = useContext(CurrentUserContext);

  const { Icon, path, label, iconMdi, child, childData, handleDrawerClose, useNotification, totalNotification } = props;

  const [open, setOpen] = useState(false);
  const [openLogout, setLogout] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onClickHandler = (pathChild?: string): React.MouseEventHandler => async () => {
    if (path === 'logout') {
      setLogout(true);
    } else if (path === '#') {
      setOpen(!open);
      if (pathChild) {
        setOpen(true);
        history.push(pathChild);
        handleDrawerClose();
      }
    } else {
      history.push(path);
      handleDrawerClose();
    }
  };

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      await axios.post(LOGOUT_URL);
    } catch (err) {
      console.log(err);
    }
    setLogout(false);
    unsetCurrentUser();
    history.push('/');
    handleDrawerClose();
  };

  return (
    <>
      <Tooltip title={label} placement='right'>
        <List component='nav' aria-labelledby='nested-list-subheader'>
          <ListItem button onClick={onClickHandler()}>
            <ListItemIcon>
              {Icon ? (
                <Fragment>
                  <Badge invisible={useNotification} badgeContent={totalNotification} color='error'>
                    <Icon className={classes.colorDrawer} />
                  </Badge>
                </Fragment>
              ) : (
                <Fragment>
                  <IconMdi path={iconMdi ? iconMdi : ''} size={1} color={WHITE} />
                </Fragment>
              )}
            </ListItemIcon>
            <ListItemText primary={label} />
            {child && (open ? <ExpandLess /> : <ExpandMore />)}
          </ListItem>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              {childData &&
                childData.map((value, index) => (
                  <ListItem key={index} button className={classes.nested}>
                    <ListItemText primary={value.name} onClick={onClickHandler(value.path)} />
                  </ListItem>
                ))}
            </List>
          </Collapse>
        </List>
      </Tooltip>
      <StandardConfirmationDialog
        variant={'logout'}
        titleMessage={'Log Out'}
        message={'Apakah yakin anda ingin keluar ?'}
        open={openLogout}
        isLoading={isLoading}
        handleClose={() => setLogout(false)}
        onConfirm={handleLogout}
      />
    </>
  );
};

export default DrawerItem;
