import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { GREY } from 'constants/colors';
import React, { FC, memo, useMemo } from 'react';
interface Props {
  isLoading: boolean;
  deliveryOrderItem: DeliveryOrderItem[];
}
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    rowGap: '1em'
  },
  containerHeader: {
    padding: '0 1em'
  },
  containerBody: {
    rowGap: '1em'
  },
  containerItem: {
    rowGap: '1em',
    borderTop: '1px solid rgba(45, 55, 72, 0.12)',
    padding: '1em 0',
    marginTop: '1em'
  },
  collapseIcon: {
    cursor: 'pointer'
  },
  wrapBody: {
    padding: '1em',
    border: `1px solid ${GREY}`,
    borderRadius: '0.5em'
  },
  expandAnimation: {
    animation: `$expandEffect 300ms linear`
  },
  '@keyframes expandEffect': {
    '0%': {
      transform: 'scaleX(0)'
    },
    '50%': {
      transform: 'scaleX(0.5)'
    },
    '100%': {
      transform: 'scaleX(1)'
    }
  }
}));
const DeliveryOrderList: FC<Props> = ({ isLoading, deliveryOrderItem }) => {
  const classes = useStyles();

  const tableHeaders = useMemo(
    () =>
      [
        { label: 'NO', width: 3 },
        { label: 'NAMA PRODUK', width: 3 },
        { label: 'TOTAL QUANTITY', width: 3 }
      ] as const,
    []
  );
  return (
    <Grid xs={12} container className={classes.container}>
      <Grid xs={12} container className={classes.containerHeader}>
        {tableHeaders.map(tableHader => (
          <Grid xs={tableHader.width}>
            <Typography color='textSecondary'>{tableHader.label}</Typography>
          </Grid>
        ))}
      </Grid>
      <Grid xs={12} container className={classes.containerBody}>
        {isLoading ? (
          <Grid xs={12}>
            {[...Array(5)].map(val => (
              <Skeleton height={70} key={val} />
            ))}
          </Grid>
        ) : (
          deliveryOrderItem.map((value, index) => (
            <Grid xs={12} className={classes.wrapBody} container key={index} alignItems='center'>
              <Grid xs={12} container>
                <Grid xs={3} container alignItems='center'>
                  <Typography>{index + 1}</Typography>
                </Grid>
                <Grid xs={3}>
                  <Typography>{value.packName}</Typography>
                </Grid>
                <Grid xs={6}>
                  <Typography>{value.name}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ))
        )}
      </Grid>
    </Grid>
  );
};

export default memo(DeliveryOrderList);
