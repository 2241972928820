import React, { FC, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  makeStyles,
  Theme,
  Typography,
  Tabs,
  Tab
} from '@material-ui/core';
import { DialogTitle } from 'components/Dialog';
import { TabPanel, a11yProps } from 'components';
import NumberFormat from 'react-number-format';
import { Close } from '@material-ui/icons';
import { WHITE } from 'constants/colors';

import useDebounced from 'hooks/useDebounced';
import { INVOICE_BASE_URL, PARTNER_DETAIL_BASE_URL } from 'constants/url';
import axios from 'axios';
import PaymentPage from 'pages/InvoiceDetailPage/components/PartnerModal/PaymentPage';
import { dummyPartner } from 'utils/dummy';
import InvoiceTable from 'pages/PartnerDetailPage/components/InvoiceTable';

interface Props {
  partners: SalesOrderModel;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onCancel: React.MouseEventHandler;
}

const useStyles = makeStyles((theme: Theme) => ({
  controlDiv: {
    '& > :nth-child(n+2)': {
      marginLeft: theme.spacing(2)
    }
  },
  tab: {
    textTransform: 'none'
  },
  cancelButton: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(3)
  },
  box: {
    backgroundColor: WHITE,
    borderRadius: '5px',
    padding: 24,
    border: '1px solid #F2F2F2',
    boxShadow: '0px 5px 12px rgba(0, 0, 0, 0.08)',
    marginBottom: '10px'
  },
  boxTitle: {
    marginBottom: 8
  }
}));

const PartnerModal: FC<Props> = props => {
  const classes = useStyles();
  const { partners, open, setOpen } = props;
  const [tabs, setTabs] = useState<number>(0);
  const [statusPayment, setStatusPayment] = useState<string>('');
  const [invoiceId, setInvoiceId] = useState<string>('');
  const [loadInvoice, setLoadInvoice] = useState<boolean>(false);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [count, setCount] = useState<number>(0);
  const [invoices, setInvoices] = useState<InvoiceModel[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [partner, setPartner] = useState<PartnerModel>(dummyPartner);
  const [indexCollapse, setIndexCollapse] = useState<number>(-1);
  const tokenSource = axios.CancelToken.source();
  const handleChangeTabs = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabs(newValue);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getPartner = async () => {
    try {
      const { data } = await axios.get(PARTNER_DETAIL_BASE_URL(partners.PartnerId));
      setPartner(data.data);
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleOpenCollapse = (index: number, id: number): React.MouseEventHandler => () => {
    setIndexCollapse(index);
    setOpenCollapse(openCollapse ? (index === indexCollapse ? false : true) : true);
    getData(id);
  };

  const getData = async (id: number) => {
    try {
      const { data } = await axios.get(`${INVOICE_BASE_URL}/${id}`);
      setInvoices(prevState =>
        prevState.map(value => {
          if (value.id === id) {
            value.InvoiceItem = data.data.InvoiceItem;
          }
          return value;
        })
      );
    } catch (err) {
      console.log('err', err);
    }
  };
  const fetchInvoice = async () => {
    const getQueryParams = () => {
      const param = new URLSearchParams();

      if (invoiceId) {
        param.append('number', invoiceId);
      }

      if (statusPayment) {
        param.append('status', statusPayment);
      }

      param.append('orderBy', orderBy);
      param.append('ordered', order);
      param.append('page', String(currentPage));
      param.append('partnerId', String(partners.PartnerId));
      return param.toString();
    };

    setLoadInvoice(true);
    try {
      const { data } = await axios.get(`${INVOICE_BASE_URL}?${getQueryParams()}`, {
        cancelToken: tokenSource.token
      });
      setInvoices(data.data);
      setCount(data.meta.last_page);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoadInvoice(false);
    }
  };

  const debounceHits = useDebounced(fetchInvoice, 500);

  useEffect(() => {
    if (partners.PartnerId) {
      getPartner();
    }
  }, [open]);

  useEffect(() => {
    if (partners.PartnerId) {
      debounceHits();
    }
    return () => tokenSource.cancel('query is cancelled');
  }, [invoiceId, statusPayment, orderBy, currentPage, partners]);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title' fullWidth maxWidth='md'>
      <DialogTitle>
        <Grid xs={12} container justify='space-between' alignItems='center'>
          <Typography variant='h5'>Info Customer</Typography>
          <Close onClick={() => setOpen(false)} style={{ cursor: 'pointer' }} />
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid xs={12}>
          <Tabs
            value={tabs}
            onChange={handleChangeTabs}
            indicatorColor='primary'
            textColor='primary'
            variant='scrollable'
            scrollButtons='auto'
            aria-label='scrollable auto tabs example'
          >
            <Tab label='Informasi Umum' {...a11yProps(0)} className={classes.tab} />
            <Tab label='Invoice' {...a11yProps(1)} className={classes.tab} />
          </Tabs>

          <TabPanel value={tabs} index={0}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={2}>Nama</TableCell>
                    <TableCell colSpan={2} align='right'>
                      {partners.partnerName}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={2}>Plafon</TableCell>
                    <TableCell colSpan={2} align='right'>
                      <NumberFormat value={partners.plafon ? partners.plafon : 0} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={2}>Jatuh Tempo</TableCell>
                    <TableCell colSpan={2} align='right'>
                      {partners.dueDatePlafon || 0}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>Jumlah Terhutang</TableCell>
                    <TableCell colSpan={2} align='right'>
                      <NumberFormat
                        value={partners.totalBillPlafon ? partners.totalBillPlafon : 0}
                        prefix={'Rp'}
                        thousandSeparator={true}
                        displayType='text'
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={tabs} index={1}>
            <Grid xs={12} container style={{ marginTop: '1em' }}>
              <Grid container direction='row' justify='space-between' className={classes.box}>
                <Grid item lg={4} sm={12} md={4} xs={12}>
                  <Typography align='center' variant='h5' className={classes.boxTitle}>
                    Tipe Mitra
                  </Typography>
                  <Typography align='center' variant='body2'>
                    {partner.partnerType}
                  </Typography>
                </Grid>

                <Grid item lg={4} sm={12} md={4} xs={12}>
                  <Typography align='center' variant='h5' className={classes.boxTitle}>
                    Jumlah Terhutang
                  </Typography>
                  <Typography align='center' variant='body2'>
                    <NumberFormat value={partner.totalBill ? partner.totalBill : 0} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                  </Typography>
                </Grid>
              </Grid>
              <Grid xs={12}>
                <InvoiceTable
                  statusPayment={statusPayment}
                  invoiceId={invoiceId}
                  isLoadingData={loadInvoice}
                  count={count}
                  currentPage={currentPage}
                  invoices={invoices}
                  handleClose={handleClose}
                  order={order}
                  orderBy={orderBy}
                  openCollapse={openCollapse}
                  indexCollapse={indexCollapse}
                  setInvoiceId={setInvoiceId}
                  setStatusPayment={setStatusPayment}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                  handleOpenCollapse={handleOpenCollapse}
                  setCurrentPage={setCurrentPage}
                />
              </Grid>
            </Grid>
          </TabPanel>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PartnerModal;
