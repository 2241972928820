import React, { FC, Fragment } from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  IconButton,
  Typography,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Checkbox,
  Grid,
  Tooltip
} from '@material-ui/core';
import { RED, GREEN, BLUE, GREY_SECONDARY, WHITE, PRIMARY_SECONDARY } from 'constants/colors';
import { TableCellEnd, TableCellMiddle, TableCellStart, TableRowCustom, StatusPayment, TableRowCollapse } from 'components';
import { format } from 'date-fns';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import NumberFormat from 'react-number-format';
import Skeleton from '@material-ui/lab/Skeleton';
import clx from 'clsx';
import PartnerType from 'typings/enum/PartnerType';
import { DeleteOutline } from '@material-ui/icons';

interface Props {
  invoice: InvoiceModel;
  isLoading: boolean;
  openCollapse: boolean;
  index: number;
  indexCollapse: number;
  invoiceBulkTab: { id: number; isPayment: boolean }[];
  loadingItem: boolean;
  isSuperAdmin: boolean;
  handlePaymentConfirm: (id: number) => void;
  handleChecklistBulk: (id: number, isPayment: boolean, isChecked: boolean, totalPrice: number) => void;
  handleOpenCollapse: React.MouseEventHandler;
  handleDeleteInvoiceConfirm: (id: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      color: RED,
      width: 30
    },
    tableCellAction: {
      width: 30
    },
    tableCellPrint: {
      width: 100
    },
    tableCellPrint1: {
      width: 200
    },
    customerName: {
      fontSize: '15px',
      fontWeight: 500,
      fontFamily: 'Rubik',
      fontStyle: 'normal',
      lineHeight: '22px'
    },
    link: {
      color: BLUE,
      cursor: 'pointer'
    },
    tableCollapse: {
      width: '100%'
    },
    cellCollapse: {
      border: 'none'
    },
    tableCell: {
      width: 100
    },
    partnerType: {
      color: WHITE,
      borderRadius: '1.2em',
      padding: '.4em 0'
    },
    customerColor: {
      background: PRIMARY_SECONDARY
    },
    resellerColor: {
      background: GREY_SECONDARY
    },
    timeOut: {
      animation: 'shake 0.5s',
      animationIterationCount: 'infinite'
    },
    container: {
      display: 'flex'
    },
    '@keyframes shake': {
      '0%': { transform: 'translate(1px, 1px) rotate(0deg)' },
      '10%': { transform: ' translate(-1px, -2px) rotate(-1deg)' },
      '20%': { transform: ' translate(-3px, 0px) rotate(1deg)' },
      '30%': { transform: ' translate(3px, 2px) rotate(0deg)' },
      '40%': { transform: ' translate(1px, -1px) rotate(1deg)' },
      '50%': { transform: ' translate(-1px, 2px) rotate(-1deg)' },
      '60%': { transform: ' translate(-3px, 1px) rotate(0deg)' },
      '70%': { transform: ' translate(3px, 1px) rotate(-1deg)' },
      '80%': { transform: ' translate(-1px, -1px) rotate(1deg)' },
      '90%': { transform: ' translate(1px, 2px) rotate(0deg)' },
      '100%': { transform: ' translate(1px, -2px) rotate(-1deg)' }
    }
  })
);

const BodyRow: FC<Props> = props => {
  const {
    invoice,
    isLoading,
    loadingItem,
    openCollapse,
    index,
    indexCollapse,
    isSuperAdmin,
    handleOpenCollapse,
    invoiceBulkTab,
    handleChecklistBulk,
    handleDeleteInvoiceConfirm
  } = props;

  const classes = useStyles();

  const handleLink = () => {
    window.open(`/invoice/${invoice.id}`);
  };

  return (
    <Fragment>
      <TableRowCustom>
        <TableCellStart>
          {isLoading ? (
            <Skeleton variant='text' width={60} height={25} />
          ) : (
            <Grid className={classes.container}>
              <IconButton aria-label='expand row' size='small' onClick={handleOpenCollapse}>
                {openCollapse && index === indexCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>

              <Checkbox
                color='primary'
                checked={invoiceBulkTab.some(value => value.id === invoice.id)}
                disabled={invoiceBulkTab.length > 9 && !invoiceBulkTab.some(value => value.id === invoice.id)}
                size='small'
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChecklistBulk(invoice.id, invoice.totalPay !== 0, event.target.checked, invoice.totalPrice)
                }
              />
            </Grid>
          )}
        </TableCellStart>

        <TableCellMiddle onClick={handleLink} className={clx(classes.customerName, classes.link)}>
          {isLoading ? <Skeleton variant='text' width={100} height={25} /> : ` #${invoice.invoiceNumber}`}
        </TableCellMiddle>
        <TableCellMiddle>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <Typography variant='inherit' component='p' className={classes.customerName}>
              {invoice.partnerName || '-'}
            </Typography>
          )}
        </TableCellMiddle>

        <TableCellMiddle>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <Typography
              align='center'
              className={clx(classes.partnerType, invoice.partnerType.includes(PartnerType.CUSTOMER) ? classes.customerColor : classes.resellerColor)}
            >
              {invoice.partnerType}
            </Typography>
          )}
        </TableCellMiddle>
        <TableCellMiddle>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : invoice.createdAt ? (
            format(new Date(invoice.createdAt), 'dd MMM yyyy')
          ) : null}
        </TableCellMiddle>

        <TableCellMiddle>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <NumberFormat value={invoice.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          )}
        </TableCellMiddle>

        <TableCellMiddle>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <NumberFormat value={invoice.totalPrice - invoice.totalPay} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          )}
        </TableCellMiddle>

        {isSuperAdmin ? (
          <>
            <TableCellMiddle align='center'>
              {isLoading ? (
                <Skeleton variant='text' width={150} height={25} />
              ) : (
                <StatusPayment
                  status={invoice.statusPayment}
                  overdue={invoice.paymentDue ? new Date().getTime() > new Date(invoice.paymentDue).getTime() : false}
                  totalPrice={invoice.totalPrice}
                  totalPay={invoice.totalPay}
                />
              )}
            </TableCellMiddle>
            <TableCellEnd align='center'>
              {isLoading ? (
                <Skeleton variant='text' width={20} height={25} />
              ) : (
                <Tooltip title='Hapus Invoice'>
                  <IconButton size='small' onClick={() => handleDeleteInvoiceConfirm(invoice.id)}>
                    <DeleteOutline fontSize='small' color='error' />
                  </IconButton>
                </Tooltip>
              )}
            </TableCellEnd>
          </>
        ) : (
          <TableCellEnd align='center'>
            {isLoading ? (
              <Skeleton variant='text' width={150} height={25} />
            ) : (
              <StatusPayment
                status={invoice.statusPayment}
                overdue={invoice.paymentDue ? new Date().getTime() > new Date(invoice.paymentDue).getTime() : false}
                totalPrice={invoice.totalPrice}
                totalPay={invoice.totalPay}
              />
            )}
          </TableCellEnd>
        )}
      </TableRowCustom>

      <TableRowCollapse open={openCollapse && index === indexCollapse && !isLoading} colSpan={9}>
        <Table className={classes.tableCollapse} size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Nama</TableCell>
              <TableCell align='center'>Kuantitas</TableCell>
              <TableCell align='center'>Gudang</TableCell>
              <TableCell align='right'> Total Harga</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {loadingItem ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <Skeleton variant='text' width='100%' height={25} />
                </TableCell>
              </TableRow>
            ) : (
              invoice.InvoiceItem &&
              invoice.InvoiceItem.map((value: InvoiceItemModel, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.cellCollapse}>{value.productName || '-'}</TableCell>

                  <TableCell align='center' className={classes.cellCollapse}>
                    {value.totalItem + ' ' + value.typeUnit}
                  </TableCell>

                  <TableCell align='center' className={classes.cellCollapse}>
                    {value.warehouse || '-'}
                  </TableCell>

                  <TableCell align='right' className={classes.cellCollapse}>
                    <NumberFormat value={value.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableRowCollapse>
    </Fragment>
  );
};

export default BodyRow;
