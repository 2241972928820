import { Grid, GridProps } from '@material-ui/core';
import React from 'react';

interface Props extends GridProps {
  children: React.ReactNode;
  direction?: 'row' | 'column';
  flex?: string;
  rowGap?: number;
  columnGap?: number;
}

const FlexBox = ({ flex = '1', children, rowGap = 0, columnGap = 0, direction = 'row', ...props }: Props) => {
  const styles = {
    flex: flex,
    display: 'flex',
    flexDerection: direction,
    gap: `${rowGap}em ${columnGap}em`
  };
  return (
    <Grid {...props} style={styles}>
      {children}
    </Grid>
  );
};

export default FlexBox;
