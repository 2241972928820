import React from 'react';
import { Grid, TextField, Button, Theme, makeStyles, MenuItem } from '@material-ui/core';

interface Props {
  email: string;
  firstName: string;
  lastName: string;
  contactNumber: string;
  emailError: string;
  wareHouseError: string;
  wareHouse: number;
  firstNameError: string;
  contactNumberError: string;
  type: string;
  typeError: string;
  roles: RoleModel[];
  warehouses: WareHouseModel[];
  isLoading: boolean;
  handleOnSubmit: () => void;
  handleOnCancel: React.MouseEventHandler;
  setType: React.Dispatch<React.SetStateAction<string>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  setContactNumber: React.Dispatch<React.SetStateAction<string>>;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  handleChangeWareHouse: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  controlDiv: {
    '& > :nth-child(n+2)': {
      marginLeft: theme.spacing(2)
    }
  },
  wareHouseContainer: {
    margin: '0.6em'
  },
  cancelButton: {
    marginRight: theme.spacing(1)
  },
  addButton: {
    color: '#FFFFFF'
  },
  root: {
    flexGrow: 1,
    maxWidth: 700
  },
  gridRolesHidden: {
    display: 'none'
  }
}));

const CreateUserForm: React.FC<Props> = props => {
  const { type, typeError, setType, roles, handleOnSubmit, handleOnCancel, isLoading } = props;
  const { email, setEmail, emailError } = props;
  const { firstName, setFirstName, firstNameError } = props;
  const { lastName, setLastName } = props;
  const { contactNumber, setContactNumber, contactNumberError } = props;

  return (
    <Grid container direction='row' spacing={1}>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='firstName'
          label='Nama Depan'
          error={firstNameError !== ''}
          helperText={firstNameError}
          value={firstName}
          onChange={event => setFirstName(event.target.value)}
          autoComplete='off'
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <TextField
          variant='outlined'
          margin='normal'
          fullWidth
          id='lastName'
          label='Nama Belakang'
          value={lastName}
          onChange={event => setLastName(event.target.value)}
          autoComplete='off'
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='email'
          label='Email'
          error={emailError !== ''}
          helperText={emailError}
          value={email}
          onChange={event => setEmail(event.target.value)}
          autoComplete='off'
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='contactNumber'
          label='No Kontak'
          error={contactNumberError !== ''}
          helperText={contactNumberError}
          value={contactNumber}
          onChange={event => setContactNumber(event.target.value)}
          autoComplete='off'
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextField
          id='typeUser'
          required
          label='Tipe'
          value={type as string}
          error={typeError !== ''}
          helperText={typeError}
          onChange={e => setType(e.target.value as string)}
          select
          fullWidth
        >
          <MenuItem key={'other'}>Pilih Tipe</MenuItem>
          {roles.length > 0 &&
            roles.map((value, index) => (
              <MenuItem key={index} value={value.label}>
                {value.name}
              </MenuItem>
            ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container direction='row' justify='flex-end' spacing={2}>
        <Grid item>
          <Button color='secondary' onClick={handleOnCancel} disabled={isLoading}>
            Batal
          </Button>
        </Grid>
        <Grid item>
          <Button disabled={isLoading} onClick={handleOnSubmit}>
            Simpan
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreateUserForm;
