import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import { PaperCustom } from 'components';
import { GREY } from 'constants/colors';
import React from 'react';
import { TcourierSalary } from '..';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    rowGap: '1em'
  },
  divender: {
    borderRight: `1px solid ${GREY}`
  }
}));
interface Props {
  courierSalaryResource: TcourierSalary<UserDetailsModel, CourierSalary[]>;
}
const TitleTable = [
  {
    key: 'name',
    title: 'Nama Kurir'
  },
  {
    key: 'email',
    title: 'Email'
  },
  {
    key: 'contactNumber',
    title: 'No Handphone'
  },
  {
    key: 'isActive',
    title: 'Status'
  }
];
const ContentInfomation = ({ courierSalaryResource }: Props) => {
  const classes = useStyles();

  return (
    <PaperCustom>
      {courierSalaryResource.isLoading ? (
        <Grid xs={12} container>
          {TitleTable.map((value, key) => (
            <Grid
              xs={3}
              key={value.key}
              className={clsx(classes.container, key !== TitleTable.length - 1 && classes.divender)}
              container
              direction='column'
              alignItems='center'
            >
              <Typography>
                <Typography variant='h6'>{value.title}</Typography>
              </Typography>
              <Typography>
                <Skeleton variant='text' animation='wave' width={200} />
              </Typography>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid xs={12} container>
          {TitleTable.map((value, key) => (
            <Grid
              xs={3}
              key={value.key}
              className={clsx(classes.container, key !== TitleTable.length - 1 && classes.divender)}
              container
              direction='column'
              alignItems='center'
            >
              <Typography variant='h6'>{value.title}</Typography>
              {value.key.includes('isActive') ? (
                <Typography color='textSecondary'>{courierSalaryResource.data.isActive ? 'Aktif' : 'Tidak Aktif'}</Typography>
              ) : (
                <Typography color='textSecondary'>{courierSalaryResource.data[value.key as keyof UserDetailsModel]}</Typography>
              )}
            </Grid>
          ))}
        </Grid>
      )}
    </PaperCustom>
  );
};

export default ContentInfomation;
