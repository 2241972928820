import React, { FC, useState, useEffect, Fragment } from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  TextField,
  Tabs,
  Tab,
  Typography,
  Grid,
  IconButton,
  FormHelperText,
  Paper,
  CircularProgress,
  DialogActions,
  Button
} from '@material-ui/core';
import { isValidEmail } from 'utils';
import { GREY, WHITE, PRIMARY, GRAY_4 } from 'constants/colors';
import { dummyRegion, dummyPartner } from 'utils/dummy';
import { TabPanel, a11yProps } from 'components';
import TypeUser from 'typings/enum/TypeUser';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PersonIcon from '@material-ui/icons/Person';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReactQuill from 'react-quill';
import NumberFormatMask from 'components/NumberFormatMask';
import PartnerType from 'typings/enum/PartnerType';
import { Add, DeleteForever, PeopleAltRounded } from '@material-ui/icons';
import FlexBox from 'components/FlexBox';

interface Props {
  partnerId: string;
  isSubmit: boolean;
  region: RegionModel[];
  value: number;
  loadProvinsi: boolean;
  loadKabupaten: boolean;
  loadKecamatan: boolean;
  typeUser: string;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  setSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (partner: PartnerModel, reset: () => void, resetValidation: () => void) => void;
  handleSearchProvinsi: (value: string) => void;
  handleSearchKabupaten: (code: string, value: string) => void;
  handleSearchKecamatan: (code: string, value: string) => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles({
  container: {
    padding: '12px'
  },
  spacing: {
    margin: '1em 0'
  },
  paper: {
    border: '1px solid #E0E0E0',
    boxSizing: 'border-box',
    boxShadow: '0px 5px 12px rgba(0, 0, 0, 0.08)',
    borderRadius: '5px',
    width: '100%',
    height: '173px',
    paddingTop: 24
  },
  iconButton: {
    background: PRIMARY
  },
  iconBorder: {
    border: `1px solid ${GREY}`,
    boxSizing: 'border-box',
    marginRight: 18
  },
  icon: {
    color: WHITE
  },
  errorType: {
    textAlign: 'center',
    width: '100%'
  },
  secondary: {
    color: GRAY_4
  }
});

const PartnerWizard: FC<Props> = props => {
  const classes = useStyles();

  const {
    isSubmit,
    partnerId,
    region,
    value,
    loadProvinsi,
    loadKabupaten,
    loadKecamatan,
    typeUser,
    setValue,
    setSubmit,
    onSubmit,
    handleSearchProvinsi,
    handleSearchKabupaten,
    handleSearchKecamatan,
    setOpen
  } = props;

  const [name, setName] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [remarkAddress, setRemarkAddress] = useState<string>('');
  const [cellPhoneNumber, setCellPhoneNumber] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [website, setWebsite] = useState<string>('');
  const [virtualAccount, setVirtualAccount] = useState<string>('');
  const [plafon, setPlafon] = useState<number | null>(null);
  const [notes, setNotes] = useState<string>('');
  const [postalCode, setPostalCode] = useState<number | null>(null);
  const [partnerType, setPartnerType] = useState<PartnerType>(PartnerType.CUSTOMER);
  const [provinsi, setProvinsi] = useState<RegionModel>(dummyRegion);
  const [kabupaten, setKabupaten] = useState<RegionModel>(dummyRegion);
  const [kecamatan, setKecamatan] = useState<RegionModel>(dummyRegion);
  const [dueDate, setDueDate] = useState<number | null>(30);
  const [nameMessage, setNameMessage] = useState<string>('');
  const [emailMessage, setEmailMessage] = useState<string>('');
  const [addressMessage, setAddressMessage] = useState<string>('');
  const [businessEntityMessage, setBusinessEntityMessage] = useState<string>('');
  const [provinsiMessage, setProvinsiMessage] = useState<string>('');
  const [kabupatenMessage, setKabupatenMessage] = useState<string>('');
  const [cellPhoneNumberMessage, setCellPhoneNumberMessage] = useState<string>('');
  const [kecamatanMessage, setKecamatanMessage] = useState<string>('');
  const [postalCodeMessage, setPostalCodeMessage] = useState<string>('');
  const [zoneMessage, setZoneMessage] = useState<string>('');
  const [phoneNumberMessage, setPhoneNumberMessage] = useState<string>('');
  const [dueDateMessage, setDueDateMessage] = useState<string>('');
  const [partnerTypeMessage, setPartnerTypeMessage] = useState<string>('');
  const [isSalesOrSpv, setIsSalesOrSpv] = useState<boolean>(false);
  const [resellerType, setResellerType] = useState<number>(0);
  const [countCustomer, setCountCustomer] = useState<number[]>([0]);
  const [customerResource, setCustomerResource] = useState<PartnerModel[]>([dummyPartner]);
  const validationFormOne = (): boolean => {
    let valid = true;

    if (name === '' || !name) {
      setNameMessage('Nama tidak boleh kosong.');
      valid = false;
    }

    if (email && !isValidEmail(email)) {
      setEmailMessage('Email tidak valid.');
      valid = false;
    }

    if (!partnerType) {
      setPartnerTypeMessage('Pilih tipe mitra terlebih dahulu');
    }
    if (phoneNumber === '') {
      setPhoneNumberMessage('No Kontak tidak boleh kosong');
    } else if (phoneNumber.length < 10) {
      setPhoneNumberMessage('Jumlah No kontak tidak benar');
    } else if (!/^\d+$/.test(phoneNumber)) {
      setPhoneNumberMessage('No kontak tidak boleh ada abjad');
    }
    if (!address || !address.trim()) {
      setAddressMessage('Alamat tidak boleh kosong');
      valid = false;
    }

    if (provinsi.name === '' || provinsi.code === '') {
      setProvinsiMessage('Asal provinsi tidak boleh kosong');
      valid = false;
    }

    if (kabupaten.name === '' || kabupaten.code === '') {
      setKabupatenMessage('Kabupaten tidak boleh kosong');
      valid = false;
    }
    if (kecamatan.name === '' || kecamatan.code === '') {
      setKecamatanMessage('Kecamatan tidak boleh kosong');
      valid = false;
    }

    return valid;
  };

  const handleAddCustumerReseler = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const temp = customerResource.map((value, key) => {
      if (key === index) {
        return { ...value, [event.target.id]: event.target.value };
      } else {
        return { ...value };
      }
    });
    setCustomerResource(temp);
  };

  const resetValidation = () => {
    setNameMessage('');
    setEmailMessage('');
    setAddressMessage('');
    setProvinsiMessage('');
    setKabupatenMessage('');
    setKecamatanMessage('');
    setPostalCodeMessage('');
    setBusinessEntityMessage('');
    setDueDateMessage('');
    setPartnerTypeMessage('');
    setCellPhoneNumberMessage('');
    setPhoneNumberMessage('');
  };

  const reset = () => {
    setName('');
    setEmail('');
    setPhoneNumber('');
    setAddress('');
    setProvinsi(dummyRegion);
    setKabupaten(dummyRegion);
    setKecamatan(dummyRegion);
    setPostalCode(null);
    setDueDate(null);
    setPartnerType(PartnerType.CUSTOMER);
    setType('');
  };

  const handleCancel = () => {
    setOpen(false);
    resetValidation();
  };

  const handleSubmit = () => {
    setSubmit(true);
    resetValidation();
  };
  const handleNextReseller = () => {
    if (!validationFormOne()) return;
    setResellerType(1);
  };

  useEffect(() => {
    if (name !== '') {
      setNameMessage('');
    }

    if (isValidEmail(email)) {
      setEmailMessage('');
    }

    if (phoneNumber !== '') {
      setPhoneNumberMessage('');
    }

    if (type !== '') {
      setBusinessEntityMessage('');
    }

    if (partnerType === PartnerType.CUSTOMER) {
      if (dueDate) {
        setDueDateMessage('');
      }
    }

    if (partnerType) {
      setPartnerTypeMessage('');
    }

    if (address !== '') {
      setAddressMessage('');
    }

    if (provinsi.name !== '' || provinsi.code !== '') {
      setProvinsiMessage('');
    }

    if (kabupaten.name !== '' || kabupaten.code !== '') {
      setKabupatenMessage('');
    }

    if (kecamatan.name !== '' || kecamatan.code !== '') {
      setKecamatanMessage('');
    }
  }, [name, email, type, dueDate, partnerType, address, provinsi, kabupaten, kecamatan, phoneNumber]);

  useEffect(() => {
    if (!isSubmit) {
      return;
    }

    resetValidation();

    if (!validationFormOne()) {
      setValue(0);
      setSubmit(false);
      return;
    }

    const item: PartnerModel = {
      ...dummyPartner,
      partnerId,
      name,
      partnerType: partnerType!,
      phoneNumber,
      cellPhoneNumber,
      email,
      address,
      notes,
      website,
      postalCode,
      areaCode: kecamatan.code,
      regionName: provinsi.name + ', ' + kabupaten.name + ', ' + kecamatan.name,
      businessEntity: type,
      virtualAccount,
      dueDate,
      plafon,
      remarkAddress,
      customerReseller: partnerType.includes(PartnerType.RESELLER)
        ? customerResource.map(val => ({ ...val, areaCode: (val.Kecamatan && val.Kecamatan.code) || '' }))
        : []
    };
    onSubmit(item, reset, resetValidation);
  }, [isSubmit]);

  useEffect(() => {
    if (typeUser === TypeUser.SALES || typeUser === TypeUser.SUPERVISOR || typeUser === TypeUser.ADMIN) {
      setPartnerType(PartnerType.CUSTOMER);
    }

    if (typeUser === TypeUser.SALES || typeUser === TypeUser.SUPERVISOR) {
      setIsSalesOrSpv(true);
    }

    return;
  }, [typeUser]);

  const handleAddFormCustumerReseler = () => {
    setCountCustomer([...countCustomer, countCustomer.push(countCustomer[countCustomer.length - 1] + 1)]);
    setCustomerResource([...customerResource, dummyPartner]);
  };

  const handleRemoveFormCustumerReseler = (index: number) => {
    setCountCustomer(countCustomer.filter((val, key) => index !== key));
    setCustomerResource(customerResource.filter((val, key) => index !== key));
  };

  useEffect(() => {
    setKabupaten(dummyRegion);
    setKecamatan(dummyRegion);
  }, [provinsi]);

  useEffect(() => {
    setKecamatan(dummyRegion);
  }, [kabupaten]);

  return (
    <Grid container direction='row' spacing={1} className={classes.container}>
      <Paper className={classes.paper}>
        <Typography variant='h5' align='center'>
          Pilih tipe mitra <code style={{ color: 'red' }}>*</code>
        </Typography>

        <Grid container direction='row' item lg={12} md={12} sm={12} justify='space-between'>
          <Grid container item lg={4} sm={4} md={4} xs={4} justify='center' alignItems='center'>
            <IconButton
              onClick={() => setPartnerType(PartnerType.CUSTOMER)}
              className={clsx({ [classes.iconButton]: PartnerType.CUSTOMER === partnerType }, classes.iconBorder)}
            >
              <PersonIcon color='primary' className={clsx({ [classes.icon]: PartnerType.CUSTOMER === partnerType })} />
            </IconButton>
            <Typography variant='h5'>Customer</Typography>
          </Grid>

          <Grid container item lg={4} sm={4} md={4} xs={4} justify='center' alignItems='center'>
            <IconButton
              onClick={() => setPartnerType(PartnerType.RESELLER)}
              className={clsx({ [classes.iconButton]: PartnerType.RESELLER === partnerType && partnerType }, classes.iconBorder)}
            >
              <PeopleAltRounded color='primary' className={clsx({ [classes.icon]: PartnerType.RESELLER === partnerType && partnerType })} />
            </IconButton>
            <Typography variant='h5'>Reseller</Typography>
          </Grid>

          <Grid container item lg={4} sm={4} md={4} xs={4} justify='center' alignItems='center'>
            <IconButton
              onClick={() => setPartnerType(PartnerType.SUPPLIER)}
              className={clsx({ [classes.iconButton]: PartnerType.SUPPLIER === partnerType && partnerType }, classes.iconBorder)}
            >
              <ShoppingCartIcon color='primary' className={clsx({ [classes.icon]: PartnerType.SUPPLIER === partnerType && partnerType })} />
            </IconButton>
            <Typography variant='h5'>Supplier</Typography>
          </Grid>
        </Grid>

        <FormHelperText error={partnerTypeMessage !== ''} className={classes.errorType}>
          {partnerTypeMessage}
        </FormHelperText>
      </Paper>
      {partnerType.includes(PartnerType.RESELLER) && (
        <Grid xs={12} className={classes.spacing}>
          <Tabs value={resellerType} indicatorColor='primary' textColor='primary' aria-label='tabs type reseller' variant='fullWidth'>
            <Tab label='DATA RESELLER' {...a11yProps(0)} />
            <Tab label='DATA CUSTOMER' {...a11yProps(1)} />
          </Tabs>
        </Grid>
      )}

      {partnerType.includes(PartnerType.RESELLER) && resellerType === 1 ? (
        <>
          {countCustomer.map((val, index) => (
            <>
              <Grid
                xs={12}
                container
                justify='space-between'
                spacing={2}
                key={index}
                className={clsx(!partnerType.includes(PartnerType.RESELLER) && classes.spacing)}
              >
                <Grid xs={6} item>
                  <Typography variant='h6'>Informasi Umum</Typography>
                  <TextField
                    id='name'
                    name='name'
                    required
                    fullWidth
                    label='Nama'
                    value={customerResource[index].name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAddCustumerReseler(index, event)}
                  />

                  <TextField
                    id='phoneNumber'
                    fullWidth
                    label='Nomor Kontak'
                    value={customerResource[index].phoneNumber}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAddCustumerReseler(index, event)}
                  />

                  <TextField
                    id='email'
                    name='email'
                    type='email'
                    fullWidth
                    label='Email '
                    value={customerResource[index].email}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAddCustumerReseler(index, event)}
                  />
                </Grid>
                <Grid xs={6} item>
                  <Typography variant='h6'>Informasi Alamat</Typography>
                  <TextField
                    id='address'
                    required
                    fullWidth
                    label='Alamat'
                    value={customerResource[index].address}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAddCustumerReseler(index, event)}
                  />
                  <Grid container xs={12} justify='space-between' alignItems='center'>
                    <FlexBox flex='0.49'>
                      <Autocomplete
                        id='provinsi'
                        fullWidth
                        options={region}
                        getOptionLabel={option => option.name}
                        getOptionSelected={(option, value) => option.code === value.code}
                        onChange={(event: any, value: any) => {
                          if (value) {
                            setCustomerResource(
                              customerResource.map((val, key) => {
                                if (key === index) {
                                  return { ...val, Provinsi: value };
                                } else {
                                  return { ...val };
                                }
                              })
                            );
                          }
                        }}
                        loading={loadProvinsi}
                        onOpen={e => handleSearchProvinsi('')}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label='Provinsi *'
                            onChange={e => handleSearchProvinsi(e.target.value)}
                            variant='outlined'
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <Fragment>
                                  {loadProvinsi && <CircularProgress color='inherit' size={20} />}
                                  {params.InputProps.endAdornment}
                                </Fragment>
                              )
                            }}
                          />
                        )}
                      />
                    </FlexBox>
                    <FlexBox flex='0.49'>
                      <Autocomplete
                        id='kabupaten'
                        fullWidth
                        options={region}
                        getOptionLabel={option => option.name}
                        getOptionSelected={(option, value) => option.code === value.code}
                        onChange={(event: any, value: any) => {
                          if (value) {
                            setCustomerResource(
                              customerResource.map((val, key) => {
                                if (key === index) {
                                  return { ...val, Kabupaten: value };
                                } else {
                                  return { ...val };
                                }
                              })
                            );
                          }
                        }}
                        loading={loadKabupaten}
                        onOpen={e => handleSearchKabupaten(customerResource[index].Provinsi!.code, '')}
                        renderInput={params => (
                          <TextField
                            {...params}
                            fullWidth
                            label='Kabupaten *'
                            onChange={e => handleSearchKabupaten(customerResource[index].Provinsi!.code, e.target.value)}
                            variant='outlined'
                            error={kabupatenMessage !== ''}
                            helperText={kabupatenMessage}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <Fragment>
                                  {loadKabupaten && <CircularProgress color='inherit' size={20} />}
                                  {params.InputProps.endAdornment}
                                </Fragment>
                              )
                            }}
                          />
                        )}
                      />
                    </FlexBox>
                  </Grid>
                  <Grid container xs={12} justify='space-between'>
                    <FlexBox flex='0.49'>
                      <Autocomplete
                        id='kecamatan'
                        fullWidth
                        options={region}
                        getOptionLabel={option => option.name}
                        getOptionSelected={(option, value) => option.code === value.code}
                        onChange={(event: any, value: any) => {
                          if (value) {
                            setCustomerResource(
                              customerResource.map((val, key) => {
                                if (key === index) {
                                  return { ...val, Kecamatan: value };
                                } else {
                                  return { ...val };
                                }
                              })
                            );
                          }
                        }}
                        loading={loadKecamatan}
                        onOpen={e => handleSearchKecamatan(customerResource[index].Kabupaten!.code, '')}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label='Kecamatan *'
                            size='small'
                            onChange={e => handleSearchKecamatan(customerResource[index].Kabupaten!.code, e.target.value)}
                            variant='outlined'
                            error={kecamatanMessage !== ''}
                            helperText={kecamatanMessage}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <Fragment>
                                  {loadKecamatan && <CircularProgress color='inherit' size={20} />}
                                  {params.InputProps.endAdornment}
                                </Fragment>
                              )
                            }}
                          />
                        )}
                      />
                    </FlexBox>
                    <FlexBox flex='0.49'>
                      <TextField
                        id='postalCode'
                        fullWidth
                        label='Kode Pos'
                        value={customerResource[index].postalCode}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAddCustumerReseler(index, event)}
                      />
                    </FlexBox>

                    <Grid xs={12} style={{ marginTop: '0.8em' }}>
                      <ReactQuill
                        id='notes'
                        value={customerResource[index].notes}
                        onChange={(value: any) =>
                          setCustomerResource(
                            customerResource.map((val, key) => {
                              if (key === index) {
                                return { ...val, notes: value };
                              } else {
                                return { ...val };
                              }
                            })
                          )
                        }
                        placeholder='Catatan'
                      />
                    </Grid>
                  </Grid>

                  {partnerType.includes(PartnerType.RESELLER) && resellerType === 1 && (
                    <Grid xs={12} container justify='flex-end' className={classes.spacing}>
                      {index !== countCustomer.length - 1 ? (
                        <Button
                          variant='text'
                          className={classes.secondary}
                          size='small'
                          startIcon={<DeleteForever className={classes.secondary} />}
                          onClick={() => handleRemoveFormCustumerReseler(index)}
                        >
                          Hapus Customer
                        </Button>
                      ) : (
                        <>
                          {index > 0 && (
                            <Button
                              variant='text'
                              className={classes.secondary}
                              size='small'
                              startIcon={<DeleteForever className={classes.secondary} />}
                              onClick={() => handleRemoveFormCustumerReseler(index)}
                            >
                              Hapus Customer
                            </Button>
                          )}
                          <Button
                            variant='text'
                            color='primary'
                            size='small'
                            disabled={
                              customerResource[index].name === '' ||
                              customerResource[index].address === '' ||
                              !customerResource[index].Provinsi ||
                              !customerResource[index].Kabupaten ||
                              !customerResource[index].Kecamatan
                            }
                            startIcon={<Add />}
                            onClick={handleAddFormCustumerReseler}
                          >
                            Tambah Customer
                          </Button>
                        </>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </>
          ))}
        </>
      ) : (
        <Grid xs={12} container justify='space-between' spacing={2} className={clsx(!partnerType.includes(PartnerType.RESELLER) && classes.spacing)}>
          <Grid xs={6} item>
            <Typography variant='h6'>Informasi Umum</Typography>
            <TextField
              id='name'
              name='name'
              required
              fullWidth
              label='Nama '
              value={name}
              onChange={event => setName(event.target.value)}
              error={nameMessage !== ''}
              helperText={nameMessage}
              disabled={partnerType === null}
            />

            <TextField
              id='phoneNumber'
              fullWidth
              label='Nomor Kontak'
              value={phoneNumber}
              inputProps={{ min: 0, inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={event => setPhoneNumber(event.target.value)}
              error={phoneNumberMessage !== ''}
              helperText={phoneNumberMessage}
              disabled={partnerType === null}
            />

            <TextField
              id='email'
              name='email'
              type='email'
              fullWidth
              label='Email '
              value={email}
              onChange={event => setEmail(event.target.value)}
              error={emailMessage !== ''}
              helperText={emailMessage}
              disabled={partnerType === null}
            />
            {!partnerType.includes(PartnerType.SUPPLIER) && (
              <TextField
                id='plafon'
                fullWidth
                label='Plafon'
                value={plafon}
                onChange={event => setPlafon(+event.target.value)}
                disabled={partnerType === null}
                InputProps={{
                  inputComponent: NumberFormatMask as any
                }}
              />
            )}

            {!partnerType.includes(PartnerType.SUPPLIER) && (
              <TextField
                id='dueDate'
                fullWidth
                label='Jatuh Tempo'
                value={dueDate}
                onChange={event => setDueDate(+event.target.value)}
                disabled={partnerType === null}
              />
            )}
          </Grid>
          <Grid xs={6} item>
            <Typography variant='h6'>Informasi Alamat</Typography>
            <TextField
              id='address'
              required
              fullWidth
              label='Alamat'
              value={address}
              onChange={event => setAddress(event.target.value)}
              error={addressMessage !== ''}
              helperText={addressMessage}
            />
            <Grid container xs={12} justify='space-between' alignItems='center'>
              <FlexBox flex='0.49'>
                <Autocomplete
                  id='provinsi'
                  fullWidth
                  value={provinsi}
                  options={region}
                  getOptionLabel={option => option.name}
                  getOptionSelected={(option, value) => option.code === value.code}
                  onChange={(event: any, value: any, reason: any) => {
                    if (reason === 'clear') {
                      setKabupaten(dummyRegion);
                      setKecamatan(dummyRegion);
                    }
                    if (value) {
                      setProvinsi(value);
                    }
                  }}
                  loading={loadProvinsi}
                  onOpen={e => handleSearchProvinsi('')}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label='Provinsi *'
                      onChange={e => handleSearchProvinsi(e.target.value)}
                      variant='outlined'
                      error={provinsiMessage !== ''}
                      helperText={provinsiMessage}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>
                            {loadProvinsi && <CircularProgress color='inherit' size={20} />}
                            {params.InputProps.endAdornment}
                          </Fragment>
                        )
                      }}
                    />
                  )}
                />
              </FlexBox>
              <FlexBox flex='0.49'>
                <Autocomplete
                  id='kabupaten'
                  fullWidth
                  value={kabupaten}
                  options={region}
                  getOptionLabel={option => option.name}
                  getOptionSelected={(option, value) => option.code === value.code}
                  onChange={(event: any, value: any, reason: any) => {
                    if (reason === 'clear') {
                      setKecamatan(dummyRegion);
                    }
                    if (value) {
                      setKabupaten(value);
                    }
                  }}
                  loading={loadKabupaten}
                  onOpen={e => handleSearchKabupaten(provinsi.code, '')}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      label='Kabupaten *'
                      onChange={e => handleSearchKabupaten(provinsi.code, e.target.value)}
                      variant='outlined'
                      error={kabupatenMessage !== ''}
                      helperText={kabupatenMessage}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>
                            {loadKabupaten && <CircularProgress color='inherit' size={20} />}
                            {params.InputProps.endAdornment}
                          </Fragment>
                        )
                      }}
                    />
                  )}
                />
              </FlexBox>
            </Grid>
            <Grid container xs={12} justify='space-between'>
              <FlexBox flex='0.49'>
                <Autocomplete
                  id='kecamatan'
                  fullWidth
                  value={kecamatan}
                  options={region}
                  getOptionLabel={option => option.name}
                  getOptionSelected={(option, value) => option.code === value.code}
                  onChange={(event: any, value: any) => {
                    if (value) {
                      setKecamatan(value);
                    }
                  }}
                  loading={loadKecamatan}
                  onOpen={e => handleSearchKecamatan(kabupaten.code, '')}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label='Kecamatan *'
                      size='small'
                      onChange={e => handleSearchKecamatan(kabupaten.code, e.target.value)}
                      variant='outlined'
                      error={kecamatanMessage !== ''}
                      helperText={kecamatanMessage}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>
                            {loadKecamatan && <CircularProgress color='inherit' size={20} />}
                            {params.InputProps.endAdornment}
                          </Fragment>
                        )
                      }}
                    />
                  )}
                />
              </FlexBox>
              <FlexBox flex='0.49'>
                <TextField
                  id='postalCode'
                  fullWidth
                  label='Kode Pos'
                  value={postalCode}
                  onChange={event => setPostalCode(+event.target.value)}
                  error={postalCodeMessage !== ''}
                  helperText={postalCodeMessage}
                />
              </FlexBox>

              <Grid xs={12} style={{ marginTop: '0.8em' }}>
                <ReactQuill id='notes' value={notes} onChange={(value: any) => setNotes(value)} placeholder='Catatan' />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid container direction='row' justify='space-between'>
        <Grid item lg={6} sm={6} md={6} xs={6}></Grid>

        <Grid container item lg={6} sm={6} md={6} xs={6} spacing={3} justify='flex-end' className={classes.spacing}>
          <Grid item>
            <DialogActions>
              <Button color='secondary' disabled={isSubmit} onClick={handleCancel}>
                Batal
              </Button>
              {partnerType.includes(PartnerType.RESELLER) && resellerType === 0 ? (
                <Button disabled={isSubmit} onClick={handleNextReseller}>
                  Selanjutnya
                </Button>
              ) : (
                <Button
                  disabled={
                    partnerType.includes(PartnerType.RESELLER)
                      ? customerResource.filter(val => val.name === '' || val.address === '' || !val.Provinsi || !val.Kabupaten || !val.Kecamatan)
                          .length > 0
                      : isSubmit
                  }
                  onClick={handleSubmit}
                >
                  Simpan
                </Button>
              )}
            </DialogActions>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PartnerWizard;
