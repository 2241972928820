import React, { FC, useEffect, useState, useContext, useMemo } from 'react';
import { Theme, Grid, Container, Typography, makeStyles, Button, Tooltip, Badge, CircularProgress } from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';
import CalendarIcon from '@material-ui/icons/EventNote';
import RefreshIcon from '@material-ui/icons/Refresh';
import useRole from 'hooks/useRole';
import Pagination from '@material-ui/lab/Pagination';
import useDebounced from 'hooks/useDebounced';
import { Page, StandardConfirmationDialog, PaperCustom, Breadcrumb, FormPinDialog } from 'components';
import { INVOICE_BASE_URL, ZONE_BASE_URL, EXPORT_INVOICE_BASE_URL } from 'constants/url';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import DateRangeFilter from 'components/DateRangeFilter';
import InvoiceTable from './components/InvoiceTable';
import { GREEN, WHITE, PRIMARY, PRIMARY_SECONDARY } from 'constants/colors';
import { format, startOfMonth } from 'date-fns';
import CardTotal from './components/CardTotal';
import TypeUser from 'typings/enum/TypeUser';
import GetAppIcon from '@material-ui/icons/GetApp';
import ModalSuccess from './components/ModalSuccess';
import ModalConfirmation from './components/ModalConfirmation';
import FlexBox from 'components/FlexBox';
import InvoicePaymentCreate from './components/invoicePayment/InvoicePaymentCreate';
import InvoicePaymentSuccess from './components/invoicePayment/InvoicePaymentSuccess';
import { getTotal } from 'utils/getTotal';
import useConfirmationPin from 'hooks/useConfirmationPin';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) => ({
  spaceTop: {
    paddingBottom: 30
  },
  fourthGrid: {
    marginTop: 20
  },
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN
    }
  },
  calendarIcon: {
    fontSize: 30,
    color: theme.palette.primary.main
  },
  paper: {
    padding: '20px',
    boxShadow: '0px 5px 24px rgba(2, 169, 234, 0.12)',
    borderRadius: '5px',
    cursor: 'pointer'
  },
  alertWrapper: {
    margin: '1em 0'
  },
  iconCard: {
    fontSize: 48,
    color: PRIMARY_SECONDARY
  },
  icon: {
    paddingTop: 5,
    marginTop: 5,
    fontSize: 48,
    color: PRIMARY_SECONDARY
  },
  gridPaper: {
    paddingTop: '24px'
  }
}));

const InvoicePage: FC = () => {
  const classes = useStyles();
  const { currentUser } = useContext(CurrentUserContext);
  const [count, setCount] = useState<number>(0);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [invoices, setInvoices] = useState<InvoiceModel[]>([]);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [selectedId, setSelectedId] = useState<number>();
  const [confirmationDelete, setConfirmationDelete] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [invoiceId, setInvoiceId] = useState<string>('');
  const [statusPayment, setStatusPayment] = useState<string>('UNPAID');
  const [statusPrint, setStatusPrint] = useState<number>(0);
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [indexCollapse, setIndexCollapse] = useState<number>(-1);
  const [loadingItem, setLoadingItem] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [to, setTo] = useState<number>(0);
  const [from, setFrom] = useState<number>(0);
  const [loadingPayment, setloadingPayment] = useState<boolean>(false);
  const [openPaymentSuccess, setOpenPaymentSuccess] = useState<boolean>(false);
  const [paymentMessage, setPaymentMessage] = useState<string>('');
  const [invoicePayment, setInvoicePayment] = useState<number>(0);
  const [totalPriceSearch, setTotalPriceSearch] = useState<number>(0);
  const [invoicePaymentConfirm, setInvoicePaymentConfirm] = useState<boolean>(false);
  const [partnerType, setPartnerType] = useState<string>('');
  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [pin, setPin] = useState<number | null>(null);
  const [isComplete, setComplete] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [openCalendarFilter, setOpenCalendarFilter] = useState<boolean>(false);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const [invoiceBulkTab, setInvoiceBulkTab] = useState<{ id: number; isPayment: boolean; totalPrice: number }[]>([]);
  const [InvoicePaymentBulk, setInvoicePaymentBulk] = useState<boolean>(false);
  const [isInvoicePaymentBulkLoading, setIsInvoicePaymentBulkLoading] = useState<boolean>(false);
  const [isInvoicePaymentBulkSuccess, setIsInvoicePaymentBulkSuccess] = useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<{ id: number; isConfirm: boolean; isLoading: boolean; isSuccess: boolean }>({
    id: 0,
    isConfirm: false,
    isLoading: false,
    isSuccess: false
  });

  const pinMessage = useConfirmationPin({
    pin,
    confirmationDelete: true,
    isCompletePin: isComplete,
    handleOnDelete: handleDeleteInvoice,
    setOpenConfirmation: setOpenConfirmation,
    setLoading: setLoadingDelete,
    setCompletePin: setComplete,
    setPin: setPin
  });

  const isSuperAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERADMIN]
  });

  const isAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ADMIN]
  });

  const isSuperVisor = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERVISOR]
  });

  const isSales = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SALES]
  });

  const salesRoute = currentUser && currentUser.SalesRoute ? currentUser.SalesRoute.map(value => value.ZoneId) : [];
  const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

  const getQueryParams = () => {
    const params = new URLSearchParams();

    if (invoiceId) {
      params.append('number', invoiceId);
    }

    if (name) {
      params.append('keyword', name);
    }

    if (statusPayment) {
      params.append('status', statusPayment);
    }

    if (partnerType) {
      params.append('partnerType', partnerType);
    }
    if (totalPriceSearch) {
      params.append('totalPrice', String(totalPriceSearch));
    }

    if (!invoiceId) {
      params.append('startDate', startDate);
      params.append('endDate', endDate);
    }

    params.append('orderBy', orderBy);
    params.append('ordered', order);
    params.append('page', currentPage.toString());
    params.append('printed', statusPrint.toString());
    return params.toString();
  };

  const fetchData = async () => {
    setIsLoadingData(true);
    setIndexCollapse(-1);

    try {
      const { data } = await axios.get(`${INVOICE_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelTokenSource.token });
      setInvoices(data.data);
      setCount(data.meta.last_page);
      setTotal(data.meta.total);
      setTo(data.meta.to);
      setFrom(data.meta.from);
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const totalPriceBulk = useMemo(() => {
    const total = getTotal(invoiceBulkTab.map(value => value.totalPrice));
    return total;
  }, [invoiceBulkTab]);

  const handleInvoiceBulkTab = () => {
    invoiceBulkTab.forEach(value => window.open(`/invoice/${value.id}`));
    setInvoiceBulkTab([]);
  };

  const handleClosePaymentSuccess = () => {
    setIsInvoicePaymentBulkSuccess(false);
    setInvoiceBulkTab([]);
    fetchData();
  };
  const handleInvoicePaymentBulk = async () => {
    setIsInvoicePaymentBulkLoading(true);
    const payments = invoiceBulkTab.map(invoice => ({
      Invoice: invoice.id
    }));
    try {
      axios.post(`${INVOICE_BASE_URL}/multipayment`, payments);
      setIsInvoicePaymentBulkLoading(false);
      setInvoicePaymentBulk(false);
      setIsInvoicePaymentBulkSuccess(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenInvoicePaymentBulk = () => setInvoicePaymentBulk(true);

  const handleChecklistBulk = (id: number, isPayment: boolean, isChecked: boolean, totalPrice: number) => {
    if (isChecked) {
      setInvoiceBulkTab(prev => prev.concat({ id, isPayment, totalPrice }));
    } else {
      const tempChecklistId = invoiceBulkTab.filter(value => value.id !== id);
      setInvoiceBulkTab(tempChecklistId);
    }
  };

  const onRefresh = () => {
    setOrderBy('id');
    setOrder('desc');
    setStatusPayment('');
    setStatusPrint(0);
    setName('');
    setInvoiceId('');

    setCurrentPage(1);
  };
  const handleDeleteInvoiceConfirm = async (id: number) => {
    setIsDelete(prev => ({ ...prev, isConfirm: true, id }));
  };

  const handleOpenConfirmation = () => {
    setOpenConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  async function handleDeleteInvoice() {
    setIsDelete(prev => ({ ...prev, isLoading: true }));
    try {
      axios.delete(`${INVOICE_BASE_URL}/${isDelete.id}`);
      setIsDelete(prev => ({ ...prev, isLoading: false, isConfirm: false, isSuccess: true }));
    } catch (error) {
      console.log(error);
    }
  }

  const handleConfirmationDelete = (id: number): React.MouseEventHandler => () => {
    setSelectedId(id);
    setConfirmationDelete(true);
  };

  const handlePaymentConfirm = (id: number) => {
    setInvoicePaymentConfirm(true);
    setInvoicePayment(id);
  };
  const handlePayment = async () => {
    setInvoicePaymentConfirm(false);
    setloadingPayment(true);

    try {
      const body = {
        id: invoicePayment,
        statusPayment: 'PAID',
        paidDate: format(new Date(), 'yyyy-MM-dd'),
        paidBy: currentUser && currentUser.id
      };
      const { data } = await axios.post(`${INVOICE_BASE_URL}/update-invoice-status`, body);
      setloadingPayment(false);
      setOpenPaymentSuccess(true);
      setPaymentMessage(data.message);
    } catch (error) {
      console.log('error :', error);
    }
  };

  const getData = async (id: number) => {
    setLoadingItem(true);
    try {
      const { data } = await axios.get(`${INVOICE_BASE_URL}/${id}`);
      setInvoices(prevState =>
        prevState.map(value => {
          if (value.id === id) {
            value.InvoiceItem = data.data.InvoiceItem;
          }
          return value;
        })
      );
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoadingItem(false);
    }
  };

  const handleOpenCollapse = (index: number, id: number): React.MouseEventHandler => () => {
    setIndexCollapse(index);
    setOpenCollapse(openCollapse ? (index === indexCollapse ? false : true) : true);
    getData(id);
  };

  const handleCalendarFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenCalendarFilter(!openCalendarFilter);
  };

  const downloadExcel = async () => {
    setLoadingExport(true);

    try {
      const { data } = await axios.get(`${EXPORT_INVOICE_BASE_URL}?${getQueryParams()}`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `Invoice-Penjualan-${format(new Date(), 'dd/MM/yyyy-HH:mm:ss')}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log('error :', error);
    } finally {
      setLoadingExport(false);
    }
  };

  const debouncedFetchHits = useDebounced(fetchData, 500);

  useEffect(() => {
    debouncedFetchHits();

    return () => cancelTokenSource.cancel('No longer latest query');
  }, [
    orderBy,
    order,
    currentPage,
    startDate,
    endDate,
    name,
    invoiceId,
    statusPayment,
    isSuperVisor,
    isSales,
    statusPrint,
    partnerType,
    totalPriceSearch
  ]);

  const PaymentDisable = invoiceBulkTab.some(value => value.isPayment);

  return (
    <Page title='Invoice'>
      <Container>
        <Grid container direction='row' spacing={2}>
          <Grid item lg={2} md={12} sm={12} xs={12}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant='h1' component='h1'>
                Penjualan
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Breadcrumb />
            </Grid>
          </Grid>
        </Grid>

        <PaperCustom>
          <Grid container direction='row' spacing={2}>
            <Grid xs={12} container alignItems='flex-start'>
              <FlexBox container xl={6} lg={6} md={6} sm={12} xs={12} columnGap={1} alignItems='center'>
                <Tooltip title='Memuat Ulang'>
                  <Button onClick={onRefresh} color='inherit' className={classes.refresh} size='small'>
                    <RefreshIcon />
                  </Button>
                </Tooltip>
                <Grid item>
                  <Typography variant='h6'>{`Menampilkan ${total} Invoice (${from || 0} - ${to || 0} dari ${total || 0})`}</Typography>

                  <Typography color='primary'>
                    Data tanggal {format(new Date(startDate), 'dd-MM-yyyy')} s/d {format(new Date(endDate), 'dd-MM-yyyy')}
                  </Typography>
                </Grid>
              </FlexBox>
              <FlexBox xl={6} lg={6} md={6} sm={12} xs={12} container justify='flex-end' columnGap={1}>
                <Tooltip title='Calendar filter' placement='top'>
                  <Button color='inherit' onClick={event => handleCalendarFilterClick(event)} size='small'>
                    <CalendarIcon fontSize='small' />
                  </Button>
                </Tooltip>
                <Tooltip title='Export'>
                  <Button color='default' onClick={downloadExcel} disabled={isLoadingData || loadingExport} size='small'>
                    {loadingExport ? <CircularProgress color='inherit' size={20} /> : <GetAppIcon />}
                  </Button>
                </Tooltip>
                <Tooltip title={invoiceBulkTab.length > 0 ? 'Untuk membuka tab sekaligus' : 'Silahkan Select invoice terlebih dahulu'}>
                  <Button color='inherit' variant='contained' onClick={handleInvoiceBulkTab} disabled={invoiceBulkTab.length < 1} size='small'>
                    Buka Tab Baru ({invoiceBulkTab.length})
                  </Button>
                </Tooltip>

                <Tooltip title={invoiceBulkTab.length > 0 ? 'Untuk melakukan pembayaran sekaligus' : 'Silahkan Select invoice terlebih dahulu'}>
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={handleOpenInvoicePaymentBulk}
                    size='small'
                    disabled={invoiceBulkTab.length < 1 || PaymentDisable}
                  >
                    Pembayaran ({invoiceBulkTab.length})
                  </Button>
                </Tooltip>
              </FlexBox>
            </Grid>

            {invoiceBulkTab.length > 9 && (
              <Grid xs={12} className={classes.alertWrapper}>
                <Alert severity='warning'>
                  <AlertTitle>Peringatan</AlertTitle>
                  Invoice hanya bisa dibuka tab sekaligus <strong>Maximal 10</strong>
                </Alert>
              </Grid>
            )}

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <InvoiceTable
                isSuperAdmin={isSuperAdmin}
                isAdmin={isAdmin}
                statusPayment={statusPayment}
                statusPrint={statusPrint}
                invoiceId={invoiceId}
                name={name}
                totalPriceSearch={totalPriceSearch}
                setTotalPriceSearch={setTotalPriceSearch}
                invoiceBulkTab={invoiceBulkTab}
                handleChecklistBulk={handleChecklistBulk}
                handleDeleteInvoiceConfirm={handleDeleteInvoiceConfirm}
                isLoadingData={isLoadingData}
                count={count}
                loadingPayment={loadingPayment}
                invoicePayment={invoicePayment}
                handlePaymentConfirm={handlePaymentConfirm}
                currentPage={currentPage}
                invoices={invoices}
                order={order}
                orderBy={orderBy}
                openCollapse={openCollapse}
                indexCollapse={indexCollapse}
                setName={setName}
                setInvoiceId={setInvoiceId}
                setStatusPayment={setStatusPayment}
                setStatusPrint={setStatusPrint}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                handleConfirmationDelete={handleConfirmationDelete}
                handleOpenCollapse={handleOpenCollapse}
                loadingItem={loadingItem}
                partnerType={partnerType}
                setPartnerType={setPartnerType}
              />
            </Grid>

            <Grid container justify='center' item xl={12} md={12} sm={12} className={classes.fourthGrid}>
              <Grid item>
                {invoices.length > 0 && (
                  <Pagination
                    count={count}
                    onChange={(event, page) => setCurrentPage(page)}
                    page={currentPage}
                    boundaryCount={2}
                    variant='outlined'
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </PaperCustom>

        <DateRangeFilter
          openCalendarFilter={openCalendarFilter}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleClose={() => {
            setOpenCalendarFilter(false);
          }}
        />

        <ModalSuccess
          open={openPaymentSuccess}
          message={paymentMessage}
          handleClick={() => {
            setOpenPaymentSuccess(false);
            fetchData();
          }}
        />
        <ModalConfirmation
          open={invoicePaymentConfirm}
          message={'Apa kah anda yakin melakukan pembayaran?'}
          buttonLabel='Bayar'
          handleClose={() => setInvoicePaymentConfirm(false)}
          handleSubmit={handlePayment}
        />
        <ModalConfirmation
          open={isDelete.isConfirm}
          message={'Apa kah anda yakin ingin menghapus invoice?'}
          buttonLabel='Hapus'
          isLoading={isDelete.isLoading}
          handleClose={() => setIsDelete(prev => ({ ...prev, isConfirm: false }))}
          handleSubmit={handleOpenConfirmation}
        />
        <ModalSuccess
          open={isDelete.isSuccess}
          message='Berhasil menghapus invoice!'
          handleClick={() => {
            setIsDelete(prev => ({ ...prev, isSuccess: false }));
            fetchData();
          }}
        />

        <InvoicePaymentCreate
          open={InvoicePaymentBulk}
          handleClose={() => setInvoicePaymentBulk(false)}
          handleSubmit={handleInvoicePaymentBulk}
          totalPrice={totalPriceBulk}
          isLoading={isInvoicePaymentBulkLoading}
        />
        <FormPinDialog
          open={openConfirmation}
          pinMessage={pinMessage}
          isComplete={isComplete}
          setComplete={setComplete}
          setPin={setPin}
          handleClose={handleCloseConfirmation}
        />
        <InvoicePaymentSuccess open={isInvoicePaymentBulkSuccess} handleClick={handleClosePaymentSuccess} />
      </Container>
    </Page>
  );
};

export default InvoicePage;
