import React, { FC } from 'react';
import clx from 'clsx';
import Logo from 'images/logo.png';
import { BLACK, BLUE } from 'constants/colors';
import { format } from 'date-fns';
import { Grid, makeStyles, Theme, Typography, TableContainer, Table, TableRow, TableCell, TableBody, Hidden, Button } from '@material-ui/core';
import { PaperCustom } from 'components';
import ListProduct from '../ListProduct';
import Skeleton from '@material-ui/lab/Skeleton';
import NumberFormat from 'react-number-format';
import clsx from 'clsx';

interface Props {
  purchaseInvoice: PurchaseInvoiceModel;
  invoiceItem: PurchaseInvoiceItemModel[];
  invoicePayment: InvoicePaymentModel[];
  company: CompanyModel;
  isLoadingData: boolean;
  handleClickInvoicePayment: (id: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  cellOrder: {
    marginBottom: 1,
    padding: '2px 0px 2px 2px',
    border: 'none'
  },
  cellOrderRight: {
    width: '35%',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontStyle: 'normal',
    color: BLACK
  },
  cellOrderLeft: {
    width: '30%'
  },
  titleOrder: {
    color: BLACK,
    marginBottom: '16px'
  },
  cellSummaryRight: {
    border: 'none',
    padding: 4,
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontStyle: 'normal',
    color: BLACK
  },
  cellSummaryRightLink: {
    color: BLUE,
    cursor: 'pointer',
    textDecorationLine: 'underline'
  },
  cellSummaryLeft: {
    width: '26%',
    border: 'none',
    padding: '4px 4px 4px 4px',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal',
    color: BLACK
  },
  cellInfoRight: {
    padding: '2px 0px 2px 2px',
    border: 'none',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal'
  },
  cellInfoLeft: {
    padding: '2px 0px 2px 0px',
    border: 'none',
    fontSize: '15px',
    lineHeight: '22px',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontStyle: 'normal'
  },
  cellFinalRight: {
    marginRight: 65
  },
  cellFinalLeft: {
    marginLeft: 65
  },
  logo: {
    objectFit: 'cover',
    height: '8em'
  }
}));

const DetailInvoice: FC<Props> = props => {
  const classes = useStyles();
  const { purchaseInvoice, isLoadingData, company, invoiceItem, invoicePayment, handleClickInvoicePayment } = props;

  return (
    <Grid container direction='row' item lg={12} sm={12} md={12} xs={12}>
      <PaperCustom>
        <Grid container direction='row' spacing={2} item lg={12} sm={12} md={12} xs={12}>
          <Hidden smDown xsDown>
            <Grid item lg={6} sm={12} md={6} xs={12}>
              <img src={Logo} alt='company' className={classes.logo} />
            </Grid>
          </Hidden>

          <Grid container item lg={6} sm={12} md={6} xs={12} justify='flex-end'>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell align='right' colSpan={2} className={classes.cellOrder}>
                    <Typography variant='h1' className={classes.titleOrder}>
                      Invoice Pembelian
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='left' className={clx(classes.cellOrder, classes.cellOrderLeft)}>
                    <Typography variant='subtitle2'>Nomor Invoice</Typography>
                  </TableCell>
                  <TableCell align='right' className={clx(classes.cellOrder, classes.cellOrderRight)}>
                    {isLoadingData ? <Skeleton variant='text' width='100%' /> : purchaseInvoice.invoiceNumber}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='left' className={clx(classes.cellOrder, classes.cellOrderLeft)}>
                    <Typography variant='subtitle2'>Nomor Invoice Supplier</Typography>
                  </TableCell>
                  <TableCell align='right' className={clx(classes.cellOrder, classes.cellOrderRight)}>
                    {isLoadingData ? <Skeleton variant='text' width='100%' /> : purchaseInvoice.invoiceNumberSupplier}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='left' className={clx(classes.cellOrder, classes.cellOrderLeft)}>
                    <Typography variant='subtitle2'>Tanggal Order</Typography>
                  </TableCell>
                  <TableCell align='right' className={clx(classes.cellOrder, classes.cellOrderRight)}>
                    {isLoadingData ? (
                      <Skeleton variant='text' width='100%' />
                    ) : purchaseInvoice.orderDate ? (
                      format(new Date(purchaseInvoice.orderDate), 'dd-MM-yyyy')
                    ) : (
                      ''
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='left' className={clx(classes.cellOrder, classes.cellOrderLeft)}>
                    <Typography variant='subtitle2'>Jatuh Tempo</Typography>
                  </TableCell>
                  <TableCell align='right' className={clx(classes.cellOrder, classes.cellOrderRight)}>
                    {isLoadingData ? <Skeleton variant='text' width='100%' /> : format(new Date(purchaseInvoice.dueDate), 'dd-MM-yyyy')}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align='left' className={clx(classes.cellOrder, classes.cellOrderLeft)}>
                    <Typography variant='subtitle2'>Nomor Pembelian (PO)</Typography>
                  </TableCell>
                  <TableCell align='right' className={clx(classes.cellOrder, classes.cellOrderRight)}>
                    {isLoadingData ? (
                      <Skeleton variant='text' width='100%' />
                    ) : (
                      purchaseInvoice.PurchaseOrder &&
                      purchaseInvoice.PurchaseOrder.map(value => (
                        <>
                          <Button size='small' variant='text' onClick={() => window.open(`/purchase/${value.id}`, '_blank')}>
                            <u>{`${value.orderNumber} (${format(new Date(value.travelDate), 'dd-MM-yyyy')})`}</u>
                          </Button>
                          <br />
                        </>
                      ))
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>

          <Grid item lg={6} sm={12} md={6} xs={12}>
            <Typography variant='h5' align='left'>
              Nama Perusahaan
            </Typography>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align='left' style={{ fontWeight: 500, color: BLACK }} className={classes.cellInfoLeft}>
                      {isLoadingData ? <Skeleton variant='text' width='100%' /> : company.name}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='left' className={classes.cellInfoLeft}>
                      {isLoadingData ? <Skeleton variant='text' width='100%' /> : company.address}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='left' className={classes.cellInfoLeft}>
                      {isLoadingData ? <Skeleton variant='text' width='100%' /> : 'Telp: ' + company.phoneNumber}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='left' className={classes.cellInfoLeft}>
                      {isLoadingData ? <Skeleton variant='text' width='100%' /> : 'No Hp: ' + (company.cellPhoneNumber || '-')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='left' className={classes.cellInfoLeft}>
                      {isLoadingData ? <Skeleton variant='text' width='100%' /> : 'Email: ' + (company.email || '-')}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item lg={6} sm={12} md={6} xs={12}>
            <Typography variant='h5' align='right'>
              Order Pembelian dari
            </Typography>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align='right' style={{ fontWeight: 500, color: BLACK }} className={classes.cellInfoRight}>
                      {isLoadingData ? <Skeleton variant='text' width='100%' /> : purchaseInvoice.Partner ? purchaseInvoice.Partner.name : ''}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='right' className={classes.cellInfoRight}>
                      {isLoadingData ? <Skeleton variant='text' width='100%' /> : purchaseInvoice.Partner ? purchaseInvoice.Partner.address : ''}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='right' className={classes.cellInfoRight}>
                      {isLoadingData ? (
                        <Skeleton variant='text' width='100%' />
                      ) : purchaseInvoice.Partner ? (
                        'Telp: ' + purchaseInvoice.Partner.phoneNumber
                      ) : (
                        ''
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='right' className={classes.cellInfoRight}>
                      {isLoadingData ? (
                        <Skeleton variant='text' width='100%' />
                      ) : purchaseInvoice.Partner ? (
                        'No Hp: ' + purchaseInvoice.Partner.cellPhoneNumber
                      ) : (
                        ''
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='right' className={classes.cellInfoRight}>
                      {isLoadingData ? (
                        <Skeleton variant='text' width='100%' />
                      ) : purchaseInvoice.Partner ? (
                        'Email: ' + purchaseInvoice.Partner.email
                      ) : (
                        ''
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item lg={12} sm={12} md={12} xs={12}>
            <ListProduct isLoadingData={isLoadingData} invoiceItem={invoiceItem} />
          </Grid>

          <Grid container item lg={12} sm={12} md={12} xs={12}>
            <Typography variant='body2'>Total Item : {invoiceItem.length}</Typography>
            <br />
          </Grid>

          <Grid container item xl={6} lg={6} sm={12} md={6} xs={12}>
            <Grid item lg={12} sm={12} md={12} xs={12}>
              <Typography variant='h5'>Keterangan</Typography>
              <div dangerouslySetInnerHTML={{ __html: purchaseInvoice.notes || '-' }}></div>
            </Grid>
          </Grid>

          <Grid item xl={6} lg={6} sm={12} md={6} xs={12}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align='right' className={classes.cellSummaryRight}>
                      Subtotal
                    </TableCell>
                    <TableCell align='right' className={classes.cellSummaryLeft}>
                      <NumberFormat value={purchaseInvoice.subTotal} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='right' className={classes.cellSummaryRight}>
                      Biaya Logistik
                    </TableCell>
                    <TableCell align='right' className={classes.cellSummaryLeft}>
                      <NumberFormat value={purchaseInvoice.logisticPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='right' className={classes.cellSummaryRight}>
                      Diskon
                    </TableCell>
                    <TableCell align='right' className={classes.cellSummaryLeft}>
                      <NumberFormat value={purchaseInvoice.totalDiscount} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='right' className={classes.cellSummaryRight}>
                      Total Harga
                    </TableCell>
                    <TableCell align='right' className={classes.cellSummaryLeft}>
                      <NumberFormat value={purchaseInvoice.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align='right' className={classes.cellSummaryRight}>
                      Jumlah Tertagih
                    </TableCell>
                    <TableCell align='right' className={classes.cellSummaryLeft}>
                      <NumberFormat
                        value={purchaseInvoice.totalPrice - purchaseInvoice.totalPay}
                        prefix={'Rp'}
                        thousandSeparator={true}
                        displayType='text'
                      />
                    </TableCell>
                  </TableRow>
                  {invoicePayment.map((payment, key) => (
                    <TableRow>
                      <TableCell
                        align='right'
                        className={clsx(classes.cellSummaryRight, classes.cellSummaryRightLink)}
                        onClick={() => handleClickInvoicePayment(payment.id)}
                      >
                        {payment.paymentNumber}
                      </TableCell>
                      <TableCell align='right' className={classes.cellSummaryLeft}>
                        <NumberFormat value={payment.totalPay} prefix={'Rp'} thousandSeparator={true} displayType='text' />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </PaperCustom>
    </Grid>
  );
};

export default DetailInvoice;
