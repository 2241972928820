import { Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { FC } from 'react';
interface Props {
  isLoading: boolean;
  deliveryOrder: DeliveryOrders;
}

const DeliveryInfo: FC<Props> = ({ isLoading, deliveryOrder }) => {
  return (
    <Grid xs={12} container style={{ rowGap: '1em' }}>
      <Grid xs={12}>
        <Typography variant='h6' color='textSecondary'>
          NO. SURAT JALAN
        </Typography>

        <Typography variant='h6'>{isLoading ? <Skeleton width={180} /> : deliveryOrder.deliveryNumber}</Typography>
      </Grid>
      <Grid xs={2}>
        <Typography variant='h6' color='textSecondary'>
          TANGGAL
        </Typography>
        <Typography variant='h6'>{isLoading ? <Skeleton width={180} /> : deliveryOrder.createdAt}</Typography>
      </Grid>
      <Grid xs={10}>
        <Typography variant='h6' color='textSecondary'>
          NAMA KURIR
        </Typography>
        <Typography variant='h6'>{isLoading ? <Skeleton width={180} /> : deliveryOrder.kurirName}</Typography>
      </Grid>
    </Grid>
  );
};

export default DeliveryInfo;
