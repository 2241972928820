import * as React from 'react';

const HomePage: React.FC = () => {
  return (
    <div>
      Home Page
    </div>
  )
}

export default HomePage;
