import React, { FC } from 'react';
import { Grid, TableRow, Button, makeStyles } from '@material-ui/core';
import { TableCellMiddle, TableCellEnd, TableCellStart } from 'components/Table';
import Skeleton from '@material-ui/lab/Skeleton';
import NumberFormat from 'react-number-format';
import ItemStatus from 'typings/enum/ItemStatus';
import clx from 'clsx';

interface Props {
  salesOrderItem: SalesOrderItemModel;
  isLoading: boolean;
  handleOpenImage: (imagePath: string) => void;
}

const useStyles = makeStyles({
  done: {
    backgroundColor: '#e0e0e0'
  }
});

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const { salesOrderItem, isLoading, handleOpenImage } = props;

  return isLoading ? (
    <Grid xl={12} lg={12} style={{ marginBottom: '2em' }}>
      <Skeleton variant='text' height={25} component='div' />
      <Skeleton variant='text' height={25} component='div' />
    </Grid>
  ) : (
    <>
      <TableRow className={clx(salesOrderItem.status === ItemStatus.HAS_INVOICE && classes.done)}>
        <TableCellStart>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <Button variant='text' onClick={() => handleOpenImage(salesOrderItem.productImage || '')}>
              {(salesOrderItem && salesOrderItem.productName) || ''}
            </Button>
          )}
        </TableCellStart>

        <TableCellMiddle>{isLoading ? <Skeleton variant='text' width={100} height={25} /> : salesOrderItem.typeUnit}</TableCellMiddle>
        <TableCellMiddle>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <NumberFormat value={salesOrderItem.price} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          )}
        </TableCellMiddle>

        <TableCellMiddle>
          {isLoading ? <Skeleton variant='text' width={100} height={25} /> : salesOrderItem.totalItem + ' ' + salesOrderItem.typeUnit}
        </TableCellMiddle>

        <TableCellEnd align='right'>
          {isLoading ? (
            <Skeleton variant='text' width={100} height={25} />
          ) : (
            <NumberFormat value={salesOrderItem.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
          )}
        </TableCellEnd>
      </TableRow>
    </>
  );
};

export default BodyRow;
