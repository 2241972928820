import React, { FC, useState, useEffect } from 'react';
import { TextField, Button, Grid } from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';
import { WAREHOUSE_BASE_URL } from 'constants/url';

interface Props {
  wareHouses: WareHouseModel[];
  wareHouse: WareHouseModel | null;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setWareHouses: React.Dispatch<React.SetStateAction<WareHouseModel[]>>;
  setWareHouse: React.Dispatch<React.SetStateAction<WareHouseModel | null>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

const CreateWareHouseForm: FC<Props> = props => {
  const { wareHouses, wareHouse, setWareHouses, setWareHouse, setOpen, handleSnackBar } = props;
  const [id, setId] = useState<number>(0);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [address, setAddress] = useState<string>('');

  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [nameMessage, setNameMessage] = useState<string>('');

  useEffect(() => {
    if (!wareHouse) {
      return;
    }

    setId(wareHouse.id);
    setName(wareHouse.name);
    setAddress(wareHouse.address);
    setDescription(wareHouse.description);
  }, [wareHouse]);

  const handleOnCancel = (): void => {
    setOpen(false);
    resetForm();
  };

  const handleOnSubmit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    resetValidation();

    if (!validation()) {
      return;
    }

    try {
      setSubmit(true);
      const { data } = await axios.post(
        `${WAREHOUSE_BASE_URL}`,
        {
          id,
          name,
          description,
          address
        },
        {
          cancelToken: cancelTokenSource.token
        }
      );

      if (wareHouse) {
        setWareHouses(
          wareHouses.map(value => {
            if (value.id === data.data.id) {
              return data.data;
            }
            return value;
          })
        );
        resetForm();
      } else {
        setWareHouses([data.data, ...wareHouses]);
        resetForm();
      }

      setOpen(false);
      handleSnackBar(true, 'success', wareHouse ? 'Gudang berhasil diperbaharui.' : 'Gudang berhasil ditambahkan.');
    } catch (error) {
      console.log('error :', error);
      errorRequest(error.data.error);
      handleSnackBar(true, 'error', wareHouse ? 'Gudang gagal diperbaharui.' : 'Gudang gagal ditambahkan.');
    }
    setSubmit(false);
  };

  const validation = (): boolean => {
    let valid = true;

    if (name === '' || !name) {
      setNameMessage('Nama tidak boleh kosong.');
      valid = false;
    }

    return valid;
  };

  const resetValidation = () => {
    setNameMessage('');
  };

  const resetForm = () => {
    setName('');
    setDescription('');
    setId(0);
    setAddress('');
    setWareHouse(null);
  };

  const errorRequest = (error: { [key: string]: string[] }) => {
    if (error) {
      if (error.name) {
        setNameMessage(error.name[0]);
      }
    }
  };

  return (
    <Grid container direction='row' spacing={1}>
      <Grid item lg={12} sm={12} md={12} xs={12}>
        <TextField
          id='name'
          name='name'
          required
          fullWidth
          label='Nama Gudang'
          value={name}
          onChange={event => setName(event.target.value)}
          error={nameMessage !== ''}
          helperText={nameMessage}
        />
      </Grid>

      <Grid item lg={12} sm={12} md={12} xs={12}>
        <TextField id='address' name='address' fullWidth label='Alamat' value={address} onChange={event => setAddress(event.target.value)} />
      </Grid>

      <Grid item lg={12} sm={12} md={12} xs={12}>
        <TextField
          id='Deskripsi'
          rows={4}
          multiline
          fullWidth
          size='small'
          variant='outlined'
          label='Deskripsi'
          value={description}
          onChange={event => setDescription(event.target.value)}
        />
      </Grid>

      <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} justify='center'>
        <Grid item>
          <Button disabled={isSubmit} color='secondary' onClick={handleOnCancel}>
            Batal
          </Button>
        </Grid>
        <Grid item>
          <Button type='submit' disabled={isSubmit} onClick={handleOnSubmit}>
            Simpan
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default CreateWareHouseForm;
