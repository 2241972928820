import { Grid, makeStyles, Typography } from '@material-ui/core';
import { ExpandLessRounded, ExpandMoreRounded } from '@material-ui/icons';
import FlexBox from 'components/FlexBox';
import React, { ReactNode, useState } from 'react';
import { IReport } from 'pages/ReportProfitPage';
import ContentCard from './ContentCard';
import ContentItem from './ContentItem';
interface Props {
  reportProfitResource: IReport<ReportProfit>;
}

const useStyles = makeStyles({
  container: {
    rowGap: '1em',
    position: 'relative'
  },
  wrapperTitle: {
    cursor: 'pointer'
  }
});

const dummyExpands = [
  {
    key: 0,
    isExpand: true
  },
  {
    key: 1,
    isExpand: true
  },
  {
    key: 2,
    isExpand: true
  }
];

const ContentSection = ({ reportProfitResource }: Props) => {
  const classes = useStyles();
  const [isExpand, setIsExpand] = useState<{ key: number; isExpand: boolean }[]>(dummyExpands);
  const handleExpand = (key: number) => setIsExpand(prev => prev.map(value => (value.key === key ? { ...value, isExpand: !value.isExpand } : value)));

  const getExpand = (key: number) => isExpand[key].isExpand && isExpand[key].key === key;

  const profit = reportProfitResource.data;

  const totalNode = (total: number, minus: boolean = false) => {
    if (minus) {
      return <Typography>(Rp{new Intl.NumberFormat('id-ID').format(total)})</Typography>;
    } else {
      return <Typography>Rp{new Intl.NumberFormat('id-ID').format(total)}</Typography>;
    }
  };
  const items: Record<string, { title: string; totalPrice: ReactNode }[]> = {
    income: [
      {
        title: 'Pendapatan Umum',
        totalPrice: totalNode(profit.totalIncome)
      },
      {
        title: 'Total Ongkos Kirim',
        totalPrice: totalNode(profit.shippingFee)
      },
      {
        title: 'Total Potongan Lainnya',
        totalPrice: totalNode(profit.deduction, true)
      },
      {
        title: 'Total Diskon Penjualan',
        totalPrice: totalNode(profit.diskonSales, true)
      }
    ],
    debit: [
      {
        title: 'Total Pembelian',
        totalPrice: totalNode(profit.mainPurchase)
      }
    ],
    costs: [
      {
        title: 'Total Beban Operasional',
        totalPrice: totalNode(profit.totalOperationalCost)
      },
      {
        title: 'Total Komisi Kurir',
        totalPrice: totalNode(profit.totalCourierSalary)
      }
    ]
  };

  return (
    <Grid xs={12} container className={classes.container}>
      <ContentCard
        items={{
          title: 'Pendapatan',
          total: [
            {
              label: 'Total Pendapatan',
              totalPrice: profit.generalIncome
            }
          ]
        }}
      >
        <Grid xs={12} container className={classes.container}>
          <FlexBox xs={12} container columnGap={1} className={classes.wrapperTitle} onClick={() => handleExpand(0)}>
            {getExpand(0) ? <ExpandLessRounded /> : <ExpandMoreRounded />}
            <Typography>Rincian Pendapatan</Typography>
          </FlexBox>

          {getExpand(0) && <ContentItem items={items['income']} />}
        </Grid>
      </ContentCard>

      <ContentCard
        items={{
          title: 'Beban Pokok',
          total: [
            {
              label: 'Total Beban Pokok',
              totalPrice: profit.totalMainLoad
            }
          ]
        }}
      >
        <Grid xs={12} container className={classes.container}>
          <FlexBox xs={12} container columnGap={1} className={classes.wrapperTitle} onClick={() => handleExpand(1)}>
            {getExpand(1) ? <ExpandLessRounded /> : <ExpandMoreRounded />}
            <Typography>Rincian Beban Pokok</Typography>
          </FlexBox>

          {getExpand(1) && <ContentItem items={items['debit']} />}
        </Grid>
      </ContentCard>
      <ContentCard
        items={{
          title: 'Beban Biaya',
          total: [
            {
              label: 'Total Beban Biaya',
              totalPrice: profit.totalCost
            }
          ]
        }}
      >
        <Grid xs={12} container className={classes.container}>
          <FlexBox xs={12} container columnGap={1} className={classes.wrapperTitle} onClick={() => handleExpand(2)}>
            {getExpand(2) ? <ExpandLessRounded /> : <ExpandMoreRounded />}
            <Typography>Rincian Beban Operasional</Typography>
          </FlexBox>
          {getExpand(2) && <ContentItem items={items['costs']} />}
        </Grid>
      </ContentCard>

      <ContentCard
        items={{
          title: 'Total summary',
          total: [
            {
              label: 'Total Pendapatan',
              totalPrice: profit.generalIncome
            },
            {
              label: 'Total Beban Pokok',
              totalPrice: profit.totalMainLoad
            },
            {
              label: 'Total Beban Biaya',
              totalPrice: profit.totalCost
            },
            {
              label: 'Untung Bersih',
              totalPrice: profit.netProfit
            }
          ]
        }}
      />
    </Grid>
  );
};

export default ContentSection;
