import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

interface Props<T> {
  totalResource: { label: string; content: T }[];
}
const useStyles = makeStyles({
  container: {
    columnGap: '1em'
  },
  items: {
    rowGap: '1em'
  }
});
const TotalSection = <T,>({ totalResource }: Props<T>) => {
  const classes = useStyles();
  return (
    <Grid xs={12} container className={classes.container}>
      {totalResource.map((total, key) => (
        <>
          <Grid xs={2} key={key} className={classes.items} container direction='column'>
            <Typography variant='h6'>{total.label}</Typography>
            <Typography>{total.content}</Typography>
          </Grid>

          {key < totalResource.length - 1 && <Divider orientation='vertical' flexItem />}
        </>
      ))}
    </Grid>
  );
};

export default TotalSection;
